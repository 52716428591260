<template>
  <hr :class="checkClasses" :style="checkStyles">
</template>

<script>
  export default {
    name: 'LhHr'
  }
</script>

<style scoped lang="scss">
  hr {
    border-color: $hr-color;
    margin-top: 0;

    .popupContent & {
      position: relative;
      border-color: $hr-popup-color;

      //TODO te bespreken
      //width: calc(100% + 2rem);
      //left: -1rem;
      //margin: 1rem 0;

      //@include media-breakpoint-up(md) {
        //width: calc(100% + 4rem);
        //left: -2rem;
        //margin: 1rem 0;
      //}
    }
  }
</style>
