<template>
  <div class="container">
    <b-row>
      <b-col md="10" class="mb-2">
        <h1>Update Translations</h1>
        <h2>IMPORTANT: press enter in the input after updating a translation to save</h2>
      </b-col>
      <b-col md="2">
        <router-link
            :to="$route.params.from"
            class="button lh-button"
        >Back</router-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h2>Jump to:</h2>
      </b-col>
    </b-row>
    <b-row class="mb-4" wrap>
      <b-col md="3" lg="2" class="mb-2" v-for="(groupedKey, index) in groupedKeys">
        <a :href="`#${index}`">{{ index }}</a>
      </b-col>
    </b-row>
    <b-form>
      <b-row v-for="(groupedKey, index) in groupedKeys">
        <b-col>
          <h2 :id="index">{{index}}</h2>
          <b-row>
            <b-col md="6" class="mb-4" v-for="key in groupedKey">
              <p><strong>{{ key }}</strong></p>
              <b-form-group
                  v-for="(definition, index) in getDefinitions(key)"
                  label-cols="1"
                  :label="index"
                  :label-for="key">
                <div class="mb-2">
                  <b-form-input
                      :id="key"
                      :value="definition"
                      @keyup.enter="handleSubmit(key, index, definition, $event)"
                  ></b-form-input>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { library } from '@fortawesome/fontawesome-svg-core'
import { eventBus } from '@/services/event-bus'

export default {
  name: 'LhTranslations',
  props: {},
  data () {
    return {
      languages: [],
      filteredTranslations: [],
      keys: [],
      groupedKeys: {}
    }
  },
  computed: {
    ...mapState({
      translations: 'latestTranslations'
    }),
    ...mapGetters({
      getKeyByLang: 'getTranslationKeyByLang'
    })
  },
  methods: {
    filterTranslations () {
      this.languages = Object.keys(this.translations)
      this.filteredTranslations = Object.entries(this.translations)
      Object.entries(this.translations).forEach(lang => {
        console.log('lang ', lang)
        if (lang[0] !== 'test') {
          Object.keys(lang[1]).forEach(key => {
            let keyIndex = this.keys.findIndex(item => item.toString() === key)
            if (keyIndex < 0) this.keys.push(key)
            else this.$set(this.keys, keyIndex, key)
          })
        }
      })
      this.keys.sort()

      let prefix = ''
      for (var i = 0; i < this.keys.length; i++)
      {
        if (this.keys[i].indexOf('_') < 0){
          prefix = 'No_prefix'
        } else {
          prefix = this.keys[i].split('_')[0];
        }
        if (!this.groupedKeys[prefix]) this.groupedKeys[prefix] = []
        let groupedIndex = this.groupedKeys[prefix].findIndex(item => item.toString() === this.keys[i])
        if (groupedIndex < 0)   this.groupedKeys[prefix].push(this.keys[i]);
        else this.$set(this.groupedKeys[prefix], groupedIndex, this.keys[i])
      }
    },
    getDefinitions (key) {
      let definitions = {}
      this.filteredTranslations.forEach(trans => {
        let definition = trans[1][key]
        if (definition) {
          definitions[trans[0]] = definition
        } else {
          definitions[trans[0]] = ""
        }
      })
      return definitions
    },
    handleSubmit (key, lang, original_value, event) {
      let input = event.target.value
      let keyFound = this.getKeyByLang({lang: lang, key: key})
      event.preventDefault()
      if (input !== '' && input !== original_value) {
        let payload = {
          key: key,
          language: lang,
          translation: input,
          add: !keyFound
        }
        this.$store.dispatch('submitTranslation', payload).then((response) => {
          console.log('translation response: ', response)
          let data = {
            title: 'Translation updated successfully',
            message: `Translation key "${key}" was successfully updated to "${input}" for language "${lang}". ${response.data.message}`
          }
          eventBus.$emit('show-toast', data)
          this.filterTranslations()
        })
      }
    }
  },
  watch: {},
  created () {
    this.filterTranslations()
    console.log('route: ', this.$route)
  }
}
</script>

<style scoped lang="scss">
.container {
  margin: 2rem;
}

h2 {
  margin: 0 0 1rem 0;
}

.form-group {
  margin-bottom: 0.5rem;
}
</style>