<template>
  <div :class="[checkClasses, 'lh-h1']" :style="checkStyles">
    <h1>{{$t(obj.attributes.content)}}
      <lh-edit-translation :obj="{key: obj.attributes.content, parentKey: obj.key}"></lh-edit-translation>
    </h1>
    <component v-for="item in obj.children" :is="item.type" :key="item.key" :obj="item"></component>
  </div>
</template>

<script>
  export default {
    name: 'LhH1',
    components: {},
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>

<style lang="scss">
  .page-title {
    @include media-breakpoint-only(xs) {
      padding-right: 2rem;
      word-break: break-all;
    }
  }
</style>
