<template>
  <div class="lh-upload" :class="checkClasses" :style="checkStyles" v-tooltip="checkTooltip">
    <div>
      <div class="upload-wrapper">
        <b-form-file
            v-model="file"
            :id="'file-' + obj.attributes.id"
            :placeholder="fileName ? fileName : $t('UPLOAD_CHOOSE_FILE')"
            :drop-placeholder="$t('UPLOAD_DROP_FILE')"
            :browse-text="!checkDisabled ? $t('UPLOAD_BROWSE_TEXT') : ' '"
            :accept="obj.attributes.accept"
            class="lh-file-input"
            @input="handleInput"
            :disabled="checkDisabled"
        ></b-form-file>
        <span v-if="fileUrl" :class="['upload-download-icon']"
              :style="'right: ' + (uploadButtonWidth + 2.5) + 'rem'" ><font-awesome-icon
            icon="eye" @click="handleDownloadFile"></font-awesome-icon>
        </span>
        <span v-if="(file || fileName) && !checkDisabled" :class="['upload-delete-icon']"
              :style="'right: ' + uploadButtonWidth + 'rem'"><font-awesome-icon
            icon="trash-alt" @click="handleRemoveFile"></font-awesome-icon>
        </span>
      </div>
      <p v-if="file" class="file-size-text">{{$t('UPLOAD_FILE_SIZE')}}
        <span v-if="file">{{ readableBytes(file.size) }}</span>
      </p>
<!--      <p v-if="file && !checkFile" class="error">{{$t('UPLOAD_MAX_SIZE')}}</p>-->
    </div>
    <span class="footer" v-if="!isInput">
      <button
          class="button primary"
          :class="{disabled: !checkFile}"
          :disabled="!checkFile"
          @click="submitFile"
          size="medium"
          ref="uploadButton"
      >{{$t('UPLOAD_UPLOAD')}}</button>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'LhUpload',
    props: {
      obj: {
        type: Object
      },
      isInput: {
        type: Boolean
      }
    },
    data () {
      return {
        loading: false,
        file: null,
        fileName: '',
        fileUrl: ''
      }
    },
    computed: {
      checkFile () {
        return !!this.file
      },
      uploadButtonWidth () {
        // TODO: refactor this, must be an easier way to get button width
        let width = 0
        let buttonText = this.$t('UPLOAD_BROWSE_TEXT')
        if (buttonText) {
          // move 6px for every letter
          width += buttonText.length * 0.625
        }

        if (this.checkDisabled) {
          width = -2.5
        }

        return width !== 0 ? width : 9.375
      }
    },
    methods: {
      readableBytes (bytes) {
        let i = Math.floor(Math.log(bytes) / Math.log(1024)),
          sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i]
      },
      submitFile () {
        if (this.file) {
          let payload = {
            file: this.file,
            url: this.obj.attributes.url
          }
          this.$store.dispatch('submitFile', payload)
        } else {
          alert(this.$t('UPLOAD_ALERT_CHOOSE_FILE'))
        }
      },
      handleInput (val) {
        if (val) {
          this.$emit('handleInput', val)
        } else {
          this.removeFile()
        }
      },
      removeFile () {
        this.$emit('handleInput', false)
      },
      handleRemoveFile () {
        this.file = null
        this.fileUrl = ''
        this.fileName = ''
        this.removeFile()
      },
      handleDownloadFile () {
        let payload = {
          url: this.fileUrl,
          name: this.obj.attributes.value,
        }
        this.$store.dispatch('download', payload)
      }
    },
    mounted () {
      if (this.obj && this.obj.attributes.fileUrl) this.fileUrl = this.obj.attributes.fileUrl
      if (this.obj && this.obj.attributes.value) {
        this.fileName = this.obj.attributes.value
        this.handleInput(this.fileName)
      }
    }
  }
</script>

<style lang="scss">
  .lh-file-input {
    cursor: pointer;
  }

  .custom-file-input, .custom-file-label {
    cursor: pointer;
  }

  input[disabled] + .custom-file-label {
    cursor: default;
  }

  .upload-wrapper {
    position: relative;
    display: flex;
  }

  .file-size-text {
    padding: 0.25rem 0.75rem;
    margin-bottom: 0;
  }

  .upload-delete-icon, .upload-download-icon {
    position: absolute;
    width: 3.125rem;
    font-size: $icon-font-size;
    text-align: center;
    z-index: 10;
    background: $white;
    color: $secondary-color;
    max-height: calc(100% - 2px);
    top: 3px;

    &:hover {
      color: $primary-color
    }

    &:active {
      color: $secondary-color;
      transform: translateY(1px);
    }



    svg {
      cursor: pointer;
    }
  }
</style>
