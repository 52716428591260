<template>
  <div class="grid-lite" :style="checkStyles" :class="checkClasses">
    <div class="grid-lite-no-results" v-if="!hasResults && !disableNoResults">
      <p>{{$t('GENERAL_NO_RESULTS')}}</p>
    </div>
    <div v-if="hasResults || disableNoResults" :class="['table-wrapper', {isMobile: isMobile}]">
      <table class="grid-lite-table noselect">
        <thead v-if="!obj.attributes.hideHeaders && !isMobile">
        <th v-for="header in headersArr"
            :class="['gridHeader', {notSortable: header.disableSort}]"
        >
          {{ $t(header.name) }}
        </th>
        </thead>
        <template v-for="row in obj.children" v-if="row.children[0].type !== 'LhGridHeader'" class="row-wrapper">
          <tbody v-if="!isMobile">
          <tr
              v-if="row.children[0]"
              :class="['gridrow', row.attributes.class]" :style="row.styles"
              :key="row.key"
              v-on="checkAction(row.actions)"
          >
            <td v-for="cell in row.children" :class="['gridCell', cell.attributes.class]" :style="cell.styles">

              <template v-if="cell.children.length !== 0">
                <component v-for="(item, index) in cell.children" :is="item.type" :obj="item"
                           :key="item.key"></component>
              </template>
              <template v-else>
                <template v-if="cell.attributes.content">
                  {{ $t(cell.attributes.content) }}
                </template>
                <template v-else>
                  <!--        else leave empty-->
                  &nbsp;
                </template>
              </template>
            </td>
          </tr>
          </tbody>

          <!-- Mobile -->
          <tbody v-else>
          <tr v-if="row.children[0]"
              :class="[{'isMobile': isMobile}, 'cards-view-mobile']">
            <td style="display: block;">
              <div class="grid-card-item">
                <div class="grid-card-item-content" :class="['gridrow']">
                  <div class="card-title">
                    <template v-for="cell in row.children" v-if="cell.attributes.titleMobile">
                      <div v-if="cell.attributes.content">{{$t(cell.attributes.content)}}</div>
                    </template>
                  </div>

                  <div class="card-content">
                    <template v-for="(cell, index) in row.children">

                      <!-- has children -->
                      <template v-if="cell.children.length !== 0">
                        <template v-for="item in cell.children">
                          <template v-if="item.type != 'LhButton' && item.type != 'LhIconButton'">
                            <template v-if="headersArr && headersArr.length > 0 && headersArr[index]">
                              <span>{{ $t(headersArr[index].name) }}: </span>
                            </template>
                            <component

                                :is="item.type" :obj="item"
                                :key="item.key"
                                :componentClass="'white-icon-buttons'"
                            ></component>
                          </template>
                        </template>
                      </template>

                      <!-- just content -->
                      <template v-else>
                        <p v-if="cell.attributes.content">
                          <template v-if="headersArr && headersArr.length > 0 && headersArr[index]">
                            <span>{{ $t(headersArr[index].name) }}: </span>
                          </template>
                          {{ $t(cell.attributes.content) }}
                        </p>
                        <template v-else>
                          <!--        else leave empty-->
                          &nbsp;
                        </template>
                      </template>


                    </template>
                  </div>
                  <!-- check for buttons -->
                  <template v-for="cell in row.children">
                    <b-row v-if="cell.children.length !== 0" class="row buttons-wrapper-mobile no-gutters">
                      <template v-for="button in cell.children">
                        <b-col v-if="button && (button.type == 'LhButton' || button.type == 'LhIconButton')"
                               class="col-auto">
                          <component
                              :is="button.type"
                              :obj="button"
                              :key="button.key"
                              :componentClass="'white-icon-buttons'"
                          ></component>
                        </b-col>
                      </template>
                    </b-row>
                  </template>
                </div>
              </div>
            </td>
          </tr>

          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import mixinFunctions from '@/mixin/mixinFunctions'
  import confirm from '@/mixin/confirm'

  export default {
    name: 'LhGridLite',
    mixins: [mixinFunctions, confirm],
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        headers: [],
      }
    },
    methods: {
      checkAction (actions) {
        let events = {}
        if (actions) {
          for (const action of actions) {
            events[action.event] = this.allEventHandler(action)
          }
        }
        return events
      }
    },
    computed: {
      ...mapState({
        isMobile: 'isMobile'
      }),
      disableNoResults(){
        return this?.obj?.attributes?.disableNoResultsRow ? true : false
      },
      hasResults(){
        let result = false
        if(this?.obj?.children && this.obj.children.length > 0){
          //Has first row
          let hasHeaders = (this?.obj?.children[0]?.children.filter(row => row.type === 'LhGridHeader').length > 0) ? true : false
          let hasSecondRow = (this?.obj?.children[1]) ? true : false
          if(!hasHeaders || (hasHeaders && hasSecondRow)){
            result = true
          }
        }
        return result
      },
      headersArr () {
        let headersArr = []
        if (this.obj.children[0]) {
          //first child -> headers
          for (const index in this.obj.children[0].children) {
            const child = this.obj.children[0].children[index]
            if (child && (child.type == "LhGridHeader" || child.type == "lh-grid-header)")) {
              headersArr.push({
                name: child.attributes.content,
                checked: true,
                key: child.key,
                type: child.attributes.cellType,
                sort: 0,
                sorting: false,
                showMobile: child.attributes.showMobile,
                disableSort: child.attributes.disableSort,
                hidden: false
              })
            }
          }
        }
        return headersArr
      },
    }
  }
</script>

<style lang="scss">
  .grid-lite {
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    /*overflow-x: auto;*/
    transition: all 1s;
    width: 100%;
    padding-bottom: 20px;
    position: relative;


    &-no-results {
      background: $ultra-light-grey;
      color: $medium-dark-grey;
      padding: 1rem;
      text-align: center;
      width: 100%;
      p {
        margin: 0;
      }
    }

    .grid-lite-table {
      flex: 1 1 auto;
      text-align: left;
      border-collapse: collapse;
      width: 100%;
      overflow-x: visible;

      p {
        margin-bottom: 0.5em;
      }
    }

    .table-wrapper {
      overflow-x: auto;
      width: 100%;

      &:not(.isMobile) {
        margin-left: 0;
      }
    }

    .title {
      /*background-color: burlywood;*/
      width: 80%;
      margin: 0 auto;
    }

    tbody {
      border-top: 1px solid $white;
      min-height: 40px;
    }

    tbody:nth-of-type(odd):not(.row_selected) {
      background-color: $ultra-light-grey;
    }

    .gridrowItem:hover {
      background-color: $light-grey;
    }

    .row_selected {
      > tr:first-of-type {
        background-color: $browse-selected-color;
        color: $white;
      }
    }

    .checkbox-wrapper {
      background-color: $background;
      width: 32px;

      .popup-content & {
        padding-left: 0.7rem;
      }

      &.checkbox-mobile {
        position: absolute;
        left: 1em;
        top: -15px;
        z-index: 10;

        .custom-control-label::before, .custom-control-label::after {
          width: 1.5rem;
          height: 1.5rem;
        }

        &.select-all {
          left: 9px;
        }
      }

      &.select-all {
        margin-top: 0;
      }
    }


    th {
      color: $browse-header-color;
    }

    .gridHeader {
      padding: 0 1em 1em 1em;
      white-space: nowrap;

      &.notSortable {
        cursor: unset;
      }

      .fa-layers {
        svg {
          color: $medium-grey;

          &.is-active {
            color: inherit
          }
        }
      }
    }

    /*Mobile*/
    .cards-view-mobile {


      tr.isMobile {
        border-bottom: 15px solid $white;
      }

      .header-mobile {
        font-weight: 700;
        color: $font-color;
      }

      .cell-content-mobile {
        font-weight: 700;
        color: $headings-color;
      }

      .row-container-mobile {
        display: flex;
        flex-direction: row;
        overflow-x: hidden;
        justify-content: space-between;
      }


      .buttons-wrapper-mobile {
        color: $white;
        text-align: center;
        width: 100%;
        justify-content: flex-end;

        &.buttons-wrapper-mobile.buttons-wrapper-mobile {
          //overwrite row padding
          padding: 5px;
        }

        div[class^="col"] {
          &:not(:first-child) {
            margin-left: 0.5rem;
          }
        }

        .icon-button.white-icon-buttons {
          font-size: 22px;
          color: $white;
          background: $headings-color;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 25px;
        }
      }
    }

    .grid-card-item {
      min-height: 95px;

      .grid-card-col {
        word-break: break-all;
        padding: 0 !important;

        @media (min-width: 400px) {
          flex: 1 0 50%;
          max-width: 50%;
        }
      }

      .grid-card-item-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        justify-content: center;

        .row {
          padding: 3px 0;
        }
      }

      .card-title {
        background-color: $headings-color;
        color: $white;
        width: 100%;
        padding: 0.5em;
        font-weight: bold;
      }

      .card-content {
        display: flex;
        flex-flow: column wrap;
        padding: 5px 10px;
        width: 100%;

        p {
          order: 999;
          margin-bottom: 0.2em;
        }
      }
    }

  }

  .popup-content {
    .table-wrapper {
      &:not(.isMobile) {
        margin-left: 0;
        width: 100%;
      }
    }
  }

  .lh-wrapper-switcher {
    .table-wrapper {
      &:not(.isMobile) {
        margin-left: 0;
        width: unset;
      }
    }
  }

  .expand-row {
    .grid-lite {
      .table-wrapper {
        width: 100%;
      }

      .gridrow {
        transform: translateX(0);
      }
    }
  }
</style>
