<!--LhPlanner-->
<!--attributes:-->
<!--weekends (default = false) Boolean -> set to true for 7 day week in planner.-->
<!--view (default = week) String -> week/month.-->

<template>
  <div class="planner-wrapper">
    <div class="action-block">
      <b-row align-v="center" align-h="end" no-gutters>
        <b-col cols="6" md="auto" class="add-appointment-button">
          <lh-button :obj="buttons.addAppointment"></lh-button>
        </b-col>
        <b-col cols="6" md="auto" class="add-appointment-button" v-if="obj.attributes.actions.addUnavailability">
          <lh-button :obj="buttons.addUnavailability"></lh-button>
        </b-col>
        <b-col cols="6" md="auto" class="toggle-button" v-if="!isMobile">
          <lh-button :obj="buttons.toggleWeek"></lh-button>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col cols="12" :xl="setCalendarSize">
        <b-calendar
            v-show="isDesktop"
            v-model="selectedDate"
            :date-info-fn="highlightedDays"
            start-weekday="1"
            selected-variant="secondary"
            @input="handleDateChange"
            :date-format-options="dateFormat"
            hide-header
        ></b-calendar>
        <b-row v-if="!isDesktop" class="mb-3">
          <b-col cols="12" sm="auto" class="d-flex align-items-center mt-3 mb-0 mt-sm-0 order-2 order-sm-1">
            <div class="week-nr ">{{ $t('GENERAL_WEEK') }} {{ selectedDate | moment('w') }}</div>
          </b-col>
          <b-col class="order-1 order-sm-2">
            <b-input-group>
              <b-input-group-append>
                <b-form-datepicker
                    v-model="selectedDate"
                    :date-info-fn="highlightedDays"
                    start-weekday="1"
                    selected-variant="secondary"
                    @input="handleDateChange"
                    :inline="true"
                    :date-format-options="dateFormat"
                    button-only
                    ref="calendarButton"
                ></b-form-datepicker>
              </b-input-group-append>
              <b-form-input
                  autocomplete="off"
                  type="text"
                  v-model="formattedDate"
                  @click="openCalendar"
              ></b-form-input>
            </b-input-group>


          </b-col>
        </b-row>

      </b-col>
      <b-col>
        <!-- WEEK VIEW CALENDAR -->
        <template v-if="weekView">
          <div class="week-wrapper">
            <b-row no-gutters class="no-wrap-md">
              <b-col v-for="(day, index) in plannerData" :key="day.date" cols="12" md="auto" lg="">
                <div :class="[`index--${index}`, 'day', {'day--background': checkEvent(day.date).length === 1}, {'day--today': isToday(day.date)}]" :style="checkEvent(day.date, true)">
                  <div :class="['day__header', {'day__header--today': isToday(day.date)}]">
                    <h4 class="day__title">{{ days[index] }}</h4>
                    <div>{{ day.date | moment('DD/MM/YYYY') }}</div>
                  </div>
                  <template v-if="checkEvent(day.date).length > 0">
                    <div class="event" v-for="(event, index) in checkEvent(day.date)" :style="{borderColor: event.colour}">
                      <div class="appointment__icons appointment__icons--action" v-if="event.remark">
                        <div class="appointment__button appointment__button--remark" :id="'tooltip' + event.date + index">
                          <font-awesome-icon :icon="['fas', 'comment-alt-lines']"></font-awesome-icon>
                        </div>
                      </div>
                      <div class="event-title">{{ event.description }}</div>
                      <div class="event-time" v-if="event.startTime">
                        {{ event.startTime }} - {{ event.endTime }}
                      </div>
                      <div class="event-rep" v-if="event.rep">
                        {{ event.rep }}
                      </div>
                      <template v-if="event.remark">
                        <b-tooltip :target="'tooltip' + event.date + index" triggers="hover">
                          {{ event.remark }}
                        </b-tooltip>
                      </template>
                    </div>
                  </template>
                  <draggable
                      :class="['day__body']"
                      tag="ul"
                      :data-date="day.date"
                      v-model="day.appointments"
                      v-bind="dragOptions"
                      @start="drag = true"
                      @end="drag = false"
                      @add="onUpdate"
                      @update="onUpdate"
                      :enable-native-drag="true"
                      :sort="false"
                  >
                    <li class="appointment" v-for="(app, index) in day.appointments" :data-guid="app.guid" :key="app.name + index">
                      <div class="appointment__header">
                        <div>{{ app.name }}</div>
                      </div>
                      <div class="appointment__body">
                        <div class="appointment__address">
                          <div class="mb-1" v-if="app.startTime">{{ app.startTime }}
                            <span v-if="app.endTime"> - {{ app.endTime }}</span></div>
                          <div>{{ app.streetName }} {{ app.houseNumber }}</div>
                          <div>{{ app.postalCode }} {{ app.city }}</div>
                          <div v-if="app.repName">{{ app.repName }}</div>
                        </div>
<!--                        <div><a :href="`tel:${app.phoneNumber}`">{{ app.phoneNumber }}</a></div>-->
<!--                        <div><a :href="`mailto:${app.email}`">{{ app.email }}</a></div>-->
                      </div>
                      <div class="appointment__icons appointment__icons--action">
                        <div class="appointment__button appointment__button--remark" v-if="app.remark" :id="'tooltip' + app.guid">
                          <font-awesome-icon :icon="['fas', 'comment-alt-lines']"></font-awesome-icon>
                        </div>
                        <div class="appointment__button appointment__button--warning" v-if="app.warning" :id="'warning' + app.guid">
                          <font-awesome-icon :icon="['fas', 'exclamation-circle']"></font-awesome-icon>
                        </div>
                        <div class="appointment__button appointment__button--action"
                             @click="showModal(`app-${app.guid + index}`)">
                          <font-awesome-icon :icon="['fas', 'ellipsis-h']"></font-awesome-icon>
                        </div>
                      </div>
                      <!--MODAL-->
                      <b-modal :id="`app-${app.guid + index}`" centered hide-footer modal-class="planner-modal">
                        <template v-slot:modal-title>
                          {{ $t('GENERAL_ACTIONS') }} {{ $t('GENERAL_FOR') }} {{ app.name }} {{ day.date | moment('DD/MM/YYYY') }} <span v-if="app.startTime">{{ app.startTime }}</span><span v-if="app.endTime"> - {{ app.endTime }}</span>
                        </template>
                        <div class="button-wrapper pt-2 pb-2 mb-1 icon-circle" v-if="app.phoneNumber" @click="closeModal(`app-${app.guid + index}`)">
                          <button class="button transparent">
                            <span class="button__icon">
                            <font-awesome-icon :icon="['fas', 'phone-alt']"></font-awesome-icon>
                            </span>
                            <a :href="`tel:${app.phoneNumber}`">{{ app.phoneNumber }}</a>
                          </button>
                        </div>
                        <div class="button-wrapper pt-2 pb-2 mb-1 icon-circle" v-if="app.email" @click="closeModal(`app-${app.guid + index}`)">
                          <button class="button transparent">
                            <span class="button__icon">
                            <font-awesome-icon :icon="['fas', 'envelope']"></font-awesome-icon>
                            </span>
                            <a :href="`mailto:${app.email}`">{{ app.email }}</a>
                          </button>
                        </div>
                        <template v-for="button in app.buttons">
                            <lh-button :modalId="`app-${app.guid + index}`" :obj="button" class="w-100 icon-circle pt-2 pb-2 mb-1 button-border"></lh-button>
                        </template>
                      </b-modal>
                      <!--TOOLTIP-->
                      <template v-if="app.remark">
                        <b-tooltip :target="'tooltip' + app.guid" triggers="hover">
                          {{ app.remark }}
                        </b-tooltip>
                      </template>
                      <template v-if="app.warning">
                        <b-tooltip :target="'warning' + app.guid" triggers="hover">
                          {{ $t(app.warning) }}
                        </b-tooltip>
                      </template>
                    </li>
                  </draggable>
                </div>
              </b-col>
            </b-row>
          </div>
        </template>

        <!-- MONTH VIEW CALENDAR -->
        <template v-else>
          <b-row>
            <b-col>
              <b-row no-gutters :class="['d-none day__header-row', {'day--weekends': weekends}]">
                <b-col v-for="n in weekLength" cols="" class="border-child">
                  <div class="day__header day__header--border"><span class="day__title">{{ daysShort[n - 1] }}</span></div>
                </b-col>
              </b-row>
              <b-row no-gutters class="month-wrapper">
                <template v-if="plannerData.length">
                  <div :class="['day', 'day--month', {'day--weekends': weekends}, {'day--background': checkEvent(day.date)}]"
                       v-for="(day, index) in this.plannerData"
                       :style="checkEvent(day.date, true)">
                    <div :class="['day__header', {'day__header--today': isToday(day.date)}]">
                      <div>
                        <span class="d-xl-none">{{ day.date | moment('dddd') }} </span>
                        {{ day.date | moment('DD/MM/YYYY') }}
                      </div>
                    </div>
                    <div :class="['day__body', {'day__body--today': isToday(day.date)}]">
                      <template v-if="checkEvent(day.date).length > 0">
                        <div class="event" v-for="event in checkEvent(day.date)">
                          {{ event.description }}
                        </div>
                      </template>
                      <div class="appointment" v-if="day.appointments.length">
                        <div class="appointment__icons">
                          <div class="appointment__button appointment__button--warning" v-if="day.appointments[0].warning" :id="'warning' + day.appointments[0].guid" v-tooltip="$t(day.appointments[0].warning)">
                            <font-awesome-icon :icon="['fas', 'exclamation-circle']"></font-awesome-icon>
                          </div>
                        </div>
                        <div class="appointment__header">
                          <div>{{ day.appointments[0].name }}</div>
                        </div>
                        <div class="appointment__body">
                          <div class="appointment__address">
                            <div v-if="day.appointments[0].startTime">{{ day.appointments[0].startTime }}<span v-if="day.appointments[0].endTime"> - {{ day.appointments[0].endTime }}</span></div>
                            <div>{{ day.appointments[0].postalCode }} {{ day.appointments[0].city }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="day__counter-wrapper">
                        <div class="day__button day__button--small" v-if="day.appointments.length > 1"
                             @click="showModal(`day-${day.date}`)" :id="`day-${day.date}`">
                          <div class="day__counter-text">
                            <span>+ {{ day.appointments.length - 1 }}</span>
                          </div>
                        </div>
                        <div v-else-if="day.appointments.length" class="appointment__buttons">
                          <component class="day__button day__button--small"
                                     v-for="button in day.appointments[0].buttons"
                                     :is="button.type"
                                     :key="button.key + index"
                                     :obj="button"
                          ></component>
                        </div>
                      </div>
                    </div>

                    <!-- MODAL -->
                    <b-modal :id="`day-${day.date}`" centered hide-footer>
                      <template v-slot:modal-title>
                        {{ $t('GENERAL_APPOINTMENTS') }} {{ day.date | moment('DD/MM/YYYY') }}
                      </template>
                      <div class="appointment appointment--popover" v-for="app in day.appointments">
                        <div class="d-flex justify-content-between align-items-center">
                          <div>
                            <div class="appointment__header">
                              <span>
                                {{ app.name }}
                              </span>
                              <span class="appointment__button appointment__button--warning" v-if="app.warning" :id="'warning' + app.guid" v-tooltip="$t(app.warning)">
                                <font-awesome-icon :icon="['fas', 'exclamation-circle']"></font-awesome-icon>
                              </span>
                            </div>
                            <div class="appointment__body">
                              <div class="appointment__address">
                                <div v-if="app.startTime">{{ app.startTime }}<span v-if="app.endTime"> - {{ app.endTime }}</span></div>
                                <div>{{ app.postalCode }} {{ app.city }}</div>
                              </div>
                            </div>
                          </div>
                          <div class="appointment__buttons">
                            <component class="day__button day__button--small"
                                       v-for="button in app.buttons" :is="button.type" :key="button.key"
                                       :obj="button" :modalId="`day-${day.date}`"></component>
                          </div>
                        </div>
                      </div>
                    </b-modal>
                  </div>
                </template>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import datepicker from 'vuejs-datepicker'
import { mapState, mapActions } from 'vuex'
import { en, nl, fr } from 'vuejs-datepicker/dist/locale'
import draggable from 'vuedraggable'
import moment from 'moment'
import { eventBus } from '@/services/event-bus'

export default {
  name: 'LhPlanner',
  components: {
    datepicker,
    draggable
  },
  props: {
    obj: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      languages: {
        en: en,
        nl: nl,
        fr: fr
      },
      drag: false,
      weekends: false,
      weekLength: 5,
      selectedRange: [],
      selectedDate: null,
      plannerData: [],
      weekView: true,
    }
  },
  computed: {
    ...mapState({
      localeStore: 'localeStore',
      windowSize: 'windowSize',
      isMobile: 'isMobile'
    }),
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        delay: 400,
        delayOnTouchOnly: true
      }
    },
    buttons () {
      return {
        toggleWeek: {
          'key': 'toggle-week-button',
          'type': 'LhButton',
          'actions': [
            {
              event: 'click',
              method: 'planner-toggle-view',
              type: 'emit',
              url: ''
            }
          ],
          'attributes': {
            'content': this.toggleWeekText,
            'name': 'toggle-week-button',
            icon: 'calendar-alt',
            transparent: true,
            disabled: this.loadingJSON
          },
          'styles': [],
          'children': []
        },
        addAppointment: {
          'key': 'toggle-week-button',
          'type': 'LhButton',
          'actions': [
            {
              event: 'click',
              method: 'GET',
              type: 'display',
              url: '/appointments/add'
            }
          ],
          'attributes': {
            'content': 'PLANNER_ADD_APPOINTMENT',
            'name': 'add-appointment-button',
            icon: 'plus-circle',
            transparent: true,
            disabled: this.loadingJSON
          },
          'styles': [],
          'children': []
        },
        addUnavailability: {
          'key': 'toggle-week-button',
          'type': 'LhButton',
          'actions': [],
          'attributes': {
            'content': 'PLANNER_ADD_UNAVAILABILITY',
            'name': 'add-unavailability-button',
            icon: 'plus-circle',
            transparent: true,
            disabled: this.loadingJSON
          },
          'styles': [],
          'children': []
        },
      }
    },
    toggleWeekText () {
      // TODO: add translations
      return this.weekView ? 'Month View' : 'Week View'
    },
    isDesktop () {
      if (this.windowSize.windowWidth) return this.windowSize.windowWidth >= 1440
    },
    setCalendarSize () {
      return this.isDesktop ? 'auto' : '12'
    },
    dateFormat () {
      // TODO: remove?
      return this.weekView ? { 'year': 'numeric', 'month': 'long', 'day': undefined } : {
        'year': 'numeric',
        'month': 'long',
        'day': undefined
      }
    },
    formattedDate () {
      let start = moment(this.selectedRange[0]).format('D/M/YYYY')
      let end = moment(this.selectedRange[1]).format('D/M/YYYY')
      let month = moment(this.selectedDate).format('MMMM YYYY')
      return this.weekView ? `${start} - ${end}` : month
    },
    days () {
      return [
        this.$t('GENERAL_MONDAY'),
        this.$t('GENERAL_TUESDAY'),
        this.$t('GENERAL_WEDNESDAY'),
        this.$t('GENERAL_THURSDAY'),
        this.$t('GENERAL_FRIDAY'),
        this.$t('GENERAL_SATURDAY'),
        this.$t('GENERAL_SUNDAY'),
      ]
    },
    daysShort () {
      return [
        this.$t('GENERAL_MONDAY_SHORT'),
        this.$t('GENERAL_TUESDAY_SHORT'),
        this.$t('GENERAL_WEDNESDAY_SHORT'),
        this.$t('GENERAL_THURSDAY_SHORT'),
        this.$t('GENERAL_FRIDAY_SHORT'),
        this.$t('GENERAL_SATURDAY_SHORT'),
        this.$t('GENERAL_SUNDAY_SHORT'),
      ]
    }
  },
  methods: {
    ...mapActions({
      post: 'postLocal'
    }),
    handleDateChange (val) {
      if (this.weekView) {
        if (!moment(val).isSame(this.selectedRange[0], 'isoWeek')) {
          this.setDateRange(val, 'isoWeek')
          this.getData(val)
        }
      } else {
        if (!moment(val).isSame(this.selectedRange[0], 'month')) {
          this.setDateRange(val, 'month')
          this.getData(val)
        }
      }
    },
    setDateRange (date, view) {
      let start = moment(date).startOf(view).toDate()
      let end = moment(date).endOf(view).toDate()
      this.selectedRange = [start, end]
    },
    handleToggleWeekView () {
      this.weekView = !this.weekView
      this.getData(this.selectedDate)
    },
    onUpdate (event) {
      let payload = {
        guid: event.item.dataset.guid,
        date: event.to.dataset.date
      }
      console.log(payload)
      this.post({ url: 'appointments/update/dragdrop', payload: payload })
          .then(response => {
            console.log(response)
          })
          .catch(error => {
            console.log(error)
            this.getData(this.selectedDate)
          })
    },
    getData (date) {
      this.plannerData = []
      let view = this.weekView ? 'week' : 'month'
      this.$router.push({path: `/schedule/${view}/${date}`})
    },
    highlightedDays (ymd, date) {
      if (this.weekView) {
        return moment(ymd).isSame(moment(this.selectedDate), 'isoWeek') ? 'table-secondary' : ''
      } else {
        return moment(ymd).isSame(moment(this.selectedDate), 'month') ? 'table-secondary' : ''
      }
    },
    isToday (date) {
      return moment(date).isSame(new Date(), 'day')
    },
    showModal (id) {
      this.$root.$emit('bv::show::modal', id)
    },
    closeModal (id) {
      this.$root.$emit('bv::hide::modal', id)
    },
    openCalendar () {
      let el = this.$refs.calendarButton.$refs.control.$refs.toggle
      el.click()
    },
    checkEvent (date, colour) {
      if (this.obj.attributes.events) {
        if (this.obj.attributes.events.length) {
          let events = []
          this.obj.attributes.events.forEach(event => {
            if (moment(event.date).isSame(date, 'day')) {
              events.push(event)
            }
          })
          if (colour && events.length === 1) return { '--bg-colour': this.hexToRgb(events[0].colour) }
          return events
        }
      }
    },
    hexToRgb (hex) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result ?
        parseInt(result[1], 16) + ',' +
        parseInt(result[2], 16) + ',' +
        parseInt(result[3], 16)
          : null
    },
    handleUpdateAppointments (payload) {
      console.log(payload)
      // this.$set(this, 'plannerData', this.obj.attributes.data)
      this.plannerData = payload.attributes.data
    }
  },
  watch: {
    'obj.attributes.view': function (val)  {
      if (val.toLowerCase().trim() === 'week') {
        this.weekView = true
      }
      if (val.toLowerCase().trim() === 'month') {
        this.weekView = false
      }
      this.plannerData = this.obj.attributes.data
    },
    'obj.attributes.data': function (data) {
      this.plannerData = data
    },
    'obj.attributes.selectedDate': function (date) {
      this.selectedDate = date
      this.setDateRange(date, this.weekView ? 'isoWeek' : 'month')
    }
  },
  created () {
    if (this.obj.attributes?.actions?.add) {
      this.buttons.addAppointment.actions = []
      this.buttons.addAppointment.actions.push(this.obj.attributes.actions.add)
    }
    if (this.obj.attributes?.actions?.addUnavailability) {
      this.buttons.addUnavailability.actions = []
      this.buttons.addUnavailability.actions.push(this.obj.attributes.actions.addUnavailability)
    }
  },
  mounted () {
    if (this.obj.attributes.weekends) {
      this.weekends = true
      this.weekLength = 7
    }
    this.weekView = this.obj.attributes.view.toLowerCase().trim() === 'week'
    this.plannerData = this.obj.attributes.data
    if (this.obj.attributes.selectedDate) {
      this.selectedDate = this.obj.attributes.selectedDate
      this.setDateRange(this.obj.attributes.selectedDate, this.weekView ? 'isoWeek' : 'month')
    }
    eventBus.$on('planner-toggle-view', this.handleToggleWeekView)
    eventBus.$on('update-planner', this.handleUpdateAppointments)
  },
  beforeDestroy () {
    eventBus.$off('planner-toggle-view', this.handleToggleWeekView)
    eventBus.$off('update-planner', this.handleUpdateAppointments)
  }
}
</script>

<style scoped lang="scss">
.planner-wrapper {
  max-width: 100%;
}

.week-wrapper {
  max-width: 100%;
  max-height: calc(66 * var(--vh-unit));
  overflow-x: auto;

  [class^="col"] {
    &:not(:last-child) {
      .day {
        border-right: none;
      }
    }
  }

  .appointment {
    cursor: grab;

    &.ghost, &.sortable-chosen {
      cursor: grabbing;
    }
    &.sortable-chosen {
      border-color: darken($medium-light-grey, 15%);
    }
  }
}

.day {
  border: 1px solid $light-grey;
  min-height: 150px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  @include media-breakpoint-up(md) {
    width: 170px;
    max-width: 170px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 700px;
    width: 100%;
    max-width: 100%;
  }

  &__header {
    text-align: center;
    background: $ultra-light-grey;
    padding: 0.3rem;
    border-bottom: 1px solid $light-grey;
    overflow: hidden;
    text-transform: lowercase;
    font-size: 0.88rem;

    @include media-breakpoint-only(xs) {
      justify-content: space-between;
    }

    @include media-breakpoint-down(sm) {
      display: flex;
      align-items: baseline;
      padding-right: 10px;
      padding-left: 10px;

      .day__title {
        margin-right: 0.5rem;
      }
    }

    &--border {
      &:not(:last-child) {
        border-right: 1px solid $light-grey;
      }
    }

    &--today {
      background: rgba($secondary-color, 0.4);
      color: darken($secondary-color, 20%);
      border-color: $secondary-color;
    }

    &-row {
      @include media-breakpoint-up(lg) {
        &:not(.day--weekends) {
          display: flex !important;
        }
      }

      @media (min-width: 1440px) {
        display: flex !important;
      }
    }
  }

  &__title {
    margin-bottom: 0;
    color: $font-color;
    font-size: 1.15rem;
  }

  &__body {
    padding: 5px;
    flex-grow: 1;
    margin-bottom: 0;
    list-style: none;
    display: flex;
    flex-flow: column nowrap;
  }

  &__counter-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem 0;
    margin-top: auto;
  }

  &__button-wrapper {
    display: flex;
    padding: 0.5rem 0;
  }

  &__button {
    background: $primary-color;
    color: $white;
    width: 3.125rem;
    height: 3.125rem;
    margin: auto;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-weight: bold;
    font-size: 1.2rem;
    cursor: pointer;

    .day--today & {
      background: rgba($secondary-color, 0.4);
      color: darken($secondary-color, 20%);
    }

    &--small {
      width: 40px;
      height: 40px;
      font-size: 0.9em;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }

    &--tiny {
      width: 25px;
      height: 25px;
      font-size: 0.85em;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  &--background {
    background-color: #{'rgba(var(--bg-colour), 0.2)'};

    .day__header {
      background-color: #{'rgba(var(--bg-colour), 0.2)'};
    }
  }

  &--today {
    background: rgba($secondary-color, 0.2);
  }

  &--month {
    border: none;
    flex: 100%;
    text-align: center;
    min-height: unset;
    height: unset;

    @include media-breakpoint-only(sm) {
      flex: 50%;
      max-width: 50%;

      &:nth-child(2n+0) {
        .day__body {
          border-right: none;
        }
      }
    }

    @include media-breakpoint-only(md) {
      flex: 33.33%;
      max-width: 33.33%;

      &:nth-child(3n+0) {
        .day__body {
          border-right: none;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      flex: 20%;
      max-width: 20%;

      &:nth-child(5n+0) {
        .day__body {
          border-right: none;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      flex: 20%;
      max-width: 20%;
    }

    @media (min-width: 1440px) {
      &.day--weekends {
        flex: 14.28%;
        max-width: 14.28%;

        &:nth-child(5n+0) {
          .day__body {
            border-right: 1px solid $light-grey;
          }
        }

        &:nth-child(7n+0) {
          .day__body {
            border-right: none;
          }
        }
      }
    }

    .appointment {
      flex-grow: 1;
      text-align: center;
      padding-top: 0.5rem;
      padding-bottom: 0.3rem;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      &__button {
        &--warning {
          padding-left: 1em;
        }
      }
    }

    .event {
      margin: 0 0 5px 0;
      position: relative;
    }

    .day__header {
      background: $primary-color;
      color: $white;
      font-weight: bold;
      font-size: 0.88rem;
      border-right: 1px solid darken($primary-color, 10%);

      &--today {
        background: $secondary-color;
        border-top-color: $secondary-color;
        border-bottom-color: $secondary-color;
      }
    }

    .day__body {
      border-right: 1px solid $light-grey;
      border-bottom: 1px solid $light-grey;
      min-height: 142px;

      &--today {
        background: rgba($secondary-color, 0.2);
      }
    }
  }
}

.month-wrapper {
  border: 1px solid $light-grey;
}

.appointment {
  background: $white;
  padding: 0.4rem 1.70rem 0.6rem 1rem;
  border: 1px solid $medium-light-grey;
  text-align: left;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &__header {
    margin-bottom: 3px;
    font-size: 0.88rem;
    line-height: 1.2;
    font-weight: bold;
  }

  &__body {
    font-size: 0.8rem;
    word-break: break-all;
  }

  &__address {
    margin-bottom: 3px;
  }

  &__icons {
    display: flex;
    flex-flow: column nowrap;
    top: 5px;
    position: absolute;
    right: 5px;

    &--action {
      bottom: 5px;
    }
  }

  &__buttons {
    display: flex;
    flex-flow: row nowrap;
  }

  &__button {
    color: $medium-grey-alt;
    cursor: pointer;

    &--warning {
      color: $danger;
      cursor: initial;
    }
    &--remark {
      cursor: initial;
    }
    &--action {
      margin-top: auto;
    }
  }

  &--popover {
    padding: 0.8rem 1rem 1rem 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.event {
  font-size: 0.88rem;
  line-height: 1.2;
  background: $white;
  padding: 0.2rem 0.5rem;
  border: 1px solid $medium-light-grey;
  margin: 5px 5px 0 5px;
  position: relative;

  .appointment__icons {
    top: 3px;
  }

  &-title {
    font-weight: bold;
    margin-bottom: 3px;
  }

  &-time,
  &-rep {
    font-size: 0.8rem;
    word-break: break-all;
    &:last-child {
      margin-bottom: 3px;
    }
  }
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  height: 100%;
  padding: 0.5rem 1.2rem;
}

.border-left {
  border-right: 1px solid $ultra-light-grey;
}

.border-child {
  &:not(:first-child) {
    .day__header--border {
      border-left: 1px solid $medium-light-grey;
    }
  }
}

.button-wrapper {
  &:not(:first-child) {
    border-top: 1px solid $medium-light-grey;
  }
}

button {
  a {
    text-decoration: none;
  }
}
</style>