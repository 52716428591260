<template>
  <div class="navigationItem" :style="checkStyles">
    <router-link
        :to="{path: obj.attributes.href , params: {obj: obj.attributes.content}}"
    >{{$t(obj.attributes.content)}}
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'NavigationItem',
    props: {
      obj: {
        type: Object
      }
    },
    methods: {},
    computed: {}
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
