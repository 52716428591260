<template>
  <div class="editor-container">
    <div class="add-block">
      <b-row align-v="center" no-gutters>
        <b-col cols="12" md="auto">
          <b-form-select
              id="add-block-select"
              v-model="addBlock"
              :options="addableBlocks"
              :disabled="addBlockDisabled"
          >
            <template v-slot="first">
              <b-form-select-option :value="null">{{ $t('Select a block') }}</b-form-select-option>
            </template>
          </b-form-select>
          <lh-button :obj="buttons.add" @click.native="handleAddBlock"></lh-button>
        </b-col>
        <b-col cols="6" md="auto" class="save-button">
          <lh-button :obj="buttons.save" @click.native="handleSavePage"></lh-button>
        </b-col>
      </b-row>
      <lh-hr></lh-hr>
    </div>

    <div class="editor">
      <transition name="fade">
        <!-- WIREFRAME-->
        <div :class="['wireframe-wrapper bootstrap-3', {expanded: wireframeExpanded, collapsed: editorExpanded, preview: preview}]"
             v-show="!editorExpanded || !smallScreen" id="wireframe-wrapper">
          <div class="container" id="wireframe-container">
            <draggable
                class="list-columns"
                tag="ul"
                v-model="blocks"
                v-bind="dragOptions"
                @start="drag = true"
            >
              <template v-for="block in blocks">
                <div :class="['content-block', {active: activeBlock === block.id}, block.id]" :id="block.id"
                     :key="block.id"
                     @dblclick="focusInput(block.id)">
                  <div class="content-block-tools">
                    <div class="edit-icon">
                      <font-awesome-icon :icon="['fas', 'pencil-alt']"
                                         @click="focusInput(block.id)"></font-awesome-icon>
                    </div>
                    <div class="delete-icon">
                      <font-awesome-icon :icon="['fas', 'trash-alt']"
                                         @click="handleDeleteBlock(block.guid)"></font-awesome-icon>
                    </div>
                  </div>
                  <div class="wireframe-block" v-html="block.value"></div>
                  <!--                <div>{{block.value}}</div>-->
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <!-- EDITOR-->
        <div :class="['editor-wrapper', {expanded: editorExpanded, collapsed: wireframeExpanded, preview: preview}]"
             v-show="!wireframeExpanded || !smallScreen">
          <form>
            <template v-for="block in blocks">
              <div class="editor-row-title">
                <h4 :key="block.guid + 'label'">{{ block.label }}</h4>
              </div>
              <div :class="['editor-row', {columns: largeEditor}, `block${block.id}`]" :key="'block' + block.id"
                   @click="scrollWireframe = true" :id="'block' + block.id">
                <div :class="['editor-block', child.attributes.type]" v-for="child in block.children">
                  <component :is="child.type"
                             :obj="child"
                             :key="child.key"
                             :parentKey="obj.key"
                             :customLabel="child.attributes.name"
                  ></component>
                </div>
              </div>
            </template>
          </form>
        </div>
      </transition>
    </div>
    <div class="icon-row">
      <div @click="toggleWireframe" class="expand-icon" v-if="!editorExpanded && !smallScreen">
        <font-awesome-icon :icon="['fas', 'arrow-to-right']"
                           v-if="!wireframeExpanded && !editorExpanded"></font-awesome-icon>
        <font-awesome-icon :icon="['fas', 'columns']"
                           v-if="wireframeExpanded"></font-awesome-icon>
      </div>
      <div class="expand-icon" @click="switchComponent" v-if="wireframeExpanded || editorExpanded">
        <font-awesome-icon :icon="['fas', 'sync']"></font-awesome-icon>
      </div>
      <div class="expand-icon" @click="togglePreview" v-if="!smallScreen">
        <font-awesome-icon :icon="['fas', 'line-columns']"></font-awesome-icon>
      </div>
      <div @click="toggleEditor" class="expand-icon" v-if="!wireframeExpanded && !smallScreen">
        <font-awesome-icon :icon="['fas', 'arrow-to-left']"
                           v-if="!wireframeExpanded && !editorExpanded"></font-awesome-icon>
        <font-awesome-icon :icon="['fas', 'columns']" v-if="editorExpanded"></font-awesome-icon>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import { eventBus } from '../services/event-bus'
import isObject from 'lodash'
import draggable from 'vuedraggable'

export default {
  name: 'LhEditor',
  components: {
    draggable
  },
  props: {
    obj: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      editorExpanded: false,
      wireframeExpanded: false,
      preview: false,
      blocks: [],
      originalBlocks: [],
      addableBlocks: [],
      addBlock: null,
      activeInput: '',
      activeBlock: '',
      scrollWireframe: true,
      placeholder: {
        text: 'Lorem ipsum',
        textarea: 'Vivamus ex felis, vehicula nec sapien nec, ultricies finibus lacus. Morbi dictum varius tortor eu efficitur.',
        'text-editor': 'Suspendisse sagittis non sem at ullamcorper. Etiam suscipit nibh vestibulum mauris sagittis, ac lacinia mauris fermentum. Nullam sit amet eros ultrices, venenatis neque eu, sagittis massa.',
        file: 'https://via.placeholder.com/810x500/E6E7E8?text=placeholder'
      },
    }
  },
  computed: {
    ...mapState({
      windowSize: 'windowSize',
      loading: 'loadingJSON'
    }),
    smallScreen () {
      return this.windowSize.windowWidth < 1200
    },
    largeEditor () {
      return this.windowSize.windowWidth > 2500 || this.windowSize.windowWidth > 1500 && this.editorExpanded
    },
    dragOptions () {
      return {
        drag: false,
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    addBlockDisabled () {
      return !this.addableBlocks.length
    },
    buttons () {
      return {
        add: {
          'key': 'add-block-button',
          'type': 'LhButton',
          'actions': [],
          'attributes': {
            'content': 'GENERAL_ADD',
            'name': 'block-add-button',
            icon: 'plus-circle',
            transparent: true,
            disabled: this.addBlockDisabled || !this.addBlock
          },
          'styles': [],
          'children': []
        },
        save: {
          'key': 'save-page-button',
          'type': 'LhButton',
          'actions': [],
          'attributes': {
            'content': 'GENERAL_SAVE',
            'name': 'page-save-button',
            icon: 'save',
            transparent: true,
            disabled: this.loadingJSON
          },
          'styles': [],
          'children': []
        }
      }
    }
  },
  methods: {
    ...mapActions({
      fetch: 'fetchJSONLocal',
      post: 'postLocal'
    }),
    toggleWireframe () {
      this.wireframeExpanded = !this.wireframeExpanded
      this.preview = false
      this.activeBlock = ''
      this.activeInput = ''
    },
    toggleEditor () {
      this.editorExpanded = !this.editorExpanded
      this.preview = false
      this.activeBlock = ''
      this.activeInput = ''
    },
    switchComponent () {
      this.toggleWireframe()
      this.toggleEditor()
    },
    scrollToBlock (id) {
      let el = document.getElementsByClassName(id)
      el[0].scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    },
    togglePreview () {
      this.preview = !this.preview
      this.editorExpanded = false
      this.wireframeExpanded = false
    },
    focusInput (id) {
      console.log('focusing input', id)
      this.scrollWireframe = false
      if (!this.smallScreen) {
        this.editorExpanded = false
        this.wireframeExpanded = false
        this.scrollToBlock('block' + id)
        let input = this.blocks.find(block => block.id === id).children[0]
        eventBus.$emit('focus-input-' + input.attributes.id, input.attributes.id)
      }
    },
    updateInput (e) {
      console.log('update input: ', e)
      let parent = this.blocks.find(block => block.id === e.parent)
      let field = parent.children.find(child => child.attributes.name === e.name)

      // update input in data
      this.$set(field.attributes, 'value', e.value)
      if ((field.attributes.type).toLowerCase() === 'file' && e.value instanceof File) {
        console.log('this is a file')
        let url = ''
        e.value ? url = window.URL.createObjectURL(e.value) : url = this.placeholder.file
        if (url) this.$set(field.attributes, 'url', url)
      }
      // replace field tags in parent block html with new value (all fields)
      this.parseBlock(parent)
    },
    findReplaceString (string, find, replace) {
      if ((/[a-zA-Z_]+/g).test(string)) {
        return string.replace(new RegExp('\{\{(?:\\s+)?(' + find + ')(?:\\s+)?\}\}'), replace)
      } else {
        throw new Error('Find statement does not match regular expression: /[a-zA-Z\_]+/')
      }
    },
    setActiveInput (payload) {
      // set active input parent block to highlight in wireframe view
      this.activeInput = payload.id
      this.activeBlock = payload.parent
      if (this.scrollWireframe === true) {
        this.scrollToBlock(payload.parent)
      }
    },
    clone (items) {
      if (Array.isArray(items)) {
        return items.map(item => {
          if (Array.isArray(item)) {
            return clone(item)
          } else if (isObject(item)) {
            return JSON.parse(JSON.stringify(item))
          } else {
            return item
          }
        })
      } else if (isObject(items)) {
        return JSON.parse(JSON.stringify(items))
      }
    },
    getAddBlocks () {
      // get blocks linked to the project that can be added to this page
      this.fetch(`get/blocks/${this.obj.attributes.pageGuid}`).then((response) => this.addableBlocks = response.data)
    },
    handleAddBlock () {
      console.log(this.addBlock)
      if (this.addBlock) {
        let data = {
          url: `add/block/${this.obj.attributes.pageGuid}`,
          payload: {
            block: this.addBlock.guid,
            order: this.blocks.length + 1
          }
        }
        let id = ''
        this.post(data).then((response) => {
          id = response.data.id
          console.log(response.data)
          this.originalBlocks.push(response.data)
          this.blocks.push(this.clone(response.data))
          this.emitShowToast({
            title: this.$t('GENERAL_SUBMIT_SUCCESS_TITLE'),
            message: this.$t('GENERAL_SUBMIT_SUCCESS')
          })
        })
            .finally(() => {
              if (id) {
                this.parseBlock(this.blocks.find(block => block.id === id))
                this.scrollWireframe = true
                this.scrollToBlock(id)
              }
            })
      }
    },
    handleDeleteBlock (guid) {
      // TODO: add delete call
      console.log('delete block')
      let data = {
        url: `/delete/block/${guid}`,
        payload: {}
      }
      this.post(data).then((response) => {
        let guid = response.data
        let blockIndex = this.blocks.findIndex(block => block.guid === guid)
        let ogBlockIndex = this.originalBlocks.findIndex(block => block.guid === guid)
        this.blocks.splice(blockIndex, 1)
        this.originalBlocks.splice(ogBlockIndex, 1)
      })
    },
    handleSavePage () {
      this.blocks.forEach((block, index) => block.order = index + 1)
      let formData = new FormData()
      let inputs = []
      this.blocks.forEach(block => inputs = inputs.concat(block.children))

      let index = 1
      this.blocks.forEach(block => {
        if (block && block.children) {
          block.children.forEach(input => {
            let value = input.attributes ? input.attributes.value : null
            let field = {
              parentBlock: block.guid,
              value: value,
              orderBlock: block.order
            }
            // add index with dash to guid because guids are reused per type -> not unique
            if (input && input.attributes && input.attributes.type == 'file' && value) {
              formData.append('file-' + index + '-' + input.guid, value)
              field.value = value ? value.name : null
            }
            formData.append(index + '-' + input.guid, JSON.stringify(field))
            index++
          })
        }
      })

      let data = {
        url: `save/page/${this.obj.attributes.pageGuid}`,
        payload: formData
      }
      const logFormData = (formData) => {
        const entries = formData.entries()
        const result = {}
        let next
        let pair
        while ((next = entries.next()) && next.done === false) {
          pair = next.value
          result[pair[0]] = pair[1]
          console.log(pair)
        }
        console.log(result)
      }
      logFormData(formData)
      this.post(data).then((response) => {
        console.log(response)
        this.emitShowToast({
          title: this.$t('GENERAL_SUBMIT_SUCCESS_TITLE'),
          message: this.$t('GENERAL_SUBMIT_SUCCESS')
        })
        // this.originalBlocks = response.data
        // this.cloneBlocks(response.data)

      })
    },
    getHtmlBlocks () {
      // get blocks present on the page
      this.fetch(`get/page/${this.obj.attributes.pageGuid}`).then((response) => {
        console.log(response.data)
        this.originalBlocks = response.data
        // clone html blocks without reference so we can keep original data to replace handlebar tags
        this.cloneBlocks(response.data)
      })
    },
    cloneBlocks (array) {
      // clone given array into this.blocks and parse content
      this.blocks = this.clone(array)
      this.blocks.forEach(block => {
        this.parseBlock(block)
      })
    },
    parseBlock (parent) {
      let ogParent = this.originalBlocks.find(block => block.id === parent.id)
      let string = ogParent.value
      if (parent.children.length) {
        parent.children.forEach(child => {
          let value = child.attributes.value
          if ((child.attributes.type).toLowerCase().trim() === 'file') {
            if (!value) {
              child.attributes.url = this.placeholder.file
            } else if (!value instanceof File) {
              console.log('this is not a file')
              value ? child.attributes.url = value : child.attributes.url = this.placeholder.file
            } else if (value) {
              if (!child.attributes.url) child.attributes.url = child.attributes.value
            }
            string = this.findReplaceString(string, child.attributes.name, child.attributes.url)
          } else {
            if (!value) {
              value = this.placeholder[child.attributes.type]
            }
            string = this.findReplaceString(string, child.attributes.name, value)
          }
        })
      }
      this.$set(parent, 'value', string)
    },
    checkExpansion () {
      if (this.smallScreen) {
        if (!this.editorExpanded && !this.wireframeExpanded) {
          this.wireframeExpanded = true
        }
      } else {
        this.wireframeExpanded = false
        this.editorExpanded = false
      }
    },
    emitShowToast (data) {
      eventBus.$emit('show-toast', data)
    },
  },
  watch: {
    smallScreen () {
      this.checkExpansion()
    }
  },
  created () {
    this.getHtmlBlocks()
    this.getAddBlocks()
  },
  mounted () {
    eventBus.$on('inputEmit' + this.obj.key, this.updateInput)
    eventBus.$on('inputFocus' + this.obj.key, this.setActiveInput)
    if (this.smallScreen) this.checkExpansion()
  },
  beforeDestroy () {
    eventBus.$off('inputEmit' + this.obj.key, this.updateInput)
    eventBus.$off('inputFocus' + this.obj.key, this.setActiveInput)
  }
}
</script>

<style scoped lang="scss">
.editor-container {
  position: relative;
  height: calc(calc(100 * var(--vh-unit)) - 130px);
  display: flex;
  flex-flow: column nowrap;

  @include media-breakpoint-up(lg) {
    height: calc(calc(100 * var(--vh-unit)) - 220px);
  }
}

.add-block {
  padding: 0 15px;

  [class^="col"] {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
  }

  hr {
    margin-top: 1rem;
    margin-left: -15px;
    margin-right: -15px;
  }

  .button-wrapper {
    padding-bottom: 0;
    margin-left: 0.5em;
    height: 100%;
  }

  .custom-select {
    width: auto;
    max-width: 100%;
  }

  .save-button {
    margin-left: auto;
  }
}

.editor {
  position: relative;
  overflow-x: hidden;
  height: 100%;
  text-align: left;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: $border-radius;
  }

  .container {
    max-width: 1200px;
  }

  [class*="wrapper"] {
    min-height: 100%;
    height: 100%;
    flex-grow: 1;
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-flow: column wrap;
    //background: $white;

    @include media-breakpoint-up(xl) {
      width: 50%;
      transition: flex $speed $easing, width $speed $easing, transform $speed $easing;
    }

    &.expanded {
      width: 100%;
    }

    &.collapsed {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  .wireframe-wrapper {
    width: calc(50% - 15px);
    left: 0;

    .container {
      max-width: 1200px;
      width: 100%;
      padding: 0;
    }

    .list-columns {
      padding: 0;
    }

    @include media-breakpoint-up(xl) {
      &.collapsed {
        transform: translateX(-101%);
      }

      &.preview {
        width: calc(20% - 15px);

        .container {
          transform: scale(0.78) translateY(-14%);
          font-size: 4px;
        }

        .content-block {
          padding: 3px 0;
        }
      }
    }
  }

  .editor-wrapper {
    left: 0;
    @include media-breakpoint-up(xl) {
      left: 50%;
      &.collapsed {
        transform: translateX(101%);
      }
      &.expanded {
        transform: translateX(-50%);
        width: 100%;
      }
      &.preview {
        width: 80%;
        transform: translateX(-37%);
      }
    }
  }
}

.editor-row {
  padding-bottom: 1rem;
  scroll-margin-top: 40px;
  //display: flex;
  //flex-flow: row wrap;
  //justify-content: space-between;


  &-title {
    border-bottom: 2px solid $secondary-color;
    margin-bottom: 0.5rem;
    //background: $secondary-color;
    //padding: 5px 10px;
  }

  &.columns {
    //columns: 2;
    //.editor-block {
    //  width: 49%;
    //  display: inline-block;
    //
    //  &.text-editor {
    //    width: 100%;
    //  }
    //}
  }
}

.editor-block {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  label {
    font-weight: bold;
  }
}

.content-block {
  position: relative;
  max-width: calc(100% - 15px);
  word-break: break-word;
  border: 1px solid transparent;
  padding: 10px 0;
  transition: border $speed $easing;
  cursor: move;

  &-tools {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;

    [class*="-icon"] {
      background: $ultra-light-grey;
      width: 30px;
      height: 30px;
      display: grid;
      place-items: center;
      cursor: pointer;
    }
  }

  &.ghost {
    background-color: $ultra-light-grey;
  }

  &:hover, &:focus {
    border-color: $medium-light-grey;

    .content-block-tools {
      display: inline-flex;
    }
  }

  &.active {
    border-color: $component-active-bg;
  }
}

.icon-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding-right: 32px;
}

.expand-icon {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.2rem;
  z-index: 100;
  background: $ultra-light-grey;
}

form {
  max-width: calc(100% - 15px);
  max-height: 100%;
}

</style>