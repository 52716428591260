<template>
  <div class="lh-detail-page">
    <lh-sidebar
        v-if="sidebar && sidebar.key"
        :obj="sidebar"
    ></lh-sidebar>
    <div v-else class="sidebar">
      <button class="btn-primary" @click="$router.go(-1)">Go back</button>
    </div>
    <div class="lh-detail-page-content">
      <component
          v-for="child in detailPage.children"
          :is="child.type"
          :key="child.key"
          :obj="child"
          :detailpage="true"
          @closePopup="handleGoBack"
      ></component>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'DetailPage',
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        inputValue: []
      }
    },
    computed: {
      ...mapState({
        sidebar: 'sidebarJSON',
        loading: 'loadingJSON',
        page: 'pageLoaded',
        detailPage: 'detailPage'
      })
    },
    methods: {
      handleChange (val) {
        console.log(val)
      },
      handleGoBack (val) {
        this.$router.go(-1)
      }
    },
    created () {
    },
    watch: {
    }
  }
</script>

<style lang="scss" scoped>
  .lh-detail-page {
    display: flex;
    flex-flow: row nowrap;
    max-width: 100%;

    .lh-detail-page-content {
      width: 100%;
    }
  }
</style>
