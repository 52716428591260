<template>
  <b-row align-h="center" class="browse-footer" :class="checkClasses" :style="checkStyles">
    <b-col cols="12">
      <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: 'LhBrowseFooter',
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {}
    },
    computed: {},
    methods: {},
    watch: {}
  }
</script>

<style lang="scss">
  .browse-footer {
    margin-top: 5px;
    div[class^=col] {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column wrap;
    }

    ul {
      margin-bottom: 0;
    }

    @media (min-width: 1100px) {
      .pagesize-select {
        position: absolute;
        right: 15px;
        top: 5px;
      }
    }
  }

</style>
