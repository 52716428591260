<template>
  <div :class="[checkClasses, {collapsed: collapsed, translations: translationEditMode}]" class="button-group" :style="checkStyles">
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'ButtonGroup',
    props: {
      obj: {
        type: Object
      }
    },
    computed: {
      ...mapState({
        collapsed: 'sidebarCollapse',
        translationEditMode: 'translationEditMode'
      })
    }
  }
</script>

<style scoped lang="scss">
  .button-group {
    display: flex;
    flex-wrap: wrap;

    .LhCol {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.translations {
      max-width: 160px;
      align-self: flex-start;
      margin-left: 20px;

      &.collapsed {
        margin-left: -5px;
      }
    }
  }
</style>
