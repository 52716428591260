<template>
  <div class="register" :style="checkStyles">
    <div>
      <h1>{{obj.attributes.title}} - {{obj.attributes.projectName}}</h1>
      <form @submit.prevent="validateBeforeSubmit">
        <div>
          <label class="label">Username</label>
          <input
              type="text"
              name="name"
              id="name"
              v-validate="'required|alpha'"
              :class="{'input': true, 'is-danger': errors.has('name') }"
              v-model="name"
              placeholder="name"
          >
          <template v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</template>
        </div>

        <div>
          <label class="label">Password</label>
          <input
              type="password"
              name="password"
              id="password"
              v-validate="'required'"
              :class="{'input': true, 'is-danger': errors.has('password') }"
              v-model="password"
              placeholder="password"
              ref="password"
          >
          <template
              v-show="errors.has('password')"
              class="help is-danger"
          >{{ errors.first('password') }}
          </template>
        </div>

        <div>
          <label class="label">Confirm password</label>
          <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              v-validate="'required|confirmed:password'"
              :class="{'input': true, 'is-danger': errors.has('confirmPassword') }"
              v-model="confirmPassword"
              placeholder="confirm password"
          >
          <template
              v-show="errors.has('confirmPassword')"
              class="help is-danger"
          >{{ errors.first('confirmPassword') }}
          </template>
        </div>

        <div>
          <button type="submit" name='user-form' class="button c-register-form__register">Create Account</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>


  export default {
    props: {
      obj: {
        type: Object
      }
    },

    data () {
      return {
        validation: 'required',
        name: 'Someone',
        email: '',
        password: '123456',
        confirmPassword: '123456',
        age: null,
        terms: false,
      }
    },
    methods: {
      register () {
        const formData = {
          formName: 'user-form',
          username: this.name,
          password: this.password,
          confirmPassword: this.confirmPassword
        }
        this.$store.dispatch('register', formData).then(response => {
          // console.log(response);
          // this.$router.push({ name: "home" });
        })
      },
      lostFocus (input) {
        input.$touch()
      },
      validateBeforeSubmit () {
        console.log(this.$validator)

        this.$validator.validateAll().then(result => {
          if (result) {
            this.register()
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .input.invalid input {
    border: 1px solid red;
  }

  input {
    margin: 10px;
    width: 200px;
    height: 20px;
    line-height: 20px;
  }

  input[type="checkbox"] {
    margin: 0;
    width: 13px;
    height: 13px;
  }
</style>
