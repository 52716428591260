<template>
  <span class="lh-span" :class="checkClasses">
    <span :style="checkStyles"
          v-tooltip="checkTooltip"
    >{{$t(obj.attributes.content)}}</span>
    <component v-for="item in obj.children" :is="item.type" :key="item.key" :obj="item"></component>
  </span>
</template>

<script>
  export default {
    name: 'LhSpan',
    components: {},
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>
