export default {
  methods: {
    confirm (confirmation) {
      const h = this.$createElement
      let text = {
        content: confirmation.content ? this.$t(confirmation.content) : 'Are you sure',
        title: confirmation.title ? this.$t(confirmation.title) : 'Confirmation',
        cancel: confirmation.cancel ? this.$t(confirmation.cancel) : 'Cancel',
        confirm: confirmation.confirm ? this.$t(confirmation.confirm) : 'Ok'
      }

      if (this.obj && this.obj.attributes &&
        (this.obj.attributes.showSelectedRowsInConfirm ||
          ((this.obj.attributes.multiSelectOnly || this.obj.attributes.selectionOnly || this.obj.attributes.singleSelectionOnly ||
            this.obj.attributes.includeSelectedRows) && this.obj.attributes.type == 'delete' ))) {
        let length = this.$store.getters.getLengthOfSelectedRows(this.obj.attributes.target)
        if (length) {
          console.log(text.content, length)
          text.content = text.content.replace('{selectedrows}', '' + length)
        }
      }
      const messageVNode = [
        h('p', { class: ['text-center'] , style:{margin: 0}}, [text.content]),
      ]
      return this.$bvModal.msgBoxConfirm([messageVNode], {
        title: text.title,
        okTitle: text.confirm,
        cancelTitle: text.cancel,
        okVariant: 'confirm button secondary confirm-modal',
        cancelVariant: 'cancel button confirm-modal',
        footerClass: ['p-2', {okDisabled: confirmation.okDisabled}, {cancelDisabled: confirmation.cancelDisabled}],
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        return value
      })
    }
  }
}