import { isEmpty } from 'lodash'

export default {
  //add class to component from parent
  props: ['componentClass'],
  methods: {
    checkEmpty (obj) {
      return obj ? obj : null
    },
    notEmpty (obj) {
      return !isEmpty(obj)
    }
  },
  computed: {
    checkClasses () {
      let classes = ''

      if (this?.obj?.attributes?.class) {
        classes = this.obj.attributes.class
      }
      //class from prop
      if (this.componentClass) {
        classes += ' ' + this.componentClass
      }

      if (this.isMobile){
        classes += ' inside-mobile'
      }

      if (this?.obj?.attributes?.target && (this?.obj?.attributes?.selectionOnly || this?.obj?.attributes?.singleSelectOnly || this?.obj?.attributes?.multiSelectOnly)) {
        let browseSetting = this.getBrowseSettingByKey(this.obj.attributes.target)

        if (browseSetting) {
          //hide unless at least one row is selected
          if (this?.obj?.attributes?.selectionOnly) {
            console.log('selectionOnly')
            classes += ' hide-component'
            if (browseSetting.selectionLength >= 1) {
              classes = classes.replace(' hide-component','')
            }
          }

          //hide unless only one row is selected
          if (this?.obj?.attributes?.singleSelectOnly) {
            console.log('singleSelectOnly')
            classes += ' hide-component'
            if (browseSetting.selectionLength === 1) {
              classes = classes.replace(' hide-component','')
            }
          }

          //hide unless at least two rows are selected
          if (this?.obj?.attributes?.multiSelectOnly) {
            console.log('multiSelectOnly')
            classes += ' hide-component'
            if (browseSetting.selectionLength > 1) {
              classes = classes.replace(' hide-component','')
            }
          }
        }
      }

      return classes
    },
    checkStyles () {
      let obj = this.obj
      let styles = {}
      let url = ''
      if (obj) {
        if (obj.styles) {
          //this.changeCursor();
          styles = obj.styles
          if (styles.background) {
            let name = styles.background
            try {
              url = `url(${require('@/assets/img/' + name)})`
              delete styles.background
              styles.backgroundImage = url
              return styles
            } catch (error) {
              console.log('Image not found: ' + error)
            }
          }
          return styles
        }
      }
      return null
    },
    checkDisabled () {
      let disabled = false

      if ((this.obj?.attributes?.disabled === true || this.obj?.attributes?.disabled === 'true'
        || this.obj?.attributes?.tableSelect)) {
        disabled = true
      }

      //disable if no rows are selected
      if (this.obj?.attributes?.enableOnSelectRow) {
        disabled = true
        let browseSetting = this.getBrowseSettingByKey(this.obj.attributes.target)
        if (browseSetting && browseSetting.selectedRows && browseSetting.selectedRows.length > 0) {
          disabled = false
        }
      }

      return disabled
    },
    checkTooltip () {
      let tooltip = ""
      if (this.obj?.attributes?.tooltip) {
        tooltip = this.$t(this.obj.attributes.tooltip)
      }
      return tooltip ? tooltip : null
    }
  }
}
