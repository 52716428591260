<template>
  <div class="sitemap">
    <draggable
        class="list-columns"
        tag="ul"
        v-model="pages"
        v-bind="dragOptions"
        @start="drag = true"
        @add="onUpdate"
        @update="onUpdate"
    >
      <lh-sitemap-item v-for="item in pages"
                       :obj="item"
                       @itemAdd="onUpdate"
                       @itemUpdate="onUpdate"
      >
      </lh-sitemap-item>
    </draggable>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import LhSitemapItem from './LhSitemapItem'
  import { mapState, mapMutations, mapActions } from 'vuex'
  import axios from 'axios'
  import { eventBus } from '../services/event-bus'

  export default {
    name: 'LhSitemap',
    props: {
      obj: {
        type: Object,
        required: true
      }
    },
    components: {
      draggable,
      LhSitemapItem
    },
    data () {
      return {
        pages: []
      }
    },
    computed: {
      dragOptions () {
        return {
          drag: false,
          animation: 200,
          group: 'description',
          disabled: false,
          ghostClass: 'ghost'
        }
      }
    },
    methods: {
      ...mapActions({
        fetch: 'fetchJSONLocal'
      }),
      ...mapMutations({
        setLoading: 'setLoading',
        stopLoading: 'stopLoading'
      }),
      onUpdate (event) {
        this.setLoading()
        console.log('onAdd', event)
        let payload = {}
        payload.guid = event.item.id
        payload.parentGuid = event.to.parentNode.id ? event.to.parentNode.id : null
        payload.index = event.newIndex + 1

        let api = this.$clientConfig.VUE_APP_API_URL + `/sitemap/${this.obj.attributes.guid}`

        axios({
          method: 'post',
          url: api,
          withCredentials: true,
          crossdomain: true,
          data: payload
        })
          .then(response => {
            console.log(response.data)
            this.pages = response.data
          })
          .catch(error => {
            console.log(error)
            this.pages = error.response.data
          })
          .finally(() => {
            this.stopLoading()
          })
      },
      handleTabChange (payload) {
        if (payload.child === this.obj.key) {
          this.getPages()
          console.log('tab focused, getting pages!')
        }
      },
      getPages () {
        this.fetch(`/sitemap/${this.obj.attributes.guid}`).then((response) => this.pages = response.data)
      }
    },
    watch: {},
    created () {
      this.getPages()
    },
    mounted () {
      eventBus.$on('changedTab', this.handleTabChange)
    },
    beforeDestroy () {
      eventBus.$off('changedTab', this.handleTabChange)
    }
  }
</script>

<style lang="scss">
  .sitemap {
    ul {
      padding-left: 0;

      @include media-breakpoint-down(md) {
        li:not(:last-child) {
          &::before {
            content: "";
            position: absolute;
            top: 44px;
            left: 0;
            border-left: 2px dotted $secondary-color;
            width: 1px;
            height: calc(100% - 24px);
          }
        }
      }
    }

    & > ul {
      & > li {
        .list-column-item-content {
          /*margin-bottom: 10px;*/
        }
      }
    }

    @include media-breakpoint-up(lg) {
      ul {
        list-style: none;
        padding-left: 32px;

        .list-column-item {
          padding: 50px 0 0 35px;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 5px;
            left: 15px;
            border-left: 2px solid $secondary-color;
            width: 1px;
            height: 100%;
          }

          &::after {
            content: "";
            position: absolute;
            border-top: 2px solid $secondary-color;
            top: 62px;
            left: 15px;
            width: 30px;
          }

          &:last-child::before {
            top: 5px;
            height: 57px;
            left: 15px;
          }
        }
      }
      // First Level Elements
      & > ul {
        & > .list-column-item {
          &:first-child {
            padding-top: 15px;
            &> .list-column-item-content {
              &::before {
                top: 31px;
              }
            }
          }
          &:nth-child(1) {
            &::before {
              top: 31px;
              height: calc(100% + 31px);
            }
          }
          .list-column-item-content {
            &::before {
              content: "";
              position: absolute;
              border-top: 2px solid $secondary-color;
              top: 62px;
              left: 36px;
              width: 15px;
            }
          }

          &::after,
          &:last-child::before {
            content: unset;
          }

          &::before {
            top: 62px;
            left: 36px;
            height: 100%;
          }
        }
      }
    }
  }
</style>