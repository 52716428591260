<template>
  <ValidationProvider :vid="obj.attributes.name" :name="fieldName"
                      :rules="checkRules ? rulesObj :
                      typeof obj.attributes.validation == 'string' ? obj.attributes.validation : ''">

  <div slot-scope="{ valid, errors }" v-bind="$attrs" class="lh-select" :style="checkStyles">
      <b-form-group :label="setLabel" :label-for="obj.attributes.id"
                    :label-cols-md="obj.attributes.labelCols">
        <b-form-select
            :id="obj.attributes.id"
            v-model="innerValue"
            :options="updatedContent"
            @change="handleChangeLocal(innerValue)"
            :placeholder="obj.attributes.placeholder"
            :disabled="checkDisabled"
            :class="errors[0] ? 'help' : 'correct'"
            v-on="allEvents"
        >
        </b-form-select>
      </b-form-group>
      <div class="error-message" >{{ errors[0] }}</div>
      <component v-for="item in obj.children" :is="item.type" :key="item.key" :obj="item"></component>
    </div>
  </ValidationProvider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate'
  import { eventBus } from '../services/event-bus'
  import mixinFunctions from '@/mixin/mixinFunctions'
  import confirm from '@/mixin/confirm'

  export default {
    name: 'LhSelect',
    mixins: [mixinFunctions, confirm],
    components: {
      ValidationProvider
    },
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        innerValue: '',
        rulesObj: {}
      }
    },
    computed: {
      valueListener () {
        return this.obj.attributes.placeholder
      },
      updatedContent () {
        let content = []

        if (Array.isArray(this.obj.attributes.content) && this.obj.attributes.content[0]) {
          let options = []
          for (let option of this.obj.attributes.content){
            let newOption = {
              text: this.$t(option.text),
              value: option.value,
              disabled: !option.value && this.rulesObj.required ? true : false
            }
            options.push(newOption)
          }
          content = options
        } else if (this.obj.attributes.content) {
          content = this.obj.attributes.content
        }
        return content
      },
      fieldName() {
        return this.obj.attributes.name ? this.obj.attributes.name : this.obj.attributes.placeholder
      },
      checkRules() {
        let hasRules = false
        for(var prop in this.rulesObj) {
          if (this.rulesObj.hasOwnProperty(prop)) {
            hasRules = true
          }
        }
        return hasRules
      },
      setLabel () {
        if (this.obj.attributes.label) {
          return this.rulesObj &&  this.rulesObj.required ? this.$t(this.obj.attributes.label) + ' *' : this.$t(this.obj.attributes.label)
        }
      },
      parentFormKey () {
        return this.$store.getters.getInsideOfFormKey(this.obj.key)
      }
    },
    methods: {
      handleChange (val) {
        let payload = {
          key: this.parentFormKey,
          inputs: {
            [this.obj.attributes.name]: this.innerValue
          }
        }
        this.$store.commit('setFormFilledDataByKey', payload)
        this.emitToForm('handleChange', this.innerValue)
        if (this.allEvents) {
          for (let actionEvent in this.allEvents) {
            console.log(actionEvent)
            if (typeof actionEvent === 'function' && actionEvent == event) {
              console.log('execute action for input')
            }
          }
        }
        //handle submit for amount pages dropdown
        if(this.obj.attributes.target){
          let payload ={}
          let type = ''
          if(this.obj.attributes.typeInfo){
            type = this.obj.attributes.typeInfo
          }
          payload[type] = val

          this.$store.dispatch('setBrowseSettingByKey', {
            key: this.obj.attributes.target,
            payload: payload
          })
          this.$store
            .dispatch('sortAndFilter', {
              target: this.obj.attributes.target,
              url: this.obj.attributes.url,
              browseKey: this.obj.attributes.target,
              pressedBtn: this.obj
            })
            .then(result => {
              // this.$store.commit("setMainJSON", result);
              // this.replaceTarget(result)
            })
        }
      },
      handleChangeLocal (val) {
        // this.$emit('handleChangeSelect', this.obj.attributes.content[val])
        this.handleChange(val)
        if (this.obj.attributes.type === 'submit') {
          eventBus.$emit('submitForm' + this.parentFormKey, { hideToast: true }) // true -> hide toast after submit
        }
      },
      setCustomMessages () {
        let validations = this.obj.attributes.validation

        let currentFieldName = this.$t(this.obj.attributes.label)
        let fieldName = this.fieldName
        let dictionary = {
          custom: {
            [fieldName]:{}
          }
        }
        if (validations && Array.isArray(validations)){
          for (let validation of validations) {
            let translation = validation.translation_key_custom
              ? this.$t(validation.translation_key_custom)
              : this.$t(validation.translation_key)

            function ruleMsg(currentFieldName, {translation}) {
              if (translation.includes('{field}')) {
                return translation.replace('{field}', currentFieldName)
              } else {
                return translation
              }
            }

            if (validation.type.includes(':')) {
              let confirm = validation.type.split(':')
              this.$set(this.rulesObj, confirm[0], confirm[1])
              dictionary.custom[fieldName][confirm[0]] = () => ruleMsg(currentFieldName, {translation})
            } else {
              this.$set(this.rulesObj, validation.type, true)
              dictionary.custom[fieldName][validation.type] = () => ruleMsg(currentFieldName, {translation})
            }
          }
          this.$validator.localize({
            [this.$i18n.locale]: dictionary
          })
        }
      },
      emitToForm (event) {
        let name = this.obj.attributes.name
        let value = this.innerValue
        eventBus.$emit('inputEmit' + this.parentFormKey, { event, value, name })
      }
    },
    watch: {
      // Handles external model changes.
      'obj.attributes.value' (newVal) {
        this.innerValue = newVal
      },
      valueListener (val) {
        this.innerValue = val
      }
    },
    mounted () {
      this.setCustomMessages()
      let payload = {
        key: this.parentFormKey,
        inputs: {
          [this.obj.attributes.name]: this.innerValue
        }
      }
      this.$store.commit('setFormFilledDataByKey', payload)

      if(this.obj.attributes.target){
        let payload ={}
        let type = ''
        if(this.obj.attributes.typeInfo){
          //typeInfo: uses value of this select to add a property to browsesetting.
          //example-> typeInfo = 'browseSize' in this case for the amount of rows in a browse
          type = this.obj.attributes.typeInfo
        }
        payload[type] = this.inputValue

        this.$store.commit('setBrowseSettingByKey', {
          key: this.obj.attributes.target,
          payload: payload
        })
      }
    },
    created () {
      if (!this.obj.attributes.value) {
        this.innerValue = this.obj.attributes.placeholder
      }
    }
  }
</script>

<style lang="scss">
  .lh-select {
    margin-bottom: 1rem;

    .form-group {
      margin-bottom: 0;
    }

    .form-group-wrapper & {
      margin-bottom: 0;
    }
  }

  .help {
    border: 1px solid $red;
  }
</style>
