<template>
  <img
      class="image"
      :style="checkStyles"
      v-on="allEvents"
      :src="parseSrc"
      :class="checkClasses"
  />
</template>

<script>
  import { mapState } from 'vuex'
  import mixinFunctions from '@/mixin/mixinFunctions'
  import confirm from '@/mixin/confirm'

  export default {
    name: 'Lh-Image',
    mixins: [mixinFunctions, confirm],
    props: {
      obj: {
        type: Object
      }
    },
    methods: {},
    computed: {
      ...mapState({
        collapsed: 'sidebarCollapse',
        isMobile: 'isMobile'
      }),
      parseSrc: function () {
        if (this.collapsed && this.obj.attributes['collapse-src'] && !this.isMobile) {
          return this.obj.attributes['collapse-src']
        } else {
          return this.obj.attributes.src
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  img.image {
    display: block;
    padding-bottom: 1rem;
  }
</style>
