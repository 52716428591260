import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import BootstrapVue from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { eventBus } from './services/event-bus'
import VTooltip from 'v-tooltip'
import i18n from './translations/translation'
import Api from './http/api.js'
import mixinAttributes from './mixin/mixinAttributes'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import VeeValidate from 'vee-validate'
import nlTrans from 'vee-validate/dist/locale/nl'
import enTrans from 'vee-validate/dist/locale/en'

// FontAwesome Icons
// duotone
import {
  faSpinner
} from '@fortawesome/pro-duotone-svg-icons'
// light
import {
  faCoffee,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faAngleUp,
  faAngleDown,
  faTimes,
  faPlus,
  faPlusLarge,
  faMinus,
  faClipboardList,
  faExpandAlt,
  faCompressAlt,
} from '@fortawesome/pro-light-svg-icons'
// regular
import {
  faCommentAlt,
  faCloudUploadAlt,
  faClipboard,
  faCartPlus
} from '@fortawesome/pro-regular-svg-icons'
//solid
import {
  faHome,
  faFileAlt,
  faChartPie,
  faChartBar,
  faChartLine,
  faCalendarAlt,
  faUsers,
  faBell,
  faCog,
  faCalendar,
  faSort,
  faSortUp,
  faSortDown,
  faCircle,
  faTimesCircle,
  faXmark,
  faXmarkLarge,
  faPlusCircle,
  faFileDownload,
  faTrashAlt,
  faPencilAlt,
  faSearch,
  faSearchPlus,
  faSearchMinus,
  faEllipsisV,
  faEllipsisH,
  faArrows,
  faLongArrowUp,
  faSave,
  faFileUpload,
  faShareSquare,
  faBars,
  faUser,
  faUserCog,
  faNotesMedical,
  faBriefcaseMedical,
  faSignOutAlt,
  faCheck,
  faFolder,
  faFolderOpen,
  faEnvelope,
  faPaperPlane,
  faInboxOut,
  faBan,
  faInfo,
  faInfoCircle,
  faFileSearch,
  faTasks,
  faLink,
  faPaperclip,
  faEye,
  faEyeSlash,
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faCode,
  faParagraph,
  faListUl,
  faListOl,
  faQuoteRight,
  faHorizontalRule,
  faUndo,
  faRedo,
  faH1,
  faH2,
  faH3,
  faH4,
  faArrowsH,
  faSync,
  faArrowToLeft,
  faArrowToRight,
  faColumns,
  faLineColumns,
  faCommentAltLines,
  faPhoneAlt,
  faPhone,
  faShoppingCart,
  faExclamationCircle,
  faExclamation,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCaretLeft,
  faArrowCircleLeft,
  faGlobe,
  faShoppingBasket,
  faUnlink,
  faHistory,
  faTrophy,
  faPercent,
  faUpload,
  faCloudUpload,
  faTruckLoading,
  faTruckContainer,
  faRoad,
  faFileInvoiceDollar,
  faCogs,
  faPaste,
  faChartSimple,
} from '@fortawesome/pro-solid-svg-icons'

import { faClipboardList as fasClipboardList } from '@fortawesome/pro-solid-svg-icons'
import { faFileAlt as farFileAlt } from '@fortawesome/pro-regular-svg-icons'

library.add(
  faCoffee,
  faHome,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faFileAlt,
  faChartPie,
  faCalendarAlt,
  faUsers,
  faBell,
  faCog,
  faCalendar,
  faSpinner,
  faCog,
  faCalendar,
  faSort,
  faSortUp,
  faSortDown,
  faCircle,
  faTimesCircle,
  faXmark,
  faXmarkLarge,
  faAngleUp,
  faAngleDown,
  faPlusCircle,
  faFileDownload,
  faTrashAlt,
  faPencilAlt,
  faSearch,
  faTimes,
  faPlus,
  faPlusLarge,
  faMinus,
  faEllipsisV,
  faEllipsisH,
  faArrows,
  faSave,
  faFileUpload,
  faShareSquare,
  faBars,
  faLongArrowUp,
  faUser,
  faUserCog,
  faNotesMedical,
  faBriefcaseMedical,
  faSignOutAlt,
  faChartBar,
  faChartLine,
  faCheck,
  faFolder,
  faFolderOpen,
  faClipboardList,
  faEnvelope,
  faPaperPlane,
  faInboxOut,
  faBan,
  faInfo,
  faInfoCircle,
  faFileSearch,
  faTasks,
  faLink,
  faPaperclip,
  faEye,
  faEyeSlash,
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faCode,
  faParagraph,
  faListUl,
  faListOl,
  faQuoteRight,
  faHorizontalRule,
  faUndo,
  faRedo,
  faH1,
  faH2,
  faH3,
  faH4,
  faArrowsH,
  faSync,
  faArrowToLeft,
  faArrowToRight,
  faColumns,
  faLineColumns,
  faExpandAlt,
  faCompressAlt,
  faCommentAlt,
  faCommentAltLines,
  faPhoneAlt,
  faCompressAlt,
  faPhone,
  faShoppingCart,
  faExclamationCircle,
  faExclamation,
  faCompressAlt,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCaretLeft,
  faArrowCircleLeft,
  faShoppingBasket,
  faUnlink,
  faSearchPlus,
  faSearchMinus,
  faHistory,
  faTrophy,
  faPercent,
  faGlobe,
  fasClipboardList,
  faUpload,
  faCloudUploadAlt,
  faCloudUpload,
  faTruckLoading,
  faTruckContainer,
  faRoad,
  faFileInvoiceDollar,
  faCogs,
  faClipboard,
  farFileAlt,
  faCartPlus,
  faPaste,
  faChartSimple,
)

let folders = [
  require.context('./components', true, /\.(js|vue)$/i),
  require.context('./views', true, /\.(js|vue)$/i),
]

try {
  folders.push(require.context('../../components', true, /\.(js|vue)$/i))
} catch(e) {
  console.log(e + ': components folder not found')
}

for (let reqModule of folders) {
  reqModule.keys().map(key => {
    const name = upperFirst(
        camelCase(
            key
                .split('/')
                .pop()
                .replace(/\.\w+$/, '')
        )
    )
    return Vue.component(name, reqModule(key).default || reqModule(key))
  })
}

Vue.config.productionTip = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.use(VTooltip)
Vue.use(BootstrapVue)
Vue.use(Api)
Vue.use(require('vue-moment'))
Vue.use(VeeValidate, {
  classes: true,
  classNames: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  },
  aria: true,
  events: 'blur',
  i18n,
  dictionary: {
    nl: nlTrans,
    en: enTrans
  }
})

Vue.mixin(mixinAttributes)

router.beforeEach((to, from, next) => {
  if (store.state.sideFormContent && store.state.sideFormContent.type) {
    store.commit('removeSideform')
  }

  let redirectAction = store.state.redirectCheck
  if (!redirectAction && store.state.popups && store.state.popups.length > 0) {
    if (store.state.popups[store.state.popups.length - 1]) {
      eventBus.$emit('close-popup' + store.state.popups[store.state.popups.length - 1].key)
      next(false)
    } else {
      next()
    }
  } else {
    next()
  }
  store.commit('setRedirectCheck', false)
})

import './filters'
import './http/interceptor'

//Config
fetch('/env.json')
.then(res => res.json())
.then(out => initApp(out))

function initApp(env){
  Vue.prototype.$clientConfig = env
  new Vue({
    router,
    store,
    i18n,
    render: function (h) {
      return h(App)
    }
  }).$mount('#app')
}