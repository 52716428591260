<template>
  <div
      class="user-button"
      v-on="allEvents"
      :value="checkEmpty(obj.attributes.value)"
      :style="checkEmpty(obj.styles)"
  >
    <img :src="obj.attributes.src" alt="user"/>
  </div>
</template>

<script>
  import mixinFunctions from '@/mixin/mixinFunctions'
  import confirm from '@/mixin/confirm'

  export default {
    name: 'UserButton',
    mixins: [mixinFunctions, confirm],
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>

<style scoped lang="scss">
  .user-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 48px;
    min-width: 40px;
    cursor: pointer;

    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }
  }
</style>
