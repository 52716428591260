<template>
  <div class="dashboard">
    <p v-for="item in obj.attributes.content" :key="item">{{item}}</p>
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"/>
  </div>
</template>

<script>
  export default {
    name: 'Dashboard',
    components: {},
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .dashboard {
    display: block;
    text-align: center;
    margin-bottom: 50px;

    .title {
      margin-left: 20px;
      font-size: 2.5em;
      line-height: 2.5em;
    }

    .navigation {
      margin-left: 20px;
    }

    .logo {
      grid-row: span 2;

      a img {
        height: 250px;
      }
    }
  }

  .dashboardTest {
    display: grid;
    grid-template-columns: 500px 1fr;
    text-align: left;
    margin-bottom: 50px;

    .title {
      margin-left: 20px;
      font-size: 2.5em;
      line-height: 2.5em;
    }

    .navigation {
      margin-left: 20px;
    }

    .logo {
      grid-row: span 2;

      a img {
        height: 250px;
      }
    }
  }
</style>
