<template>
  <div class="cards" :class="checkClasses" :style="checkStyles">
    <ul>
      <li v-for="(row, index) in obj.children" v-if="index !== 0" :key="row.key">
<!--      TODO  v-if="col.attributes.showInCards"-->
          <component
              v-for="col in getCols(row)"

              :is="col.type"
              :obj="col"
              :key="col.key"
          ></component>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'LhCards',
    components: {},
    props: {
      obj: {
        type: Object
      }
    },
    computed: {
      notPassOrGuid () {
        return
      }
    },
    methods: {
      getCols (obj) {
        let dropdowns = obj.children.filter(cell =>  cell.type === 'LhGridDropdown')
        // first child of LhGridDropdown always LhRow with LhCols
        return dropdowns[0].children
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .cards ul {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    padding: 0 1em;
    list-style: none;
  }

  .cards ul li {
    margin: 1em;
    align-self: flex-start;
    background: $ultra-light-grey;
    text-align: left;
    padding: 1em;
    width: 30%;
    word-break: break-all;

  }
</style>
