<template>
  <span v-if="!isMobile" :class="[{'cards-view': cardsView }]">
      <div>
        <div v-if="headersMobile.length > 0"
             class="grid-card-item"
        >
          <div class="row-container-mobile">
            <div class="grid-card-item-content" :class="['gridrow']">

              <div class="card-title">
                <template v-for="cell in cellsMobile" v-if="cell.attributes.titleMobile">
                  <div v-if="cell.attributes.content">{{$t(cell.attributes.content)}}</div>
                </template>
              </div>


              <div class="card-content">
                <p v-for="order in orderedCellsMobile" :style="{order: order}">
                  <span v-for="cell in cellsMobile"
                        v-if="cell.attributes.orderMobile === order && cell.attributes.labelMobile === false">{{$t(cell.attributes.content)}} </span>
                </p>
                <p v-for="cell in cellsMobile"
                   v-if="!cell.attributes.titleMobile && cell.attributes.labelMobile !== false">
                  {{$t(headerContent(cell.attributes.target))}}: {{$t(cell.attributes.content)}}
                </p>
              </div>

              <b-row class="row buttons-wrapper-mobile no-gutters">
                <b-col class="col-auto" v-for="button in buttonItemsMobile.children">
                  <component
                      :is="button.type"
                      :obj="button"
                      :key="button.key"
                      :componentClass="'white-icon-buttons'"
                  ></component>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
  </span>

  <!--  MOBILE ROWS-->
  <tbody v-else>
  <tr :class="[{'isMobile': isMobile}, 'cards-view-mobile']">
    <td style="display: block;">
      <div v-if="headersMobile.length > 0"
           class="grid-card-item"
      >
        <div class="grid-card-item-content" :class="['gridrow']"
             @click="$emit('rowClick', $event)"
             @contextmenu="disableContextMenu($event)">

          <div class="card-title">
            <template v-for="cell in cellsMobile" v-if="cell.attributes.titleMobile">
              <div v-if="cell.attributes.content">{{$t(cell.attributes.content)}}</div>
            </template>
          </div>

          <div class="card-content">
            <p v-for="order in orderedCellsMobile" :style="{order: order}">
                <span v-for="cell in cellsMobile"
                      v-if="cell.attributes.orderMobile === order && cell.attributes.labelMobile === false">{{$t(cell.attributes.content)}} </span>
            </p>
            <p v-for="cell in cellsMobile"
               v-if="!cell.attributes.titleMobile && cell.attributes.labelMobile !== false">
              {{$t(headerContent(cell.attributes.target))}}: {{$t(cell.attributes.content)}}
            </p>
          </div>

          <b-row class="row buttons-wrapper-mobile no-gutters">
            <b-col class="col-auto" v-for="button in buttonItemsMobile.children">
              <component
                  :is="button.type"
                  :obj="button"
                  :key="button.key"
                  :componentClass="'white-icon-buttons'"
              ></component>
            </b-col>
          </b-row>
        </div>
      </div>
    </td>
  </tr>
  </tbody>
</template>

<script>

  import { mapState } from 'vuex'

  export default {
    name: 'LhGridRowCards',
    components: {},
    props: {
      obj: {
        type: Object
      },
      index: {
        type: Number
      },
      browseKey: {
        type: String
      },
      cardsView: {
        type: Boolean
      }
    },
    data () {
      return {
        eventElements: [],
        localState: false,
        clicked: false
      }
    },
    computed: {
      ...mapState({
        isMobile: 'isMobile'
      }),
      headers () {
        return this.$store.getters.getBrowseSettingByKey(this.browseKey).headers
      },
      rearrangedRow () {
        let oldArr = this.obj.children
        let newArr = []
        for (const header of this.headers) {
          for (const child of oldArr) {
            if (header.key === child.attributes.target) {
              newArr.push(child)
            }
          }
        }
        return newArr
      },
      expandItems () {
        return this.obj.children.filter(child => child.type != 'LhGridCell' && child.type != 'lh-grid-cell')
      },
      headersMobile () {
        return this.headers.filter(header => header.showMobile)
      },
      cellsMobile () {
        let cells = []
        this.headersMobile.forEach(header => {
          let cell = this.obj.children.find(cell => cell.attributes.target == header.key)
          if (cell) cells.push(cell)
        })
        return cells
      },
      orderedCellsMobile () {
        let orders = []
        this.cellsMobile.find(cell => {
          if (cell.attributes.orderMobile) {
            let orderPresent = orders.find(order => order === cell.attributes.orderMobile)
            if (!orderPresent) orders.push(cell.attributes.orderMobile)
          }
        })
        return orders
      },
      showContentMobile () {
        return header => {
          return this.obj.children.find(cell => cell.attributes.target == header)
            ? this.obj.children.find(cell => cell.attributes.target == header).attributes.content : null
        }
      },
      buttonItemsMobile () {
        let buttonHeader = this.headers.find(head => head.type == 'button')
        return buttonHeader && buttonHeader.key ? this.obj.children.find(cell => cell.attributes.target == buttonHeader.key) : ''
      }
    },
    methods: {
      disableContextMenu (event) {
        if (this.isMobile) {
          event.preventDefault()
          event.stopPropagation() // not necessary in my case, could leave in case stopImmediateProp isn't available?
          event.stopImmediatePropagation()
          return false
        }
      },
      headerContent (key) {
        let header = this.headersMobile.find(item => item.key == key)
        return header ? header.name : ''
      }
    }
  }
</script>

<style lang="scss">
  .cards-view {
    border: 1px solid $light-grey;
    margin: 1rem 0 0 1rem;
    width: 450px;
    min-width: 400px;

    @media only screen and (max-width: 1800px) {
      width: 32%;
    }

    @media only screen and (max-width: 1200px) {
      width: 48%;
    }

    @media only screen and (max-width: 760px) {
      width: 100%;
      min-width: unset;
    }

    .buttons-wrapper-mobile {
      div[class^="col"] {
        &:not(:first-child) {
          margin-left: 0.5rem;
        }
      }

      &.buttons-wrapper-mobile.buttons-wrapper-mobile  {
        //overwrite row padding
        padding: 5px;
      }

      .icon-button.white-icon-buttons {
        font-size: 16px;
        padding: 8px;
        border-radius: 25px;
      }
    }
  }

  /*Mobile*/
  .cards-view-mobile {


    tr.isMobile {
      border-bottom: 15px solid $white;
    }

    .header-mobile {
      font-weight: 700;
      color: $font-color;
    }

    .cell-content-mobile {
      font-weight: 700;
      color: $headings-color;
    }

    .row-container-mobile {
      display: flex;
      flex-direction: row;
      overflow-x: hidden;
      justify-content: space-between;
    }


    .buttons-wrapper-mobile {
      color: $white;
      text-align: center;
      width: 100%;
      justify-content: flex-end;

      &.buttons-wrapper-mobile.buttons-wrapper-mobile {
        //overwrite row padding
        padding: 5px;
      }

      div[class^="col"] {
        &:not(:first-child) {
          margin-left: 0.5rem;
        }
      }

      .icon-button.white-icon-buttons {
        font-size: 22px;
        color: $white;
        background: $headings-color;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
      }
    }
  }

  .grid-card-item {
    min-height: 95px;

    .grid-card-col {
      word-break: break-all;
      padding: 0 !important;

      @media (min-width: 400px) {
        flex: 1 0 50%;
        max-width: 50%;
      }
    }

    .grid-card-item-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      justify-content: center;

      .row {
        padding: 3px 0;
      }
    }

    .card-title {
      background-color: $headings-color;
      color: $white;
      width: 100%;
      padding: 0.5em;
      font-weight: bold;
    }

    .card-content {
      display: flex;
      flex-flow: column wrap;
      padding: 5px 10px;
      width: 100%;

      p {
        order: 999;
        margin-bottom: 0.2em;
      }
    }
  }
</style>
