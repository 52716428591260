<template>
  <div class="reactive-graphs" :class="checkClasses">
    <lh-chart :obj="chartObjs" :key="'chart'" :ref="'chart'"></lh-chart>
    <!--      <div v-for="(chartObj, index) in chartObjs">-->
    <!--        <lh-chart :obj="chartObj" :key="'chart' + index" :ref="'chart'+index"></lh-chart>-->
    <!--      </div>-->
    <component v-for="item in obj.children" :is="item.type" :key="item.key" :obj="item"></component>
  </div>
</template>

<script>
  import LhChart from '../Lighthouse-Front/src/components/LhChart'
  import { eventBus } from '../Lighthouse-Front/src/services/event-bus'

  export default {
    name: 'ReactiveGraph',
    components: {LhChart},
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        selectedChoice: [],
        chartObjs: {
          attributes: {
            chartdata: this.obj.attributes.chartdata,
            options: this.obj.attributes.options,
            type: '',
            height: this.obj.attributes.height,
            displayLegend: this.obj.attributes.displayLegend
          }
        }
      }
    },
    methods: {
      handleChange () {
        let allData = []

        for (let dataset in this.obj.attributes.datasets) {
          if (this.selectedChoice.length > 0) {
            if (this.obj.attributes.radio) {
              let set = this.obj.attributes.datasets[dataset][this.selectedChoice[0]]
              allData.push(set)
            } else {
              for (let choice of this.selectedChoice) {
                let set = this.obj.attributes.datasets[dataset][choice]
                allData.push(set)
              }
            }
          }
        }
        this.chartObjs.attributes.chartdata.datasets = allData

        if (this.$refs['chart']) {
          this.$refs['chart'].updateData()
        }
      },
      handleChoiceChanged (val) {
        this.selectedChoice = val
        this.handleChange()
        if (this.$refs['chart']) this.$refs['chart'].updateOptions()
      },
      refreshAttributes() {
        this.chartObjs.attributes.options = this.obj.attributes.options
        this.chartObjs.attributes.type = !this.isMobile ? this.obj.attributes.type
            : this.obj.attributes.mobileType ? this.obj.attributes.mobileType : this.obj.attributes.type
        this.chartObjs.attributes.chartdata.labels = this.obj.attributes.chartdata.labels
        this.chartObjs.attributes.height = this.obj.attributes.height
        this.handleChange()
      }
    },
    computed: {
      attributes () {
        return this.obj.attributes
      }
    },
    mounted () {
      this.refreshAttributes()
      eventBus.$on('graph-choice-selected' + this.obj.key, this.handleChoiceChanged)
      this.$nextTick(() => {
        eventBus.$emit('graph-change-select-type' + this.obj.attributes.target, this.obj.attributes.radio)
      })
    },
    watch: {
      attributes () {
       this.refreshAttributes()
      },
      'obj.attributes.radio' () {
        eventBus.$emit('graph-change-select-type' + this.obj.attributes.target, this.obj.attributes.radio)
      },
      'obj.attributes.height' () {
        this.refreshAttributes()
      },
      isMobile () {
        this.$nextTick(()=>{
          this.refreshAttributes()
        })
      }
    }
  }
</script>

<style lang="scss">
  .reactive-graphs {
    min-height: 600px;
    canvas {
      max-width: 100%;
    }
  }
</style>
<style scoped lang="scss">
  .radio-group {
    display: flex;
    flex-direction: column;
  }
</style>
