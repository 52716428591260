<template>
  <div>
    <div class="color-input-wrapper">
      <input class="form-control"
             :value="colorValue.hex"
             @change="setColor"
             @focus="openPicker"
             @keyup.enter="setColor"
             :ref="obj.attributes.id"
      />
      <span v-if="colorValue.hex" class="pickedColor" :style="'background-color:' + colorValue.hex"></span>
    </div>
    <div class="picker-wrapper">
      <Chrome v-if="isOpen" :color="colorValue" v-model="colorValue" @input="handleColorpicker" :disableAlpha="true"></Chrome>
    </div>
  </div>
</template>

<script>
  import { Chrome, Compact } from 'vue-color'
  import { eventBus } from '@/services/event-bus'

  export default {
    name: 'colorpicker',
    components: {
      Chrome: Chrome,
      Compact: Compact
    },
    props: {
      obj: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        colorValue: {
          hex: ''
        },
        isOpen: false
      }
    },
    computed: {
      parentFormKey () {
        return this.$store.getters.getInsideOfFormKey(this.obj.key)
      }
    },
    methods: {
      setColor (event) {
        console.log(event)
        var regex = new RegExp("^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$")
        let value = event.target.value ? event.target.value : ''
        value = value.startsWith("#") ? value.toUpperCase() : '#' + value.toUpperCase()
        if (regex.test(value)) {
          this.$set(this.colorValue, 'hex', value)
          this.$emit('handleChange', this.colorValue.hex)
          this.closePicker()
        } else {
          console.log('invalid hex code')
          this.colorValue.hex = ''
          this.$set(this.colorValue, 'hex', '')
          this.$emit('handleChange', this.colorValue.hex)
        }
      },
      openPicker () {
        this.isOpen = true
        let payload = {
          parentForm: this.parentFormKey,
          id: this.obj.attributes.id,
          parent: this.obj.attributes.parent
        }
        eventBus.$emit('inputFocus' + payload.parentForm, { id: payload.id, parent: payload.parentBlock })
      },
      closePicker () {
        this.isOpen = false
      },
      handleClickOutside (evt) {
        if (!this.$el.contains(evt.target)) {
          this.isOpen = false
        }
      },
      handleColorpicker() {
        this.$emit('handleChange', this.colorValue.hex)
      },
      handleFocusInput () {
        this.$nextTick(() => {
          console.log('datepicker focus',)
          const inputRef = this.$refs[this.obj.attributes.id]
          inputRef.focus()
        })
      }
    },
    mounted () {
      document.addEventListener('click', this.handleClickOutside)
      if (this.obj.attributes.value) {
        this.colorValue.hex = this.obj.attributes.value
      }
      if (this.obj.attributes.focusOnLoad) {
        this.handleFocusInput()
      }
    },
    destroyed () {
      document.removeEventListener('click', this.handleClickOutside)
    }
  }
</script>

<style scoped lang="scss">
  .color-input-wrapper {
    position: relative;

    .pickedColor {
      position: absolute;
      display: inline-block;
      width: 1.56rem;
      height: 1.56rem;
      right: 7px;
      top: 0.445rem;
      border-radius: 1.56rem;
      border: 1px solid $medium-grey;
    }
  }

  .picker-wrapper {
    position: absolute;
    z-index: 2;
  }
</style>
