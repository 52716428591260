<template>
  <div class="list">
    {{$t(obj.attributes.content)}}
    <component v-for="item in obj.children" :is="item.type" :key="item.key" :obj="item"></component>
  </div>
</template>

<script>
  export default {
    name: 'ListItem',
    components: {},
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
