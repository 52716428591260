<template>
  <div class="lh-label" :class="checkClasses">
    <label :style="checkStyles">{{$t(obj.attributes.content)}}</label>
    <component v-for="item in obj.children" :is="item.type" :key="item.key" :obj="item"></component>
  </div>
</template>
<script>
  export default {
    name: 'LhLabel',
    components: {},
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>
