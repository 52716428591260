<template>
  <div>
    <component
        v-if="notEmpty(testJSON)"
        :is="testJSON.type"
        :key="testJSON.key"
        :obj="testJSON"
    ></component>
  </div>
</template>

<script>

export default {
  data () {
    return {
      testJSON: {
        'key': 'Root',
        'type': 'LhRoot',
        'actions': [],
        'attributes':
            {
              'version':
                  '0.0.1',
              'pageTitle': 'About dev',
              'backgroundUrl': 'https://api.dev.automationtool.technieken.be/assets/img/lighthouse.svg'
            }
        ,
        'styles':
            [], 'children':
            [{
              'key': 'Root_Sidebar',
              'type': 'LhSidebar',
              'actions': [],
              'attributes': [],
              'styles': [],
              'children': [{
                'key': 'root-menu-logo-client',
                'type': 'LhImage',
                'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                'attributes': {
                  'src': 'https://api.dev.automationtool.technieken.be/assets/img/logo.svg',
                  'collapse-src': 'https://api.dev.automationtool.technieken.be/assets/img/logo_small.svg',
                  'class': 'client-logo'
                },
                'styles': { 'cursor': 'pointer' },
                'children': []
              },
                {
                'key': 'root-menu-scroll-content',
                'type': 'LhSidebarScrollContent',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-nav-list',
                  'type': 'LhNavList',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [{
                    'key': 'root-menu-nav-list-item-1',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_INDEX', 'action': '/', 'icon': 'home' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-2',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_CLIENTS', 'action': '/clients', 'icon': 'file-alt' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-3',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_PROJECTS', 'action': '/projects', 'icon': 'chart-pie' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-4',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_PAGES', 'action': '/pages', 'icon': 'users' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-5',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_CONFIG', 'action': '/users', 'icon': 'cog' },
                    'styles': [],
                    'children': [{
                      'key': 'root-menu-nav-sub-list-5',
                      'type': 'LhNavList',
                      'actions': [],
                      'attributes': [],
                      'styles': [],
                      'children': [{
                        'key': 'root-menu-nav-sub-list-5-item-1',
                        'type': 'LhNavListItem',
                        'actions': [],
                        'attributes': { 'content': 'SIDEBAR_USERS', 'action': '/users' },
                        'styles': [],
                        'children': []
                      }, {
                        'key': 'root-menu-nav-sub-list-5-item-2',
                        'type': 'LhNavListItem',
                        'actions': [],
                        'attributes': { 'content': 'SIDEBAR_BLOCKS', 'action': '/pageblocks' },
                        'styles': [],
                        'children': []
                      }]
                    }]
                  }]
                }]
              },
                {
                'key': 'root-menu-logo-group',
                'type': 'LhDiv',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-logo-lighthouse',
                  'type': 'LhImage',
                  'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                  'attributes': {
                    'src': 'https://api.dev.automationtool.technieken.be/assets/img/lighthouse_logo_grey.svg',
                    'class': 'lighthouse-logo'
                  },
                  'styles': { 'cursor': 'pointer' },
                  'children': []
                }]
              }, {
                'key': 'root-menu-button-group',
                'type': 'LhButtonGroup',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-col-1',
                  'type': 'LhCol',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [{
                    'key': 'root-menu-user-button',
                    'type': 'LhIconButton',
                    'actions': [{ 'url': '/settings', 'type': 'update', 'method': 'GET', 'event': 'click' }],
                    'attributes': { 'icon': 'user-cog', 'class': 'sidebar-user-settings-button' },
                    'styles': [],
                    'children': []
                  }]
                }, {
                  'key': 'root-menu-col-2',
                  'type': 'LhCol',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [
                      {
                    'key': 'root-menu-logout-button',
                    'type': 'LhIconButton',
                    'actions': [{ 'url': '/logout', 'type': 'redirect', 'method': 'POST', 'event': 'click' }],
                    'attributes': { 'icon': 'sign-out-alt', 'class': 'sidebar-user-logout-button' },
                    'styles': [],
                    'children': []
                  }
                  ]
                },
                  {
                    'key': 'root-menu-col-3',
                    'type': 'LhCol',
                    'actions': [],
                    'attributes': [],
                    'styles': [],
                    'children': [
                      {
                        'key': 'toggle-translations-button',
                        'type': 'LhIconButton',
                        'actions': [
                          {
                            event: 'click',
                            method: 'translations-toggle-view',
                            type: 'emit',
                            url: ''
                          }
                        ],
                        'attributes': {
                          'name': 'toggle-translations-button',
                          icon: 'globe',
                          transparent: true,
                          disabled: this.loadingJSON
                        },
                        'styles': [],
                        'children': []
                      }
                    ]
                  },
                  {
                    'key': 'root-menu-col-4',
                    'type': 'LhCol',
                    'actions': [],
                    'attributes': [],
                    'styles': [],
                    'children': [
                      {
                        'key': 'translations-popup-button',
                        'type': 'LhIconButton',
                        'actions': [
                          {
                            event: 'click',
                            method: '',
                            type: '',
                            url: ''
                          }
                        ],
                        'attributes': {
                          'name': 'translations-popup-button',
                          icon: 'file-alt',
                          transparent: true,
                          disabled: this.loadingJSON
                        },
                        'styles': [],
                        'children': []
                      }
                    ]
                  }
                  ]
              }]
            },
              {
                "key":"main-admin-dashboard",
                "type":"LhMainPage",
                "actions":[

                ],
                "attributes":[

                ],
                "styles":[

                ],
                "children":[
                  {
                    "key": "dashboard-title-groupproject",
                    "type": "LhDiv",
                    "actions": [],
                    "attributes": {
                      "class": "mp-grid",
                      "gridSize": 5
                    },
                    "styles": [],
                    "children": [
                      {
                        "key": "dashboard-input-widget-today",
                        "type": "LhWidget",
                        "actions": [
                          {
                            "url": "\/visits?date=today",
                            "type": "redirect",
                            "method": "GET",
                            "event": "click"
                          }
                        ],
                        "attributes": {
                          "title": "DASHBOARD_VISITS_TODAY",
                          "icon": "briefcase-medical",
                          "stat": "0",
                          "type": "type2"
                        },
                        "styles": {
                          "cursor": "pointer"
                        },
                        "children": []
                      },
                      {
                        "key": "dashboard-input-widget-week",
                        "type": "LhWidget",
                        "actions": [
                          {
                            "url": "\/visits?date=week",
                            "type": "redirect",
                            "method": "GET",
                            "event": "click"
                          }
                        ],
                        "attributes": {
                          "title": "DASHBOARD_VISITS_WEEK",
                          "icon": "briefcase-medical",
                          "stat": "0",
                          "type": "type1"
                        },
                        "styles": {
                          "cursor": "pointer"
                        },
                        "children": []
                      },
                      {
                        "key": "dashboard-input-widget-month",
                        "type": "LhWidget",
                        "actions": [
                          {
                            "url": "\/visits?date=month",
                            "type": "redirect",
                            "method": "GET",
                            "event": "click"
                          }
                        ],
                        "attributes": {
                          "title": "DASHBOARD_VISITS_MONTH",
                          "icon": "briefcase-medical",
                          "stat": "2",
                          "type": "type1"
                        },
                        "styles": {
                          "cursor": "pointer"
                        },
                        "children": []
                      },
                      {
                        "key": "dashboard-input-widget-active",
                        "type": "LhWidget",
                        "actions": [
                          {
                            "url": "\/patients?active=1",
                            "type": "redirect",
                            "method": "GET",
                            "event": "click"
                          }
                        ],
                        "attributes": {
                          "title": "DASHBOARD_PATIENTS_ACTIVE",
                          "icon": "users",
                          "stat": "2",
                          "type": "type1"
                        },
                        "styles": {
                          "cursor": "pointer"
                        },
                        "children": []
                      },
                      {
                        "key": "dashboard-icf-widget-active",
                        "type": "LhWidget",
                        "actions": [
                          {
                            "url": "\/patients?icf=1",
                            "type": "redirect",
                            "method": "GET",
                            "event": "click"
                          }
                        ],
                        "attributes": {
                          "title": "DASHBOARD_ICF_COUNT",
                          "icon": "exclamation-circle",
                          "stat": "2",
                          "type": "type1"
                        },
                        "styles": {
                          "cursor": "pointer"
                        },
                        "children": []
                      },
                      {
                        "key": "dashboard-input-widget-lab",
                        "type": "LhWidget",
                        "actions": [
                          {
                            "url": "\/visits?missingLabReport=1",
                            "type": "redirect",
                            "method": "GET",
                            "event": "click"
                          }
                        ],
                        "attributes": {
                          "title": "DASHBOARD_MISSING_LAB_REPORT_COUNT",
                          "icon": "briefcase-medical",
                          "stat": "4",
                          "type": "type1"
                        },
                        "styles": {
                          "cursor": "pointer"
                        },
                        "children": []
                      },
                      {
                        "key": "dashboard-input-widget-unsent-reports",
                        "type": "LhWidget",
                        "actions": [
                          {
                            "url": "\/visits?visit-sent-reports=0",
                            "type": "redirect",
                            "method": "GET",
                            "event": "click"
                          }
                        ],
                        "attributes": {
                          "title": "DASHBOARD_UNSENT_REPORTS",
                          "icon": "briefcase-medical",
                          "stat": "2",
                          "type": "type1"
                        },
                        "styles": {
                          "cursor": "pointer"
                        },
                        "children": []
                      }
                    ]
                  },
                  {
                    "key": "dynamicforms-add-edit-form",
                    "type": "LhForm",
                    "actions": [
                      {
                        "url": "\/forms\/add",
                        "type": "update",
                        "method": "POST",
                        "event": "submit"
                      }
                    ],
                    "attributes": {
                      "id": "dynamicforms-add-edit-form",
                      "closeOnSave": true,
                      "includeTableInfo": "complex-browse-dynamicformss"
                    },
                    "styles": [],
                    "children": [
                      {
                        "key": "dynamicforms-edit-form-row",
                        "type": "LhRow",
                        "actions": [],
                        "attributes": [],
                        "styles": [],
                        "children": [
                          {
                            "key": "dynamicforms-edit-col",
                            "type": "LhCol",
                            "actions": [],
                            "attributes": {
                              "cols": 12
                            },
                            "styles": [],
                            "children": [
                              {
                                "key": "dynamic-form-detail-tab-title",
                                "type": "LhH3",
                                "actions": [],
                                "attributes": {
                                  "content": "DYNAMICFORMS_DETAIL"
                                },
                                "styles": [],
                                "children": []
                              },
                              {
                                "key": "dynamicforms-edit-form-row-5",
                                "type": "LhRow",
                                "actions": [],
                                "attributes": [],
                                "styles": [],
                                "children": [
                                  {
                                    "key": "dynamicforms-detail-col-5-left",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": "auto",
                                      "md": 6
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "pharmacy-code111132",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "text-select",
                                          "label": "Label",
                                          "disabled": false,
                                          "prefix": "Netto",
                                          "postfix": "Kg",
                                          "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget orci tristique, convallis felis eu, suscipit urna. Pellentesque finibus vehicula porttitor. Nulla facilisi. Nam et nibh eros. Pellentesque sodales diam ac eros pharetra, id porttitor elit rutrum."
                                        },
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": "pharmacy-code111132",
                                            "type": "LhInput",
                                            "actions": [],
                                            "attributes": {
                                              "type": "text",
                                              "name": "code",
                                              "id": "pharmacy-code-cru",
                                              "value": "",
                                              "disabled": false,
                                              "validation": [
                                                {
                                                  "type": "required",
                                                  "translation_key": "GENERAL_VALIDATION_REQUIRED",
                                                  "translation_key_custom": null
                                                }
                                              ],
                                              "placeholder": "123 (placeholder)"
                                            }
                                          },
                                          {
                                            "key": "languageSearch111152",
                                            "type": "LhInput",
                                            "actions": [],
                                            "attributes": {
                                              "type": "select",
                                              "name": "languageSearch",
                                              "id": "pharmacy-languageId-cru",
                                              "content": [
                                                {
                                                  "value": "3b324c49e24ce9e6cb12ef1d6a09d1793b85429150d341e97fb05df30506797afd41d0cfaa7b6810666c3c7d73c9e6e6fa4a8b7e5c2dd4bf390365f4522851de",
                                                  "text": "Kg"
                                                },
                                                {
                                                  "value": "47f912585f58ec5fdf59c17c738496a2e48fa40fba38c69ab42f68b0d1ea1d758e35bdab7cd09520fcc025f7f5999347782f03e7940329b85935edc36c866835",
                                                  "text": "Mg"
                                                }
                                              ],
                                              "value": "3b324c49e24ce9e6cb12ef1d6a09d1793b85429150d341e97fb05df30506797afd41d0cfaa7b6810666c3c7d73c9e6e6fa4a8b7e5c2dd4bf390365f4522851de",
                                              "disabled": false,
                                              "validation": [
                                                {
                                                  "type": "required",
                                                  "translation_key": "GENERAL_VALIDATION_REQUIRED",
                                                  "translation_key_custom": null
                                                }
                                              ]
                                            }
                                          }
                                        ]
                                      },
                                      {
                                        "key": "dynamicforms-name",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "text",
                                          "label": "DYNAMICFORMS_NAME",
                                          "name": "name",
                                          "id": "dynamicforms-name-cru",
                                          "value": null,
                                          "disabled": false,
                                          "validation": [
                                            {
                                              "type": "required",
                                              "translation_key": "GENERAL_VALIDATION_REQUIRED",
                                              "translation_key_custom": null
                                            }
                                          ]
                                        },
                                        "styles": [],
                                        "children": []
                                      },
                                      {
                                        "key": "status",
                                        "type": "LhInput",
                                        "actions": [
                                          {
                                            "url": "\/forms\/contenttype",
                                            "target": "form-typeupdate",
                                            "type": "inputUpdate",
                                            "method": "POST",
                                            "event": "input"
                                          }
                                        ],
                                        "attributes": {
                                          "type": "select",
                                          "label": "DYNAMICFORMS_OBJECT",
                                          "name": "object",
                                          "id": "dynamicforms-object-cru",
                                          "content": [
                                            {
                                              "text": "GENERAL_CHOOSE",
                                              "value": ""
                                            },
                                            {
                                              "value": "patient",
                                              "text": "patient"
                                            },
                                            {
                                              "value": "visit",
                                              "text": "visit"
                                            },
                                            {
                                              "value": "hcp",
                                              "text": "hcp"
                                            }
                                          ],
                                          "disabled": false,
                                          "validation": [
                                            {
                                              "type": "required",
                                              "translation_key": "GENERAL_VALIDATION_REQUIRED",
                                              "translation_key_custom": null
                                            }
                                          ],
                                          "value": "",
                                          "labelCols": 12
                                        },
                                        "styles": [],
                                        "children": []
                                      },
                                      {
                                        "key": "form-typeupdate",
                                        "type": "LhDiv",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": []
                                      },
                                      {
                                        "key": "dynamicforms-inCore",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "checkbox",
                                          "labelPlaceholder": false,
                                          "label": "",
                                          "name": "inCore",
                                          "id": "dynamicforms-inCore-cru",
                                          "value": "0",
                                          "disabled": false,
                                          "stacked": false,
                                          "switchCheckbox": false
                                        },
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": null,
                                            "type": null,
                                            "actions": [],
                                            "attributes": {
                                              "value": "1",
                                              "content": "DYNAMICFORMS_INCORE"
                                            },
                                            "styles": [],
                                            "children": []
                                          }
                                        ]
                                      },
                                      {
                                        "key": "dynamicforms-inPortal",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "checkbox",
                                          "labelPlaceholder": false,
                                          "label": "",
                                          "name": "inPortal",
                                          "id": "dynamicforms-inPortal-cru",
                                          "value": "0",
                                          "disabled": false,
                                          "stacked": false,
                                          "switchCheckbox": false
                                        },
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": null,
                                            "type": null,
                                            "actions": [],
                                            "attributes": {
                                              "value": "1",
                                              "content": "DYNAMICFORMS_INPORTAL"
                                            },
                                            "styles": [],
                                            "children": []
                                          }
                                        ]
                                      },
                                      {
                                        "key": "dynamicforms-information",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "textarea",
                                          "label": "DYNAMICFORMS_INFORMATION",
                                          "name": "information",
                                          "id": "dynamicforms-information-cru",
                                          "value": null,
                                          "disabled": false,
                                          "rows": 4
                                        },
                                        "styles": [],
                                        "children": []
                                      }
                                    ]
                                  },
                                  {
                                    "key": "dynamicforms-detail-col-5-right",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": "auto",
                                      "md": 6
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "dynamicforms-highlight-score",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "radio",
                                          "label": "DYNAMICFORMS_HIGHLIGHT_TOGGLE_LABEL",
                                          "name": "highlight",
                                          "id": "dynamicforms-highlight-score",
                                          "value": "0",
                                          "disabled": false,
                                          "stacked": true
                                        },
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": null,
                                            "type": null,
                                            "actions": [
                                              {
                                                "display": {
                                                  "#dynamicforms-highlight-score-value": true
                                                }
                                              }
                                            ],
                                            "attributes": {
                                              "value": "1",
                                              "content": "GENERAL_YES"
                                            },
                                            "styles": [],
                                            "children": []
                                          },
                                          {
                                            "key": null,
                                            "type": null,
                                            "actions": [
                                              {
                                                "display": {
                                                  "#dynamicforms-highlight-score-value": false
                                                }
                                              }
                                            ],
                                            "attributes": {
                                              "value": "0",
                                              "content": "GENERAL_NO"
                                            },
                                            "styles": [],
                                            "children": []
                                          }
                                        ]
                                      },
                                      {
                                        "key": "dynamicforms-action-status",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "radio",
                                          "label": "DYNAMICFORMS_ACTION_STATUS",
                                          "name": "actionStatus",
                                          "id": "dynamicforms-action-statu",
                                          "value": null,
                                          "disabled": false,
                                          "stacked": true,
                                          "validation": [
                                            {
                                              "type": "required",
                                              "translation_key": "GENERAL_VALIDATION_REQUIRED",
                                              "translation_key_custom": null
                                            }
                                          ]
                                        },
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": null,
                                            "type": null,
                                            "actions": [],
                                            "attributes": {
                                              "value": "1",
                                              "content": "DYNAMICOFRMS_FORM_CLOSED"
                                            },
                                            "styles": [],
                                            "children": []
                                          },
                                          {
                                            "key": null,
                                            "type": null,
                                            "actions": [],
                                            "attributes": {
                                              "value": "0",
                                              "content": "DYNAMICOFRMS_FORM_OPEN"
                                            },
                                            "styles": [],
                                            "children": []
                                          }
                                        ]
                                      },
                                      {
                                        "key": "dynamicforms-highlight-score-value",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "number",
                                          "label": "DYNAMICFORMS_HIGHLIGHT_VALUE_LABEL",
                                          "name": "highlightScore",
                                          "id": "dynamicforms-highlight-score-value",
                                          "value": null,
                                          "disabled": false
                                        },
                                        "styles": [],
                                        "children": []
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "key": "dynamicforms-guid",
                                "type": "LhInput",
                                "actions": [],
                                "attributes": {
                                  "type": "hidden",
                                  "name": "guid",
                                  "id": "guid",
                                  "value": null
                                },
                                "styles": [],
                                "children": []
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
          ]
      },
    }
  }
}
</script>

<style scoped lang="scss">
</style>
