<template>
  <div class="lh-viewtype">
    <b-select v-model="selViewType" placeholder="Select view type" class="lh-selectType">
      <option v-for="(name,value) in viewTypes" :key="name" :label="$t(name)" :value="value"></option>
    </b-select>
  </div>
</template>

<script>
  export default {
    name: 'LhViewType',
    display: 'Transitions',
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        drag: false,
        viewTypes: {
          FULLPAGE: 'Full page',
          POPUP: 'Popup',
          SIDEFORM: 'Side Form'
        }
      }
    },
    methods: {
      handleChange (val) {}
    },
    computed: {
      browseType () {
        return this.$store.getters.getViewType
      },
      list: {
        get () {
          return this.obj.children.map((child, index) => {
            let name = child.attributes.content
            let checked = child.attributes.checked
            return { name, checked, order: index + 1 }
          })
        },
        set (val) {
          console.log(val)
          this.obj.children.map((child, index) => {
            child.attributes.content = val[index].name
            child.attributes.checked = val[index].checked
          })
        }
      },
      selViewType: {
        get () {
          return this.$store.getters.getViewType
        },
        set (val) {
          this.$store.commit('setViewType', val)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
