<template>
  <div>
    <v-popover
        trigger="hover"
        :position="obj.attributes.position ? obj.attributes.position : 'right'"
    >
      <div v-if="target">
        <component v-for="child in target" :is="child.type" :key="child.key" :obj="child"></component>
      </div>
      <div v-else>
        {{$t(obj.attributes.content)}}
      </div>

      <template slot="popover">
        <component v-for="child in content" :is="child.type" :key="child.key" :obj="child"></component>
      </template>
    </v-popover>
  </div>
</template>

<script>
  export default {
    name: 'LhTooltip',
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        isOpen: false
      }
    },
    computed: {
      target () {
        return this.obj.children.filter(item => {
          return item.attributes.tooltipTarget
        })
      },
      content () {
        return this.obj.children.filter(item => {
          return !item.attributes.tooltipTarget
        })
      }
    }
  }
</script>

