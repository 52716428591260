<template>
  <div class="root" :class="[checkClasses]" :style="checkStyles" v-tooltip="checkTooltip" draggable="false">
    <div v-if="devMode" class="dev-mode">{{ devMode }}</div>
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
    <!-- sideform -->
    <div class="detail-sideform">
      <lh-detail-form v-if="sideFormContent && sideFormContent.key && viewType === 'SIDEFORM'"></lh-detail-form>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'Root',
    components: {},
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {}
    },
    computed: {
      ...mapState({
        sidebarCollapsed: 'sidebarCollapse',
        viewType: 'viewType',
        sideFormContent: 'sideFormContent'
      }),
      devMode(){
        return this?.obj?.attributes?.devMode ? this.obj.attributes.devMode : false
      }
    },
    methods: {
      ...mapMutations({
        setMobile: 'setMobile',
        setWindowSize: 'setWindowSize',
        setBackendVersion: 'setBackendVersion'
      }),
      checkWindow () {
        let windowSize = {
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight
        }
        this.setMobile(windowSize.windowWidth)
        this.setWindowSize(windowSize)
      }
    },
    watch: {
      'obj.attributes.pageTitle' () {
        document.title = this.$t(this.obj.attributes.pageTitle)
      }
    },
    created () {
      if (this.obj.attributes.version) {
        this.setBackendVersion(this.obj.attributes.version)
      }
      if (this.obj.attributes.pageTitle) {
        document.title = this.$t(this.obj.attributes.pageTitle)
      }
    },
    mounted () {
      this.$nextTick(() => {
        window.addEventListener('resize', this.checkWindow)
        this.checkWindow()
      })
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.checkWindow)
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .root {
    display: flex;
    flex-flow: row nowrap;
    max-width: calc(100 * var(--vw-unit));

    @include media-breakpoint-up(md) {
      max-height: calc(100 * var(--vh-unit));
      overflow: hidden;
    }
  }

  .containerContent {
    background: #ffffff95;
  }

  .dev-mode {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: $red;
    color: $white;
    display: flex;
    align-items: center;
    padding: 2px 1rem;
    z-index: 99;
    opacity: .9;
    font-weight: bold;
  }
</style>
