<template>
  <a v-if="externalLink"
     class="lh-a" :class="checkClasses" :style="checkStyles" v-tooltip="checkTooltip"
     :href="obj.attributes.href"
     :target="obj.attributes.target"
  >
    {{$t(obj.attributes.content)}}
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
  </a>
  <a v-else-if="popupLink"
     v-on="allEvents"
     @click="checkForModal"
     class="lh-a openInModal" :class="checkClasses" :style="checkStyles" v-tooltip="checkTooltip"
  >
    {{$t(obj.attributes.content)}}
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
  </a>
  <router-link
      v-else
      class="lh-a" :class="checkClasses" :style="checkStyles" v-tooltip="checkTooltip"
      :to="{path: obj.attributes.href}"
      :key="obj.key"
  >
    {{$t(obj.attributes.content)}}
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
  </router-link>
</template>

<script>
  import mixinFunctions from '@/mixin/mixinFunctions'

  export default {
    name: 'LhA',
    mixins: [ mixinFunctions ],
    props: {
      obj: {
        type: Object
      },
      modalId: {
        type: String
      }
    },
    computed: {
      externalLink() {
        return this.obj && this.obj.attributes && this.obj.attributes.href
          && (this.obj.attributes.href.indexOf('http://') === 0 || this.obj.attributes.href.indexOf('https://') === 0 || this.obj.attributes.href.indexOf('mailto:') === 0 || this.obj.attributes.href.indexOf('tel:') === 0)
      },
      popupLink() {
        return this.obj && this.obj.actions && this.obj.actions.find((action) => { return action.type === 'display' } )
      }
    },
    methods: {
      checkForModal () {
        if (this.modalId) {
          this.$root.$emit('bv::hide::modal', this.modalId)
        }
      }
    }
  }
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  a.openInModal {
    &:hover {
      cursor: pointer;
    }
  }
</style>