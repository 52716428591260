<template>
  <div class="reactive-graphs-selector" :class="checkClasses">
        <div v-if="radio || isMobile">
          <b-form-group :label="obj.attributes.choiceLabel">
            <b-form-radio-group id="radio-group" v-model="selectedChoice[0]" class="radio-group">
              <b-form-radio v-for="(name, choice) in choices" :value="choice">{{name}}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div v-else>
          <b-form-group :label="obj.attributes.choiceLabel">
            <b-form-checkbox-group id="checkbox-group" v-model="selectedChoice" class="radio-group">
              <b-form-checkbox v-for="(name, choice) in choices" :value="choice">{{name}}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
    <hr/>
    <component v-for="item in obj.children" :is="item.type" :key="item.key" :obj="item"></component>
  </div>
</template>

<script>
  import { eventBus } from '../Lighthouse-Front/src/services/event-bus'

  export default {
    name: 'LhReactiveGraphsSelector',
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        selectedChoice: [],
        choices: this.obj.attributes.choices ? this.obj.attributes.choices : [],
        radio: false
      }
    },
    methods: {
      handleSelectionTypeChange(val) {
        this.radio = val
      }
    },
    mounted() {
      eventBus.$on('graph-change-select-type' + this.obj.key, this.handleSelectionTypeChange)
      //default choice
      //nexttick because LhReactiveGraph is not initiated yet
      this.$nextTick()
          .then(() => {
            if (this.obj.attributes.defaultChoice) this.selectedChoice.push(this.obj.attributes.defaultChoice)
          })
    },
    watch: {
      selectedChoice () {
        eventBus.$emit('graph-choice-selected' + this.obj.attributes.target, this.selectedChoice)
      }
    }
  }
</script>

<style scoped lang="scss">
  .stats-filter-col {
    max-width: 230px;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
  .radio-group{
    display: flex;
    flex-direction: column;
  }

  form {
    span {
      width: 100%;
    }
  }
</style>
