<!--
Attributes: (needs to go in input > obj.attributes.extraInfo
  'extraInfo': {
    'url': '/test',
    'content': ['Test pharmacy', 'Address line 1', 'Address line 2'] < 1 value per line of info to display
  }
-->

<template>
  <div class="extra-info">
    <div v-for="line in obj.content">{{ line }}</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LhInputExtraInfo',
  props: {
    obj: {
      type: Object
    },
    value: {
    }
  },
  data () {
    return {
      content: ''
    }
  },
  computed: {},
  methods: {
    ...mapActions({
      post: 'postLocal'
    }),
    getNewData (guid) {
      // make API call to get new data to display
      let data = {
        payload: guid,
        url: this.obj.url,
      }
      this.post(data).then((response) => {
        // replace data to show in template
        this.content = response
      })
    }
  },
  watch: {
    value (guid) {
      this.getNewData(guid)
    }
  },
  mounted () {
    if (this.obj.content) {
      this.content = this.obj.content
    }
  }
}
</script>

<style scoped lang="scss">
  .extra-info {
    margin: 0.5em 0;
  }
</style>