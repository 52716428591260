<template>
  <div class="list">
    <ul>
      <template v-for="item in obj.attributes.content">
        <li :key="item.key" :obj="item">
          <p v-for="(value, name) in item" :key="value+ Math.floor(Math.random()*20)">
            <b>{{ $t(name) }}</b>
            : {{ value }}
          </p>
        </li>
        <hr></hr>
      </template>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'LhList',
    components: {},
    props: {
      obj: {
        type: Object
      }
    },
    computed: {
      notPassOrGuid () {
        return
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .list {
    display: block;
    padding: 0 1em;
  }

  .list ul {
    display: block;
  }

  .list ul li {
    display: list-item;
    background: white;
    text-align: left;
    padding: 1em;
  }
</style>
