<template>
  <b-col
      v-bind="obj.attributes"
      class="LhCol"
      :class="checkClasses"
      :style="checkStyles"
      v-tooltip="checkTooltip"
  >
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
  </b-col>
</template>

<script>
  export default {
    name: 'LhCol',
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>

