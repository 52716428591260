import Vue from 'vue'
import moment from 'moment'

Vue.filter('filterDate', value => {
  if (value) {
   if (moment(value, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
     console.log(moment(value, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY')
   )
      return moment(value, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY')
    }
    return value
  }
})

