<template>
  <div class="lh-color-indicator" :class="checkClasses" :style="checkStyles" :v-tooltip="checkTooltip" v-on="allEvents">
    <span class="lh-color-indicator__color" :style="checkColor(obj.attributes.content)"></span>
    <span class="lh-color-indicator__text">{{obj.attributes.content}}</span>
  </div>
</template>

<script>
  import mixinFunctions from '@/mixin/mixinFunctions'
  import confirm from '@/mixin/confirm'

  export default {
    name: 'LhColorIndicator',
    mixins: [ mixinFunctions, confirm ],
    props: {
      obj: {
        type: Object
      }
    },
    methods: {
      checkColor(content) {
        let color = ''
        if (content && content.includes('#')) {
          color = 'background-color:' + content
        } else {
          color = 'background-color: #' + content
        }
        return color
      }
    }
  }
</script>

<style lang="scss">
  .lh-color-indicator {

    &__color {
      display: inline-block;
      position: relative;
      top: 2px;
      height: 1rem;
      width: 1rem;
      border-radius: 25px;
    }

    &__text {
      margin-left: 0.5rem;
    }
  }
</style>

