<template>
  <div class="order-summary mt-2">
    <b-row>
      <b-col md="6" class="details">
        <h3>Order details</h3>
        <b-row v-for="(line) in obj.attributes.info">
          <b-col><div class="mb-1 font-weight-bold">{{ line.label }}:</div></b-col>
          <b-col :md="line.fullWidth ? 12 : 6"><div class="mb-1">{{ line.value }}</div></b-col>
        </b-row>
      </b-col>
      <b-col md="6" class="overview">
        <h3>Product Overview</h3>
        <template v-for="product in obj.attributes.products">
          <lh-product-detail :obj="product" unEditable></lh-product-detail>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
name: "LhFormStepOrderSummary",
props: {
  obj: {
    type: Object
  }
},
data () {
  return {}
},
computed: {},
methods: {},
watch: {}
}
</script>

<style scoped lang="scss">

</style>