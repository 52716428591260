<template>
  <div
      class="lh-progress"
      :class="checkClasses"
      :style="checkStyles"
      v-tooltip="checkTooltip"
  >
    <b-progress v-bind="obj.attributes" show-progress animated precision="2" height="2rem"></b-progress>
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
  </div>
</template>

<script>
  export default {
    name: 'LhProgress',
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>

<style scoped lang="scss">
  .lh-progress {
    margin: 0.5rem 0;
  }
</style>

