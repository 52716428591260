<template>
  <div
    class="grid-dropdown"
    v-on="allEvents"
  >
    <component
        v-for="child in obj.children"
        class="row-expand"
        :is="child.type"
        :obj="child"
        :key="child.key"
    ></component>
  </div>
</template>

<script>
  import mixinFunctions from '@/mixin/mixinFunctions'
  import confirm from '@/mixin/confirm'

  export default {
    name: 'GridDropdown',
    mixins: [ mixinFunctions, confirm ],
    components: {},
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>

<style scoped lang="scss">
  /* @import "../assets/style/components/_grid.scss"; */
  .row-expand {
    clear: both;
  }

  .card-title{
    font-size: 1.25em;
  }
  .grid-dropdown {
    padding: 0 1em;
  }
</style>
