<template>
    <div class="cards">
        <ul>
            <li v-for="row in obj.children" :key="row.key" v-if="row.children[0].type !=='LhGridHeader'">
                <p
                   v-for="(cell, name) in row.children"
                   :key="cell.key"
                > {{ $t(cell.attributes.content) }}</p>
            </li>
        </ul>
    </div>
</template>

<script>
  export default {
    name: 'LhCards',
    components: {},
    props: {
      obj: {
        type: Object
      }
    },
    computed: {
      notPassOrGuid () {
        return
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .cards ul {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    padding: 0 1em;
    list-style: none;
      justify-content: space-between;

  }

  .cards ul li {
    align-self: flex-start;
    background: white;
    border: 2px solid black;
    text-align: left;
    padding: 1em;
    width: 300px;
    word-break: break-all;

    -webkit-box-shadow: 9px 9px 5px -1px rgba(199, 199, 199, 0.6);
    -moz-box-shadow: 9px 9px 5px -1px rgba(199, 199, 199, 0.6);
    box-shadow: 9px 9px 5px -1px rgba(199, 199, 199, 0.6);
  }
</style>
