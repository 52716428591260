<template>
  <div class="product">
    <div class="product__header">
      <div class="name" v-if="obj.attributes">{{ obj.attributes.name }}</div>
      <div class="name" v-else>{{ obj.name }}</div>
    </div>
    <div class="product__details">
      <div class="code">
        <template v-if="obj.pharmaCode">{{ obj.pharmaCode }}</template>
        <template v-if="isFreeProduct && obj.number">{{ obj.number }}</template>
        <div v-if="isFreeProduct && obj.number && !inBasket">{{ currency }}{{ obj.value }}</div>
        <template v-if="obj.cnk">{{ obj.cnk }}</template>
        <template v-if="obj.attributes">
          <template v-if="obj.attributes.pharmaCode">{{ obj.attributes.pharmaCode }}</template>
          <template v-if="obj.attributes.cnk">{{ obj.attributes.cnk }}</template>
        </template>
        <div v-if="isFreeProduct && showPrices && !obj.attributes">
          {{ obj.units }} x {{ currency }}{{ obj.value }}
        </div>
        <div v-else-if="showPrices && !obj.attributes">
          {{ obj.units }} x {{ currency }}{{ obj.unitPrice }} - {{ obj.baseDiscount }}% - {{ obj.discount }}% = {{ currency }}{{ obj.totalValue }}
        </div>
        <div v-if="obj.attributes && showPrices">
          {{ obj.attributes.units }} x {{ obj.attributes.currency }}{{ obj.attributes.unitPrice }} - {{ obj.attributes.baseDiscount }}% - {{ obj.attributes.discount }}% = {{ currency }}{{ obj.attributes.totalValue }}
        </div>
      </div>
      <template v-if="!unEditable">
        <div v-if="productInBasket || notAddable || showAmounts" class="d-flex">
          <div class="editAmount mr-3" v-if="showAmounts">
            <div class="product__button small mr-2" @click="lowerBasketAmount">
              <lh-icon-button :obj="lowerAmount"></lh-icon-button>
            </div>
            <lh-input @handleChange="updateBasketAmount" :obj="inputs.productAmount"></lh-input>
            <div class="product__button small ml-2" @click="upBasketAmount">
              <lh-icon-button :obj="upAmount"></lh-icon-button>
            </div>
          </div>
          <div class="product__button small" @click="editProduct" v-if="(productInBasket || notAddable) && !showAmounts">
            <lh-icon-button :obj="editButton"></lh-icon-button>
          </div>
          <div class="product__button small ml-2" @click="editProduct" v-if="showAmounts">
            <lh-icon-button v-if="!isFreeProduct || (isFreeProduct && !overBudget)" :obj="addToCartButton"></lh-icon-button>
            <lh-icon-button v-if="isFreeProduct && overBudget" class="blocked" :obj="banButton" v-tooltip="$t('free_product_no_budget')"></lh-icon-button>
          </div>
          <div class="product__button small ml-2" @click="emitAction('remove')" v-if="productInBasket && !notAddable">
            <lh-icon-button :obj="deleteButton"></lh-icon-button>
          </div>
        </div>
        <div class="product__button" @click="addProduct" v-if="!showPrices && !notAddable && !showAmounts">
          <lh-icon-button :obj="addButton"></lh-icon-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LhProductDetail',
  props: {
    obj: {
      type: Object
    },
    basket: {
      type: Object
    },
    inBasket: {
      type: Boolean
    },
    unEditable: {
      type: Boolean
    },
    notAddable: {
      type: Boolean
    },
    baseDiscount: {
      type: [String, Number]
    },
    currency: {
      type: String
    },
    isFreeProduct: false
  },
  data () {
    return {
      showAmounts: false,
      showPrices: false,
      productInBasket: false,
      overBudget: false,
      addButton: {
        'key': 'product-add-button',
        'type': 'LhButton',
        'attributes': {
          'content': '',
          'name': 'add-product-button',
          icon: ['fal', 'plus'],
          disabled: this.loadingJSON
        },
        'styles': [],
        'children': []
      },
      addToCartButton: {
        'key': 'product-add-to-cart-button',
        'type': 'LhButton',
        'attributes': {
          'content': '',
          'name': 'add-product-to-cart-button',
          icon: ['far', 'cart-plus'],
          disabled: this.loadingJSON
        },
        'styles': [],
        'children': []
      },
      banButton: {
        'key': 'product-add-button-ban',
        'type': 'LhButton',
        'attributes': {
          'content': '',
          'name': 'ban-product-add-button',
          icon: ['fas', 'ban'],
          disabled: this.loadingJSON
        },
        'styles': [],
        'children': []
      },
      editButton: {
        'key': 'product-edit-button',
        'type': 'LhButton',
        'attributes': {
          'content': '',
          'name': 'edit-product-button',
          icon: ['fas', 'pencil-alt'],
          disabled: this.loadingJSON
        },
        'styles': [],
        'children': []
      },
      deleteButton: {
        'key': 'product-delete-button',
        'type': 'LhButton',
        'attributes': {
          'content': '',
          'name': 'delete-product-button',
          icon: ['fas', 'trash-alt'],
          disabled: this.loadingJSON
        },
        'styles': [],
        'children': []
      },
      lowerAmount: {
        'key': 'product-lower-amount-button',
        'type': 'LhButton',
        'attributes': {
          'content': '',
          'name': 'lower-product-amount-button',
          icon: ['fal', 'minus']
        },
        'styles': [],
        'children': []
      },
      upAmount: {
        'key': 'product-up-amount-button',
        'type': 'LhButton',
        'attributes': {
          'content': '',
          'name': 'up-product-amount-button',
          icon: ['fal', 'plus']
        },
        'styles': [],
        'children': []
      },
      inputs: {
        productAmount: {
          'key': 'product-detail-amount',
          'type': 'LhInput',
          'actions': [],
          'attributes': {
            'type': 'number',
            'name': 'productAmount',
            'id': 'product-detail-amount',
            'value': '1',
            'disabled': false,
          },
          'styles': [],
          'children': []
        },
      },
    }
  },
  computed: {},
  methods: {
    emitAction (action) {
      this.$emit(`${action}-product`, this.obj)
    },
    editProduct (){
      if(this.isFreeProduct) {
        if (this.showAmounts) {
          if(!this.overBudget) {
            this.showAmounts = false
            this.productInBasket = true
            this.$set(this.obj, 'units', this.inputs.productAmount.attributes.value)
            this.emitAction('add')
            this.$emit('update-totals')
          }
        } else {
          console.log(this.obj.productGuid, this.obj.guid, this.getProductUnits(this.obj.productGuid ? this.obj.productGuid : this.obj.guid))
          this.inputs.productAmount.attributes.value = this.getProductUnits(this.obj.productGuid ? this.obj.productGuid : this.obj.guid)
          this.showAmounts = true
          this.$emit('checkBudget', this, this.inputs.productAmount.attributes.value)
        }
      } else {
        this.emitAction('edit')
      }
    },
    addProduct (){
      if(this.isFreeProduct) {
        if(this.showAmounts) {
          if(!this.overBudget) {
            this.showAmounts = false
            this.productInBasket = true
            this.$set(this.obj, 'units', this.inputs.productAmount.attributes.value)
            this.emitAction('add')
            this.$emit('update-totals')
          }
        } else {
          this.showAmounts = true
          if(this.isFreeProduct) {
            this.$emit('checkBudget', this, this.inputs.productAmount.attributes.value)
          }
        }
      } else {
        this.emitAction('add')
      }
    },
    getProductUnits (guid){
      if (this.basket && this.basket.products && this.productInBasket) {
        let product = this.basket.products.find(item => item.productGuid === guid)
        return product ? product.units : 1
      } else {
        return 1
      }
    },
    lowerBasketAmount() {
      let value = this.inputs.productAmount.attributes.value
      if (value > 1) {
        this.inputs.productAmount.attributes.value--
      }
    },
    upBasketAmount(){
      this.inputs.productAmount.attributes.value++
    },
    updateBasketAmount(val){
      this.inputs.productAmount.attributes.value = val
      this.emitAction('checkBudget', this, val)
    }
  },
  watch: {
    'notAddable' (val) {
      if(this.isFreeProduct){
        if(!val){
          //Reset
          this.productInBasket = false
          this.inputs.productAmount.attributes.value = 1
        }
      }
    },
    'inputs.productAmount.attributes.value': function(amount) {
      this.$emit('checkBudget', this, amount)
    },
    'basket.products': function(products) {
      this.$emit('checkBudget', this, this.inputs.productAmount.attributes.value)
    },
  },
  created () {
    if (this.inBasket || this.obj?.attributes?.showPrices) this.showPrices = true
  },
  mounted() {
    this.productInBasket = (this.inBasket || this.notAddable) ? true : false
  },
}
</script>

<style scoped lang="scss">
.product {
  display: flex;
  flex-flow: column nowrap;
  padding: 5px;

  .editAmount {
    display: flex;
    input {
      max-width: 50px;
      min-width: 0;
      text-align: center;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid $medium-light-grey;
  }

  &.selected {
    background: $ultra-light-grey;
    color: $primary-color;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  &__button {
    min-width: 30px;

    .icon-button {
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      background: $browse-edit-buttons-bg;
      color: $browse-edit-buttons-color;
      border-radius: 50%;
      transition: background-color 200ms;

      &:hover, &:focus {
        background: darken($browse-edit-buttons-bg, 10%);
      }

      &.blocked,
      &.blocked:hover,
      &.blocked:focus {
        background: $red;
      }
    }

    &.small {
      .icon-button {
        font-size: 14px;
      }
    }
  }
}
</style>