<template>
  <div class="text-editor">
    <div class="form-group-wrapper">
      <!-- DATE-->
      <b-form-group
          :label="setLabel"
          :label-for="obj.attributes.id"
          :label-cols-md="obj.attributes.labelCols"
          :key="obj.attributes.id"
      >
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, getMarkAttrs }">
      <div class="menubar">
        <div
            class="menubar-button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
        >
          <font-awesome-icon :icon="['fas', 'bold']"></font-awesome-icon>
        </div>

        <div
            class="menubar-button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
        >
          <font-awesome-icon :icon="['fas', 'italic']"></font-awesome-icon>
        </div>

        <div
            class="menubar-button"
            :class="{ 'is-active': isActive.strike() }"
            @click="commands.strike"
        >
          <font-awesome-icon :icon="['fas', 'strikethrough']"></font-awesome-icon>

        </div>

        <div
            class="menubar-button"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
        >
          <font-awesome-icon :icon="['fas', 'underline']"></font-awesome-icon>

        </div>

        <div
            class="menubar-button"
            :class="{ 'is-active': isActive.code() }"
            @click="commands.code"
        >
          <font-awesome-icon :icon="['fas', 'code']"></font-awesome-icon>
        </div>

        <div
            class="menubar-button"
            :class="{ 'is-active': isActive.paragraph() }"
            @click="commands.paragraph"
        >
          <font-awesome-icon :icon="['fas', 'paragraph']"></font-awesome-icon>
        </div>

        <div
            class="menubar-button"
            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
            @click="commands.heading({ level: 1 })"
        >
          <font-awesome-icon :icon="['fas', 'h1']"></font-awesome-icon>
        </div>

        <div
            class="menubar-button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
        >
          <font-awesome-icon :icon="['fas', 'h2']"></font-awesome-icon>
        </div>

        <div
            class="menubar-button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
        >
          <font-awesome-icon :icon="['fas', 'h3']"></font-awesome-icon>
        </div>

        <div
            class="menubar-button"
            :class="{ 'is-active': isActive.heading({ level: 4 }) }"
            @click="commands.heading({ level: 4 })"
        >
          <font-awesome-icon :icon="['fas', 'h4']"></font-awesome-icon>
        </div>

        <div
            class="menubar-button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
        >
          <font-awesome-icon :icon="['fas', 'list-ul']"></font-awesome-icon>
        </div>

        <div
            class="menubar-button"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click="commands.ordered_list"
        >
          <font-awesome-icon :icon="['fas', 'list-ol']"></font-awesome-icon>
        </div>

        <div
            class="menubar-button"
            :class="{ 'is-active': isActive.blockquote() }"
            @click="commands.blockquote"
        >
          <font-awesome-icon :icon="['fas', 'quote-right']"></font-awesome-icon>
        </div>

        <div
            class="menubar-button"
            :class="{ 'is-active': isActive.code_block() }"
            @click="commands.code_block"
        >
          <font-awesome-icon :icon="['fas', 'code']"></font-awesome-icon>
        </div>

        <div
            class="menubar-button"
            @click="commands.horizontal_rule"
        >
          <font-awesome-icon :icon="['fas', 'horizontal-rule']"></font-awesome-icon>
        </div>

        <div
            class="menubar-button"
            @click="commands.undo"
        >
          <font-awesome-icon :icon="['fas', 'undo']"></font-awesome-icon>
        </div>

        <div
            class="menubar-button"
            @click="commands.redo"
        >
          <font-awesome-icon :icon="['fas', 'redo']"></font-awesome-icon>
        </div>

        <form class="menubar-form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
          <input class="menubar-input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
          <button class="menubar-button" @click="setLinkUrl(commands.link, null)" type="button">
            <font-awesome-icon :icon="['fal', 'times']" />
          </button>
        </form>

        <template v-else>
          <div
              class="menubar-button"
              @click="showLinkMenu(getMarkAttrs('link'))"
              :class="{ 'is-active': isActive.link() }"
          ><font-awesome-icon icon="link" />
          </div>
        </template>

      </div>
    </editor-menu-bar>

    <editor-content class="editor__content" :editor="editor" :ref="this.obj.id" />

<!--    <button @click="getHtml">Get html</button>-->
<!--    <button @click="getJson">Get JSON</button>-->
<!--    <div>-->
<!--      <h2>HTML</h2>-->
<!--      {{ html }}-->
<!--      <h2>JSON</h2>-->
<!--      {{ json }}-->
<!--    </div>-->
      </b-form-group>
    </div>
  </div>


</template>

<script>
  import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
  import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
    Image
  } from 'tiptap-extensions'
  import { eventBus } from '../services/event-bus'
  export default {
    components: {
      EditorContent,
      EditorMenuBar
    },
    props: {
      obj: {
        type: Object,
        required: true
      },
      parentKey: {
        type: String
      },
      customLabel: {
        type: String
      }
    },
    data() {
      return {
        editor: new Editor({
          extensions: [
            new Blockquote(),
            new BulletList(),
            new CodeBlock(),
            new HardBreak(),
            new Heading({ levels: [1, 2, 3] }),
            new HorizontalRule(),
            new ListItem(),
            new OrderedList(),
            new TodoItem(),
            new TodoList(),
            new Link(),
            new Bold(),
            new Code(),
            new Italic(),
            new Strike(),
            new Underline(),
            new History(),
            new Image(),
          ],
          content: this.obj.attributes.value,
          onUpdate: this.emitContent,
          onFocus: this.handleFocus,
        }),
        html: '',
        json: {},
        linkUrl: null,
        linkMenuIsActive: false,
      }
    },
    computed: {
      setLabel () {
        if (this.customLabel) {
          return this.rulesObj && this.rulesObj.required ? this.$t(this.customLabel) + ' *' : this.$t(this.customLabel)
        } else if (this.obj.attributes.label) {
          return this.rulesObj && this.rulesObj.required ? this.$t(this.obj.attributes.label) + ' *' : this.$t(this.obj.attributes.label)
        }
      },
      parentFormKey () {
        return this.parentKey ? this.parentKey : this.$store.getters.getInsideOfFormKey(this.obj.key)
      }
    },
    methods: {
      emitContent (e) {
        this.html = e.getHTML()
        this.json = e.getJSON()
        let event = 'handleChange'
        let name = this.obj.attributes.name
        let value = this.html
        let parent = this.obj.attributes.parent
        let key = this.obj.key
        eventBus.$emit('inputEmit' + this.parentFormKey, { event, value, name, parent, key })
      },
      handleFocus () {
        let payload = {
          parentForm: this.parentFormKey,
          id: this.obj.attributes.id,
          parentBlock: this.obj.attributes.parent
        }
        eventBus.$emit('inputFocus' + payload.parentForm, { id: payload.id, parent: payload.parentBlock })
        this.hideLinkMenu()
      },
      handleFocusInput (id) {
        this.editor.focus()
      },
      showLinkMenu(attrs) {
        this.linkUrl = attrs.href
        this.linkMenuIsActive = true
        this.$nextTick(() => {
          this.$refs.linkInput.focus()
        })
      },
      hideLinkMenu() {
        this.linkUrl = null
        this.linkMenuIsActive = false
      },
      setLinkUrl(command, url) {
        command({ href: url })
        this.hideLinkMenu()
      },
    },
    mounted () {
      eventBus.$on('focus-input-' + this.obj.attributes.id, this.handleFocusInput)
    },
    beforeDestroy() {
      this.editor.destroy()
      eventBus.$off('focus-input-' + this.obj.attributes.id, this.handleFocusInput)
    },
  }
</script>

<style lang="scss" scoped>
  .text-editor {
    margin-bottom: 1rem;
  }

  .menubar {
    position: sticky;
    top: 0;
    z-index: 50;
    background: $white;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  .menubar-button {
    display: inline-flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    line-height: 1;
    cursor: pointer;
    min-width: 35px;
    height: 35px;
    border-left: 1px solid $light-grey;
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    margin-bottom: 0.5rem;
    transition: background-color $speed $easing;

    &:last-child {
      border-right: 1px solid $light-grey;
    }

    &:hover, &:focus, &.is-active {
      background-color: $light-grey;
    }
  }

  .menubar-form {
    display: inline-flex;
    height: 35px;
    margin-bottom: 0.5rem;
  }

  .menubar-input {
    padding: 5px;
    border: 1px solid $light-grey;

    &:focus, &:active {
      border-color: $medium-light-grey;
      outline: none;
    }
  }
</style>