<!--LhOpeninghours-->
<!--attributes-->
<!-- days (array) with name of days -->
<!-- open (object) with all the opening hours-->
<!--    example: 'open': {
                    'AM': ['true', 'true', 'true', 'true', 'true', 'false', 'false'],
                    'PM': ['true', 'false', 'true', 'true', 'true', 'false', 'false'],
                    'Custom': ['8u-15u', '8u-15u', '8u-15u', '8u-15u', '8u-15u', 'GENERAL_CLOSED', 'GENERAL_CLOSED'],
                  }-->
<!-- hideTitle (bool)  -->

<template>
  <div :class="[checkClasses, 'lh-opening-hours']" :style="checkStyles">
    <div class="lh-opening-hours__wrapper container">
      <b-row :class="['text-center lh-opening-hours__row', {'flex-nowrap': !obj.attributes.responsive}]">
        <b-col v-if="!obj.attributes.hideTitle">
          <br>
          <div v-for="(hours, key) in obj.attributes.open">{{ $t(key) }}</div>
        </b-col>
        <b-col v-for="(day, dayIndex) in obj.attributes.days" class="">
          <div>{{ $t(day) }}</div>
          <template v-for="hour in obj.attributes.open">
            <div v-for="(value, index) in hour" v-if="index === dayIndex">
              <font-awesome-icon v-if="isBoolean(value)" icon="check" :class="value"></font-awesome-icon>
            </div>
          </template>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LhBusinessHoursCompact',
  components: {},
  props: {
    obj: {
      type: Object
    },
    isInput: {
      type: Boolean
    }
  },
  data () {
    return {
    }
  },
  methods: {
    isBoolean (item) {
      return item ? item.toLowerCase().trim() === 'true' || item.toLowerCase().trim() === 'false'
          || item === true || item === false : false
    }
  },
  created () {
  }
}
</script>

<style scoped lang="scss">
.lh-opening-hours {
  padding: 0.5rem;
  overflow: auto;

  &__row {
    .col {
      flex-shrink: 1;
      flex-grow: 0;
      flex-basis: 50px;

      &:not(:first-child) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

.false {
  visibility: hidden;
}
</style>
