<template>
    <iframe
        :id="obj.key"
        :style="checkStyles"
        :class="checkClasses"
        :src="obj.attributes.src"
        frameBorder='0'>
    </iframe>
</template>

<script>
    export default {
        name: 'LhIframe',
        props: {
            obj: {
                type: Object
            }
        },
        data () {
            return {}
        },
        computed: {},
        methods: {},
        watch: {},
        mounted () {
            var frame = document.getElementById(this.obj.key)
            frame.onload = function() {
                var margin = 50
                frame.style.height = (margin+frame.contentWindow.document.body.scrollHeight) + 'px'
            }

            if(this.obj.attributes.data){
                if(this.obj.attributes.rawData === true) {
                    var content = this.obj.attributes.data
                } else {
                    var content = '<html><head><title></title></head><body>' + this.obj.attributes.data + '</body></html>'
                }
                var doc = frame.contentWindow.document
                doc.open()
                doc.write(content)
                doc.close()
            }
        }
    }
</script>

<style lang="scss">
    iframe {
        width: 100%;
        height: 100%;
        border: 0;
    }
</style>