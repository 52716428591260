<template>
  <div class="title">
    <b-form-select v-model="selected" @change="handleChangeLocal">
      <option
          v-for="(value) in obj.attributes.content"
          :key="value"
          :label="value"
          :value="value"
      ></option>
    </b-form-select>
    <component v-for="item in obj.children" :is="item.type" :key="item.key" :obj="item"></component>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'LanguageSelector',
    components: {},
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        selected: 1,
      }
    },
    computed: {
      ...mapState([
        'translations'
      ])
    },
    methods: {
      handleChangeLocal (val) {
        this.$store.dispatch('changeLanguage', val).then(response => {
          this.$i18n.locale = val
          this.$validator.localize(val)
          console.log(this.$i18n)
        })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
