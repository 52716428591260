<template>
  <div class="lang" v-if="translationEditMode && keyInStore" @click.prevent="openModal">
    <font-awesome-icon :icon="['fas', 'globe']" class="lang__icon press-animation"></font-awesome-icon>
    <b-modal
        :id="'trModal' + obj.key + obj.parentKey"
        ref="translationModal"
        title="Edit Translations"
        hide-footer
    >
      <template v-for="(lang) in keyInStore">
        <div v-for="(value, key) in lang">
          <h3>{{ key }}</h3>
          <b-form-group
              :label="value.key"
              label-for="name-input"
          >
            <b-form-input
                :id="value.key"
                required
                :value="value.value"
                @keyup.enter="handleSubmit(value, key, $event)"
            ></b-form-input>
          </b-form-group>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { eventBus } from '@/services/event-bus'

export default {
  name: 'LhEditTranslation',
  data () {
    return {
      keyInStore: null,
    }
  },
  props: {
    obj: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      translationEditMode: 'translationEditMode'
    }),
    ...mapGetters({
      getKey: 'getTranslationKey',
      getKeyByLang: 'getTranslationKeyByLang'
    })
  },
  methods: {
    openModal (e) {
      console.log(e)
      e.stopPropagation()
      this.$bvModal.show('trModal' + this.obj.key + this.obj.parentKey)
    },
    handleSubmit ({key, value}, lang, event) {
      event.preventDefault()
      let input = event.target.value
      let keyFound = this.getKeyByLang({lang: lang, key: key})

      if (input !== value) {
        let payload = {
          key: key,
          language: lang,
          translation: input,
          add: !keyFound
        }
        this.$store.dispatch('submitTranslation', payload).then((response) => {
          // this.$bvModal.hide('trModal' + this.obj.key + this.obj.parentKey)
          let data = {
            title: 'Translation updated successfully',
            message: `Translation key "${key}" was successfully updated to "${input}" for language "${lang}". ${response.data.message}`
          }
          eventBus.$emit('show-toast', data)
          if (response.data.url) {
            console.log('update translations response: ', response.data)
            this.$store.dispatch('getTranslations', response.data.url).then(() => this.keyInStore = this.getKey(this.obj.key))
          }
        })
      }
    }
  },
  mounted () {
    this.keyInStore = this.getKey(this.obj.key)
  }
}
</script>

<style lang="scss" scoped>
.lang {
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;

  &__icon {
    font-size: 18px;
    color: $green;
  }
}
</style>
