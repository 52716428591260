<template>
  <b-row class="LhRow"
         v-bind="obj.attributes"
         :class="checkClasses"
         :style="checkStyles"
         :align-v="obj.attributes.alignV"
         :align-h="obj.attributes.alignH"
  >
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
  </b-row>
</template>

<script>
  export default {
    name: 'LhRow',
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>

