<template>
  <div class="lh-filter" :style="checkStyles">
    <form @submit.prevent>
      <div class="inputs-filter">
        <component
            v-for="child in inputs"
            :is="child.type"
            :obj="child"
            :type="child.attributes.type"
            :label="$t(child.attributes.content)"
            :placeholder="$t(child.attributes.content)"
            :name="child.attributes.name"
            :key="child.key"
            @handleChange="handleChange"
            v-model="items[child.key]"
        ></component>
      </div>
      <div class="sticky-buttons">
        <component
            v-for="child in buttons"
            :is="child.type"
            :obj="child"
            :key="child.key"
            class="sticky-buttons"
        ></component>
      </div>
      <div>
        <component v-for="child in othersComponents" :is="child.type" :obj="child" :key="child.key"></component>
      </div>
    </form>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'LhTableFilter',
    components: {},
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        items: [],
        errorMessage: {
          attributes: {
            content: ''
          }
        }
      }
    },
    computed: {
      ...mapState({
        filterItems: 'filterData',
        tableFilterShow: 'tableFilterShow'
      }),
      inputs () {
        return this.obj.children.filter(child => { return child.type === 'LhInput' || child.type === 'lh-input'})
      },
      buttons () {
        return this.obj.children.filter(child => { return child.type === 'LhButton' || child.type === 'lh-button'})
      },
      othersComponents () {
        return this.obj.children.filter(child => { return child.type !== 'LhInput' && child.type !== 'lh-input' && child.type !== 'LhButton' && child.type !== 'lh-button'})
      }
    },
    methods: {
      fillInputs () {
        for (const child of this.obj.children) {
          if (child.type === 'lh-input' || child.type === 'LhInput') {
            if (this.filterItems[child.key]) {
              this.items[child.key] = this.filterItems[child.key]
            }
            // this.items[child.key] = child.attributes.value;
          }
        }
      },
      handleChange () {
        let payload = {
          key: this.obj.attributes.target,
          inputs: {}
        }
        for (const key in this.items) {
          if (
              this.items.hasOwnProperty(key) &&
              (this.items[key] !== undefined && this.items[key] !== '')
          ) {
            payload.inputs[key] = this.items[key]
          }
        }
        this.$store.commit('setFilterDataByKey', payload)
      }
    },
    created () {
      this.fillInputs()
    },
    watch: {
      subject (val) {
        console.log(val)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .lh-filter {
  }

  .inputs-filter{
    max-height: calc(70 * var(--vh-unit));
    overflow-y: auto;
    overflow-x: hidden;
  }

  .sticky-buttons {
    margin-top: 20px;
  }
</style>
