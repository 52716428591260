<template>
  <div class="grid-card" :style="checkStyles" :class="checkClasses">
    <div :class="['table-wrapper', {isMobile: isMobile}]">
      <div class="grid-card-table noselect">
        <!-- table header -->
        <template v-for="(row, index) in obj.children" class="row-wrapper">
          <component
              v-if="row.children[0] && row.children[0].type !== 'LhGridHeader'"
              :is="row.type"
              :obj="row"
              :browseKey="obj.key"
              :index="index"
              :key="row.key"
              :cardsView="isMobile ? false : true"
          ></component>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'

  export default {
    name: 'LhGridCards',
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        browseSetting: {},
        headers: [],
      }
    },
    computed: {
      ...mapGetters({
        browses: 'getBrowseSettings',
      }),
      browseSettingStore () {
        let setting = this.$store.getters.getBrowseSettingByKey(this.obj.key)
        return setting ? setting : null
      },
      headersStore: {
        get () {
          return this.$store.getters.getBrowseSettingByKey(this.obj.key)
            ? this.$store.getters.getBrowseSettingByKey(this.obj.key).headers
            : null
        },
        set (val) {
          this.headers = val
        }
      },
      rows () {
        let rows = []
        this.obj.children.forEach((child, index) => {
          if (child.type.toLowerCase() === 'lhgridrow' && child.children[0].type.toLowerCase() !== 'lhgridheader') {
            rows.push(this.browseSetting.key + index)
          }
        })
        return rows
      }
    },
    methods: {
      findHeaders () {
        let headersArr = []
        if (this.obj.children[0]) {
          let index = 0
          //first child -> headers
          for (const child of this.obj.children[0].children) {
            index++

            headersArr.push({
              name: child.attributes.content,
              checked: true,
              key: child.key,
              type: child.attributes.cellType,
              sort: 0,
              sorting: false,
              showMobile: child.attributes.showMobile,
              disableSort: child.attributes.disableSort,
              hidden: this.obj.attributes.hideHeaders
            })
          }
        }
        this.headers = headersArr
      },
      setHeadersFromStore () {
        if (this.browseSettingStore) {
          this.headers = this.browseSettingStore.headers
        } else {
          this.findHeaders()
        }
      },
      getSettings () {
        this.$store.dispatch('getTableSettings')
      }
    },
    created () {
      this.setHeadersFromStore()

      this.browseSetting = {
        //content: this.obj,
        headers: this.headers,
        key: this.obj.key
      }
      this.$store.commit('addBrowseSetting', this.browseSetting)
    }
  }
</script>

<style lang="scss" scoped>
  .grid-card {
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    transition: all 1s;
    width: 100%;
    padding-top: 20px;
    position: relative;

    .table-wrapper {
      margin: 0;
      width: 100%;
    }
  }

  .grid-card-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .isMobile {
    .grid-card-table {
      flex: 1 1 auto;
      text-align: left;
      border-collapse: collapse;
      width: 100%;
      overflow-x: visible;
      display: table;
    }
  }
</style>
