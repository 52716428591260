<template>
  <div class="lh-card-item" :class="checkClasses" :style="checkStyles" v-tooltip="checkTooltip">
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
  </div>
</template>

<script>
export default {
  name: "LhCardItem",
  props: {
    obj: {
      type: Object
    }
  } 
};
</script>

