<!--LhOpeninghours-->
<!--attributes-->
<!-- direction -> 'vertical' or 'horizontal' -->
<!-- days (array) with name of days -->
<!-- open (object) with all the opening hours-->
<!--    example: 'open': {
                    'AM': ['true', 'true', 'true', 'true', 'true', 'false', 'false'],
                    'PM': ['true', 'false', 'true', 'true', 'true', 'false', 'false'],
                    'Custom': ['8u-15u', '8u-15u', '8u-15u', '8u-15u', '8u-15u', 'GENERAL_CLOSED', 'GENERAL_CLOSED'],
                  }-->
<!-- hideTitle (bool)  -->

<template>
  <div :class="[checkClasses, 'lh-opening-hours', {'vertical-container': obj.attributes.direction.toLowerCase().trim() == 'vertical'}]" :style="checkStyles">
    <table v-if="obj.attributes.direction == 'vertical' || isMobile" class="lh-opening-hours__table-wrapper">
      <tr v-if="!obj.attributes.hideTitle">
        <td v-if="!obj.attributes.hideTitle"></td>
        <td v-for="(value, key) in obj.attributes.value" class="lh-opening-hours__table-wrapper--vertical" :key="'header' + key">{{$t(key)}}</td>
      </tr>
      <tr v-for="(day, index) in obj.attributes.days" class="lh-opening-hours__row-wrapper" :key="'vertical-row' + index">
        <td class="lh-opening-hours__row-wrapper--vertical bold">
          {{$t(day)}}
        </td>
        <td v-for="(hour, key) in obj.attributes.value" :key="'vertical-cell' + key">
          <template v-if="hour[index] != undefined">
            <template v-if="isInput">
              <b-form-checkbox
                  v-if="isBoolean(hour[index])" type="checkbox"
                  @input="handleCheckboxChange(key, index)"
                  :checked="(hour[index]).toLowerCase().trim() === 'true'"
                  :disabled="disabled"
              ></b-form-checkbox>
              <b-form-input
                  v-else type="text" v-model="innerValue[key][index]" @blur="handleTextBlur"
              ></b-form-input>
            </template>
            <template v-else>
              <font-awesome-icon v-if="isBoolean(hour[index])" icon="check"
                                 :class="hour[index]"></font-awesome-icon>
              <span v-else>{{$t(hour[index])}}</span>
            </template>
          </template>
        </td>
      </tr>
    </table>
    <table v-else class="lh-opening-hours__table-wrapper lh-opening-hours__table-wrapper--horizontal">
      <thead>
      <th v-if="!obj.attributes.hideTitle"></th>
      <th v-for="day in obj.attributes.days" class="lh-opening-hours__table-wrapper__day">{{$t(day)}}</th>
      </thead>
      <tr v-for="(hours, key) in obj.attributes.value" class="lh-opening-hours__hour-wrapper">
        <td v-if="!obj.attributes.hideTitle" class="lh-opening-hours__hour-wrapper--title select-all">
          <b-form-checkbox
              class="select-all-checkbox"
              type="checkbox"
              :checked="multiSelectCheckedState[key]"
              :disabled="disabled"
              @change="handleMultiCheckboxChange($event, key)"
          >
          </b-form-checkbox>
          {{$t(key)}}
        </td>
        <td v-for="(hour, index) in hours" class="lh-opening-hours__days-wrapper__day">
          <template v-if="isInput">
            <b-form-checkbox
                v-if="isBoolean(hour)" type="checkbox"
                :checked="(hour).toLowerCase().trim() === 'true' || hour === true"
                :disabled="disabled"
                @input="handleCheckboxChange(key, index)"
            >
            </b-form-checkbox>
            <b-form-input
                v-else type="text" v-model="innerValue[key][index]" @blur="handleTextBlur"
            ></b-form-input>
          </template>
          <template v-else>
            <font-awesome-icon v-if="isBoolean(hour)" icon="check"
                               :class="hour"></font-awesome-icon>
            <span v-else>{{$t(hour)}}</span>
          </template>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'LhBusinessHours',
    components: {},
    props: {
      obj: {
        type: Object
      },
      isInput: {
        type: Boolean
      }
    },
    data() {
      return {
        innerValue: {},
        disabled: this.obj.attributes.disabled === true || this.obj.attributes.disabled === 'true',
        multiSelectCheckedState: {
          'AM': false,
          'PM': false
        },
        handleChanges: true
      }
    },
    computed: {
      ...mapState({
        isMobile: 'isMobile'
      })
    },
    methods: {
      handleCheckboxChange(hour, index) {
        if (this.innerValue && this.innerValue[hour]) {
          if (typeof this.innerValue[hour][index] === 'boolean') {
            this.$set(this.innerValue[hour], index, this.innerValue[hour][index] == true ? false : true)
          } else {
            this.$set(this.innerValue[hour], index, this.innerValue[hour][index].toLowerCase().trim() == 'true' ? 'false' : 'true')
          }
        }
        this.updateMultiCheckboxChange()
        this.$emit('handleChange', this.innerValue)
      },
      handleMultiCheckboxChange(event, index) {
        this.multiSelectCheckedState[index] = event
        console.log(this.innerValue, event, index)
        if(this.innerValue){
          for (const [key, values] of Object.entries(this.innerValue)) {
            if(index == key) {
              for (const [i, val] of Object.entries(values)) {
                if (typeof this.obj.attributes.value[index][i] === 'boolean') {
                  this.obj.attributes.value[index][i] = event ? true : false
                } else {
                  this.obj.attributes.value[index][i] = event ? 'true' : 'false'
                }
              }
            }
          }
          this.updateMultiCheckboxChange()
        }
      },
      updateMultiCheckboxChange() {
        if(this.innerValue){
          for (const [key, value] of Object.entries(this.innerValue)) {
            this.multiSelectCheckedState[key] = value.find( (state) => state === 'false' ) ? false : true
          }
        }
      },
      handleTextBlur() {
        this.$emit('handleChange', this.innerValue)
      },
      isBoolean(item) {
        return item ? item.toLowerCase().trim() === 'true' || item.toLowerCase().trim() === 'false'
          || item === true || item === false : false
      }
    },
    created () {
      if (this.isInput && this.obj.attributes.value) {
        this.innerValue = JSON.parse(JSON.stringify(this.obj.attributes.value))
      }

      this.updateMultiCheckboxChange()
    }
  }
</script>

<style scoped lang="scss">
  .lh-opening-hours {
    border: 1px solid $light-grey;
    padding: 1rem;
    overflow: auto;

    &.vertical-container {
      display: inline-block;
    }

    &__table-wrapper {

      &--horizontal {
        width: 100%;
      }

      &__day {
        min-width: 50px;
      }

      th:not(:first-child) {
        border-bottom: 1px solid $light-grey;
      }

      td, th {
        padding: 5px;
        text-align: center;

        input[type=text] {
          max-width: 85px;
        }
      }

      th {
        padding-bottom: 10px;
      }
    }

    &__row-wrapper, &__hour-wrapper {

      &--title {
        padding-right: 1rem !important;
      }
      .true {
        color: $black;
        width: 25px;
        height: 25px;
        padding: 5px;
        vertical-align: bottom;
      }

      .false {
        visibility: hidden;
      }

      .lh-opening-hours__days-wrapper__day {
        //background: $light-grey;
      }

      &--vertical {
        text-align: start !important;
        font-weight: 700;
        padding-right: 2rem !important;
      }
    }

    &__hour-wrapper:first-of-type {
      td {
        padding-top: 10px;
      }
    }

    .select-all {
      text-align: center;
      &-checkbox {
        padding-bottom: 0.5rem;
      }
    }
  }
</style>
