<template>
  <img
      class="logo"
      :style="checkStyles"
      v-on="allEvents"
      :src="parseSrc"
      :title="checkTooltip"
  />
</template>

<script>
  import { mapState } from 'vuex'
  import mixinFunctions from '@/mixin/mixinFunctions'
  import confirm from '@/mixin/confirm'
  export default {
    name: 'Logo',
    mixins: [mixinFunctions, confirm],
    props: {
      obj: {
        type: Object
      }
    },
    computed: {
      ...mapState({
        collapsed: 'sidebarCollapse'
      }),
      parseSrc: function () {
        if (this.collapsed && this.obj.attributes.collapse-src) {
          return this.obj.attributes.collapse-src
        } else {
          return this.obj.attributes.src
        }
      }
    },
    methods: {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  img {
    display: block;
    max-height: 125px;
    height: auto;
  }
</style>
