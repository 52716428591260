<!-- == attributes ==-->
<!-- priority (string) determines color of tag-->
<!-- content (string) text in tags-->
<!-- dueDate (bool) to show calendar icon-->
<!-- removeCloseButton (bool) hide close button-->

<template>
  <div class="lh-tag" :class="checkClasses" :style="checkStyles" v-tooltip="checkTooltip">
    <div
        :class="['lh-tag__tag', 'lh-tag__tag--' + checkPriority('CLASS'),
        {'lh-tag__tag--padding-right': obj.attributes.removeCloseButton && !obj.attributes.priority}]"
        :style="checkPriority('STYLE')"
    >
      <span :class="{'mr-2': obj.attributes.dueDate || !obj.attributes.removeCloseButton}">
        {{$t(obj.attributes.content)}}
      </span>
      <div class="lh-tag__button" v-if="obj.attributes.dueDate">
        <font-awesome-icon
            :icon="['fas', 'calendar-alt']"
        ></font-awesome-icon>
      </div>
      <div v-if="!obj.attributes.removeCloseButton" v-on="allEvents"
            class="lh-tag__button lh-tag__button--close press-animation press-animation--color">
        <font-awesome-icon class="" :icon="['fal', 'times']"></font-awesome-icon>
      </div>
    </div>
  </div>
</template>


<script>
  import mixinFunctions from '@/mixin/mixinFunctions'
  import confirm from '@/mixin/confirm'

  export default {
    name: 'LhTags',
    mixins: [mixinFunctions, confirm],
    props: {
      obj: {
        type: Object
      }
    },
    methods: {
      checkPriority(target) {
        let color = ""
        let priority = this.obj && this.obj.attributes && this.obj.attributes.priority
          ? this.obj.attributes.priority : ""
        if (target == 'CLASS') {
          if (['low', 'medium', 'high'].includes(priority.toLowerCase().trim())) {
            color = priority
          }
        } else if (target == 'STYLE') {
          if (priority && !['low', 'medium', 'high'].includes(priority.toLowerCase().trim())) {
            if (priority.includes('#')) {
              color = 'background-color:' + priority
            } else {
              color = 'background-color: #' + priority
            }
          }
        }
        return color
      }
    },
  }
</script>

<style lang="scss">
  .lh-tag {
    display: inline-block;

    &__tag {
      margin: 0.3rem 0.5rem 0.3rem 0;
      padding: 0 0 0 1rem;
      border-radius: 1.75rem;
      color: $white;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-height: 1.75rem;
      background-color: $medium-grey;

      &--low {
        background-color: $priority-color-low;
      }

      &--medium {
        background-color: $priority-color-medium;
      }

      &--high {
        background-color: $priority-color-high;
      }

      &--padding-right {
        padding-right: 1rem;
      }

      svg {
        font-size: 13px;
        vertical-align: 0;
      }
    }

    &__button {
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 1.75rem;
      border-top-right-radius: 1.75rem;
      border-bottom-right-radius: 1.75rem;
      border-left: 1px solid rgba($white, 0.2);
    }
  }

</style>

