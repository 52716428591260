<template>
  <div>
    <pre>{{ valueString }}</pre>
  </div>
</template>
<script>
  export default {
    name: 'raw-displayer',
    props: {
      obj: {
        type: Object
      }
    },
    computed: {
      valueString () {
        return JSON.stringify(this.obj, null, 2)
      },
      title () {
        return this.obj.attributes.content
      }
    }
  }
</script>
<style scoped lang="scss">
  pre {
    text-align: start;
  }
</style>
