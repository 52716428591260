<template>
  <ul class="nav-list" :class="checkClasses" :style="checkStyles" v-tooltip="checkTooltip">
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"
               :collapseOthers="obj.attributes.collapseSubNav ? true : false"
               :parentNavList="parentNavList"
               :popover="popover"
    ></component>
  </ul>
</template>

<script>
export default {
  name: 'LhNavList',
  props: {
    obj: {
      type: Object
    },
    parentNavList: {
      type: Array
    },
    popover: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
#sidebarCollapse {
  > .nav-list {
    > li {
      &.collapsed {
        max-width: $sidebar-min-width;
      }
    }
  }
}

ul {
  list-style: none;
  padding-left: 0;
  padding-top: 1rem;
  width: 100%;

  &.inside-mobile {
    width: 100%;
    padding-right: 1rem;
  }

  li {
    padding-left: 20px;
    padding-right: 15px;

    .collapsed & {
      padding-left: 0;
      padding-right: 0;
    }
  }

  ul {
    padding-top: 0;

    li {
      padding-left: 0;
      padding-right: 0;
    }

    ul {
      padding-left: 1rem;
      //font-style: italic;
    }
  }
}
</style>
