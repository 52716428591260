export let findTarget = function (obj, targetKey) {
  let foundElement = {}
  let elementPath = []

  let isEmpty = function (obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false
    }
    return true
  }

  let findObj = function (element, target) {
    if (element.key === target) {
      foundElement = element
    } else if (element.children && element.children.length > 0) {
      for (let [index, child] of element.children.entries()) {
        if (isEmpty(foundElement)) {
          elementPath.push(index)
          findObj(child, target)
          if (isEmpty(foundElement)) {
            elementPath.splice(-1, 1)
          }
        }
      }
    }
    return {target: foundElement, path: elementPath}
  }
  return findObj(obj, targetKey)
}

export let replaceTargetObj = function (result, targetObj, Vue, popup) {
  let target = findTarget(targetObj, result.key)
  if (target && target.path) {
    let element = targetObj
    for (let index = 0; index < target.path.length - 1; index++) {
      const path = target.path[index]
      element = element.children[path]
    }
    if (popup.content) {
      //popup
      let index = state.popups.indexOf(popup)
      Vue.set(
          state.popups[index].children,
          target.path[target.path.length - 1],
          payload.page
      )
    } else {
      //basepage
      Vue.set(
          state.mainJSON.children,
          target.path[target.path.length - 1],
          payload.page
      )
    }
  }
}
