<template>
  <div class="lh-table-settings" v-if="!isMobile" :style="checkStyles">
    <lh-collapse :icons="[['fas', 'cog'], ['fas', 'cog']]" :transparent="true">
        <draggable
          class="list-columns"
          tag="ul"
          v-model="headers"
          v-bind="dragOptions"
          @start="drag = true"
          @end="handleSettingsChange()"
      >
          <li class="list-column-item" v-for="element in headers" :key="element.key" v-if="element.type !== 'button'">
            <b-form-checkbox v-model="element.checked" :name="element.name" :id="element.name"
                             @change="handleSettingsChange()" switch>
              {{ $t(element.name) }}
            </b-form-checkbox>
          </li>
      </draggable>
      <template v-slot:footer>
          <template v-for="button in headers" v-if="button.type === 'button' && button.action">
            <component :is="button.type" :obj="button" :key="button.key"></component>
            {{ button }}
          </template>
      </template>
    </lh-collapse>
    <transition-group type="transition" :name="!drag ? 'flip-list' : null">

    </transition-group>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import { mapState } from 'vuex'

  export default {
    name: 'TableSettings',
    display: 'Transitions',
    components: {
      draggable
    },
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        drag: false,
        activeNames: ['1'],
        viewTypes: {
          FULLPAGE: 'Full page',
          POPUP: 'Popup',
          SIDEFORM: 'Side Form'
        },
        collapsed: true
      }
    },
    computed: {
      ...mapState({
        isMobile: 'isMobile'
      }),
      dragOptions () {
        return {
          drag: false,
          animation: 200,
          group: 'description',
          disabled: false,
          ghostClass: 'ghost'
        }
      },
      headers: {
        get () {
          return this.$store.getters.getBrowseSettingByKey(this.obj.attributes.target)
              ? this.$store.getters.getBrowseSettingByKey(this.obj.attributes.target).headers
              : []
        },
        set (val) {
          this.$store.commit('setBrowseSettingByKey', {
            key: this.obj.attributes.target,
            payload: {
              headers: val
            }
          })
        }
      }
    },
    methods: {
      handleSettingsChange () {
        let data = {
          target: this.obj.attributes.target,
          group: this.obj.attributes.group,
          headers: this.headers
        }

        this.$store.dispatch("saveTableSettings", data).then(()=>{
          // this.$store.commit("")
          console.log('saved browse settings')
        })
      }
    }
  }
</script>

<style lang="scss">
  // not scoped: used in other places
  .lh-table-settings {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 0.75rem;
    margin-left: 1rem;

    button.button {
      justify-content: center;
      svg {
        margin: 0;
      }
    }
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .lh-selectType {
    text-align: left;
  }

  .list-columns {
    display: block;
    min-height: 20px;
    list-style: none;
    text-align: left;
    padding: 0 0.5rem;
  }

  .list-column-item {
    cursor: move;
    border-radius: $border-radius;
    border-bottom: 1px solid $light-grey;
    padding: 1em 0.5em;
    min-width: 350px;

    label {
      padding: 0 1em;
    }
  }

  .collapse {
    /*display: block !important;*/
    width: 250px;
  }

  .lh-collapse {
    right: 0.5em;
    &-wrapper {
    }
  }
</style>
