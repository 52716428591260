<template>
  <span :class="['lh-icon', checkClasses]" :style="checkStyles" v-tooltip="checkTooltip" v-on="allEvents" >
    <font-awesome-layers>
      <font-awesome-icon
          :icon="setIcon"
          v-on="allEvents"
          v-bind="obj.attributes.transform"
      />
    </font-awesome-layers>
    <component v-for="item in obj.children" :is="item.type" :key="item.key" :obj="item"></component>
  </span>
</template>

<script>
  import mixinFunctions from '@/mixin/mixinFunctions'
  import confirm from '@/mixin/confirm'

  export default {
    name: 'LhIcon',
    mixins: [mixinFunctions, confirm],
    components: {},
    props: {
      obj: {
        type: Object
      }
    },
    computed: {
      setIcon () {
        return this.obj.attributes.icon ? this.obj.attributes.icon : 'circle'
      },
    }
  }
</script>

