<template>
  <div class="popup-footer" v-show="tabActive" :style="checkStyles">
    <template v-for="child in obj.children">
      <lh-button v-if="obj.attributes.showCancelButton" :obj="cancelButton" @click.native="emitAction('closePopup')" class="mr-2"></lh-button>
      <component :is="child.type" :key="child.key" :obj="child"></component>
    </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'PopupFooter',
    props: {
      obj: {
        type: Object,
        default () {
          return {
            attributes: {
              content: ''
            }
          }
        }
      }
    },
    data () {
      return {
        activeTab: undefined,
        cancelButton: {
          'key': 'cancel-popup-button',
          'type': 'LhButton',
          'actions': [],
          'attributes': {
            'content': 'GENERAL_CANCEL',
            'name': 'cancel-popup-button',
            'class': 'btn-cancel'
          },
          'styles': [],
          'children': []
        }
      }
    },
    computed: {
      ...mapState({
        currentTab: 'activeTab'
      }),
      tabActive () {
        if (this.activeTab !== undefined) {
          return  this.activeTab.key === this.obj.attributes.target
        } else {
          return true
        }
      }
    },
    methods: {
      handleTabChange (payload) {
        if (payload.target === this.obj.attributes.parent) {
          this.activeTab = payload
        }
      },
      emitAction (action) {
        this.$emit(action)
      }
    },
    watch: {
      currentTab (val) {
        if (val.target === this.obj.attributes.parent) {
          this.activeTab = val
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .popup-footer {
    margin-top: auto;
    position: sticky;
    bottom: 0;
    min-height: $popup-footer-height;
    background: $popup-footer-bg;
    display: flex;
    align-items: center;
    padding: 0 0.75rem;
    justify-content: flex-end;
    .button-wrapper {
      padding: 0;
    }
  }
</style>
