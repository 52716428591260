<template>
  <div :class="['page-header', {sticky: isPageScrolled && isMobile && collapsed}, checkClasses]" v-if="isMobile"
       id="pageHeader" :style="checkStyles" v-tooltip="checkTooltip">
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
  </div>
</template>

<script>
  import debounce from 'lodash/debounce'
  import { mapState } from 'vuex'

  export default {
    name: 'LhPageHeader',
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        handleDebouncedScroll: null,
        isPageScrolled: false
      }
    },
    computed: {
      ...mapState({
        collapsed: 'sidebarCollapse',
        isMobile: 'isMobile'
      })
    },
    methods: {
      handleScroll (event) {
        let page = document.scrollingElement
        // let page = document.getElementById('mainPage')
        this.isPageScrolled = page.scrollTop > 0
      }
    },
    watch: {},
    beforeMount () {
      this.handleDebouncedScroll = debounce(this.handleScroll, 50)
      window.addEventListener('scroll', this.handleDebouncedScroll, true)
    },
    beforeDestroy () {
      window.removeEventListener('scroll', this.handleDebouncedScroll, true)
    }
  }
</script>

<style scoped lang="scss">
  .page-header {
    top: 0;
    z-index: 190;
    padding: 10px 0;
    margin-bottom: 1rem;
    background: $white;
    transition: box-shadow 200ms;

    &.sticky {
      position: sticky;
      box-shadow: 0 3px 4px -4px rgba(0, 0, 0, 0.4);
    }

    img {
      max-width: 160px;
      padding: 0;
    }
  }
</style>