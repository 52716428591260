<template>
  <div class="register">
    <div>
      <h2>Register</h2>
      <form @submit.prevent="validateBeforeSubmit">
        <div>
          <label class="label">Name</label>
          <input
              type="text"
              name="name"
              id="name"
              v-validate="'required|alpha'"
              :class="{'input': true, 'is-danger': errors.has('name') }"
              v-model="name"
              placeholder="name"
          >
          <template v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</template>
        </div>

        <div>
          <label class="label">Age</label>
          <input
              type="text"
              name="age"
              id="age"
              v-validate="'required|decimal|min_value:3'"
              :class="{'input': true, 'is-danger': errors.has('age') }"
              v-model="age"
              placeholder="age"
          >
          <template v-show="errors.has('age')" class="help is-danger">{{ errors.first('age') }}</template>
        </div>

        <div>
          <label class="label">E-mail</label>
          <input
              type="email"
              name="email"
              id="email"
              v-validate="'required|email'"
              :class="{'input': true, 'is-danger': errors.has('email') }"
              v-model="email"
              placeholder="e-mail"
          >
          <template v-show="errors.has('email')" class="help is-danger">{{ errors.first('email') }}</template>
        </div>

        <div>
          <label class="label">Password</label>
          <input
              type="password"
              name="password"
              id="password"
              v-validate="'required'"
              :class="{'input': true, 'is-danger': errors.has('password') }"
              v-model="password"
              placeholder="password"
          >
          <template
              v-show="errors.has('password')"
              class="help is-danger"
          >{{ errors.first('password') }}
          </template>
        </div>
        <div>
          <label class="label">Confirm password</label>
          <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              v-validate="'required|confirmed:password'"
              :class="{'input': true, 'is-danger': errors.has('confirmPassword') }"
              v-model="confirmPassword"
              placeholder="confirm password"
          >
          <template
              v-show="errors.has('confirmPassword')"
              class="help is-danger"
          >{{ errors.first('confirmPassword') }}
          </template>
        </div>
        <div>
          <input
              type="checkbox"
              name="terms"
              v-validate="'required'"
              :class="{'input': true, 'is-danger': errors.has('terms') }"
              id="terms"
              v-model="terms"
          >
          <label for="terms">Accept Terms of Use</label>
          <template
              v-show="errors.has('terms')"
              class="help is-danger"
          >{{ errors.first('terms') }}
          </template>
        </div>

        <div>
          <button type="submit" class="button c-register-form__register">Create Account</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

  export default {
    data () {
      return {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        age: null,
        terms: false
      }
    },
    methods: {
      register () {
        const formData = {
          name: this.name,
          email: this.email,
          password: this.password,
          admin: false,
          terms: this.terms
        }
        console.log(formData)
        this.$store.dispatch('register', formData).then(response => {
          this.$router.push({ name: 'home' })
        })
      },
      lostFocus (input) {
        input.$touch()
      },
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.register()
            return
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .input.invalid input {
    border: 1px solid red;
  }

  input {
    margin: 10px;
    width: 200px;
    height: 20px;
    line-height: 20px;
  }

  input[type="checkbox"] {
    margin: 0;
    width: 13px;
    height: 13px;
  }
</style>
