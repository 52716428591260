<template>
  <div v-if="obj.attributes.type == 'date'">
    <div :class="['date-input-wrapper', {alignRight: attr.alignRight}]">
      <input
          type="text"
          :value="singleDate"
          @blur="handleTypeDate($event, 'singleDatePicker' + obj.key)"
          @keyup.enter="handleTypeDate($event, 'singleDatePicker' + obj.key)"
          @click="openCalendar('singleDatePicker' + obj.key)"
          class="form-control"
          :state="state"
          :class="error"
          :placeholder="obj.attributes.placeholder ? $t(obj.attributes.placeholder) : 'dd/mm/jjjj'"
          :disabled="checkDisabled"
          :ref="obj.attributes.id"
      />
      <span :class="['date-check', {showcheck: hasValue}]"><font-awesome-icon
          icon="check"></font-awesome-icon>
      </span>
    </div>
    <Datepicker
        :id="obj.attributes.id"
        v-bind="attr"
        input-class="date-picker-input hidden"
        :bootstrapStyling="true"
        format="dd/MM/yyyy"
        output-format="dd/MM/yyyy"
        @selected="handleDateChange"
        v-on="allEvents"
        :open-date="singleDate ? convertToDate(singleDate) : null"
        :placeholder="obj.attributes.placeholder ? $t(obj.attributes.placeholder) : 'dd/mm/jjjj'"
        :initial-view="obj.attributes.yearFirst ? 'year' : 'day'"
        :language="languages[localeStore]"
        :ref="'singleDatePicker' + obj.key"
        :monday-first="true"
        :disabled-dates="disabledDates"
    ></Datepicker>
  </div>
  <div v-else-if="obj.attributes.type == 'date-button'">
    <div :class="['date-input-wrapper', {alignRight: attr.alignRight}]">
      <input
          type="text"
          :value="singleDate"
          @keyup.enter="handleTypeDate($event, 'singleDatePicker' + obj.key)"
          @click="openCalendar('singleDatePicker' + obj.key)"
          class="button form-control"
          :state="state"
          :class="error"
          :placeholder="obj.attributes.placeholder ? $t(obj.attributes.placeholder) : 'dd/mm/jjjj'"
          :disabled="checkDisabled"
          :ref="obj.attributes.id"
          readonly
      />
      <span class="icon-left"><font-awesome-icon icon="calendar"></font-awesome-icon>
      </span>
    </div>
    <Datepicker
        :id="obj.attributes.id"
        v-bind="attr"
        input-class="date-picker-input hidden"
        :bootstrapStyling="true"
        format="dd/MM/yyyy"
        output-format="dd/MM/yyyy"
        @selected="handleDateChange"
        v-on="allEvents"
        :open-date="singleDate ? convertToDate(singleDate) : null"
        :placeholder="obj.attributes.placeholder ? $t(obj.attributes.placeholder) : 'dd/mm/jjjj'"
        :initial-view="obj.attributes.yearFirst ? 'year' : 'day'"
        :language="languages[localeStore]"
        :ref="'singleDatePicker' + obj.key"
        :monday-first="true"
        :disabled-dates="disabledDates"
    ></Datepicker>
  </div>
  <div v-else class="date-range-picker">
    <div class="date-picker-wrapper marginbottom">
      <div class="range-wrapper">
        <div class="date-input-wrapper">
          <input
              type="text"
              :value="dateStart"
              @blur="handleTypeStartDate($event, 'datepickerfrom')"
              @keyup.enter="handleTypeStartDate($event, 'datepickerfrom')"
              @focus="openCalendar('datepickerfrom')"
              class="form-control date-picker-input"
              :state="state"
              :class="error"
              :placeholder="obj.attributes.placeholder ? $t(obj.attributes.placeholder) : 'dd/mm/jjjj'"
              :disabled="checkDisabled"
              :ref="obj.attributes.id"
          />
          <span :class="['date-check', {showcheck: hasValueRange.start}]"><font-awesome-icon
              icon="check"></font-awesome-icon>
          </span>
        </div>
        <Datepicker
            ref="datepickerfrom"
            input-class="date-picker-input hidden"
            :bootstrapStyling="true"
            wrapper-class="clear-button-wrapper"
            calendar-class="center-calendar left"
            format="dd/MM/yyyy"
            output-format="dd/MM/yyyy"
            :class="errors[0] ? 'help' : 'correct'"
            @input="handleChangeDateStart"
            :open-date="singleDate ? convertToDate(singleDate) : null"
            :placeholder="$t('GENERAL_DATE_FROM')"
            :language="languages[localeStore]"
            :initial-view="obj.attributes.yearFirst ? 'year' : 'day'"
            :disabled="obj.attributes.startDisabled === true || obj.attributes.startDisabled === 'true'"
            :monday-first="true"
            :disabled-dates="disabledDates"
        ></Datepicker>
      </div>
      <div class="range-wrapper">
        <div class="date-input-wrapper">

          <input
              type="text"
              :value="dateEnd"
              @blur="handleTypeEndDate($event, 'datepickerto' + obj.key)"
              @keyup.enter="handleTypeEndDate($event, 'datepickerto' + obj.key)"
              @click="openCalendar('datepickerto' + obj.key)"
              class="form-control date-picker-input"
              :state="state"
              :class="error"
              :placeholder="obj.attributes.placeholder ? $t(obj.attributes.placeholder) : 'dd/mm/jjjj'"
              :disabled="obj.attributes.disabled === true || obj.attributes.disabled === 'true'"
          />
          <span :class="['date-check', {showcheck: hasValueRange.end}]"><font-awesome-icon
              icon="check"></font-awesome-icon>
          </span>
        </div>
        <Datepicker
            :ref="'datepickerto' + obj.key"
            input-class="date-picker-input hidden"
            :bootstrapStyling="true"
            wrapper-class="clear-button-wrapper"
            calendar-class="center-calendar"
            format="dd/MM/yyyy"
            output-format="dd/MM/yyyy"
            :class="errors[0] ? 'help' : 'correct'"
            @input="handleChangeDateEnd"
            :open-date="singleDate ? convertToDate(singleDate) : null"
            :placeholder="$t('GENERAL_DATE_TO')"
            :disabled-dates="disabledDates"
            :language="languages[localeStore]"
            :initial-view="obj.attributes.yearFirst ? 'year' : 'day'"
            :disabled="obj.attributes.endDisabled === true || obj.attributes.endDisabled === 'true'"
            :monday-first="true"
        ></Datepicker>
      </div>
    </div>
  </div>
</template>

<script>
  import Datepicker from 'vuejs-datepicker'
  import { en, nl } from 'vuejs-datepicker/dist/locale'
  import moment from 'moment'
  import { mapState } from 'vuex'
  import mixinFunctions from '@/mixin/mixinFunctions'
  import confirm from '@/mixin/confirm'

  export default {
    name: 'DateRangePicker',
    mixins: [mixinFunctions, confirm],
    components: {Datepicker},
    props: ['placeholder', 'state', 'obj', 'attr', 'passed',
      'state', 'error', 'hasValue', 'hasValueRange'],
    data () {
      return {
        emitEvent: false, //if true this will emit action.event instead of handling event on v-on="allEvents" - set to false to enable normal events (not used??)
        languages: {
          en: en,
          nl: nl
        },
        dateStart: '',
        dateEnd: '',
        disabledDates: {},
        singleDate: '',
        submitOnBlur: typeof this.obj.attributes.submitOnBlur != 'undefined' ? this.obj.attributes.submitOnBlur : true
      }
    },
    computed: {
      ...mapState(['localeStore'])
    },
    methods: {
      handleChangeDateStart (val) {
        if (val) {
          this.disabledDates = {
            to: new Date(moment(val))
          }

          if (this.dateEnd) {
            if (moment(this.dateEnd).isBefore(moment(val))) {
              this.dateEnd = ''
            }
          }
          this.dateStart = moment(val).format('DD/MM/YYYY')
          this.$emit('selected', {startDate: moment(val).format('DD/MM/YYYY'), endDate: this.dateEnd})
        } else {
          this.disabledDates = {}
        }
      },
      handleChangeDateEnd (val) {
        this.dateEnd = moment(val).format('DD/MM/YYYY')
        this.$emit('selected', {startDate: this.dateStart, endDate: moment(val).format('DD/MM/YYYY')})
      },
      handleTypeStartDate (event, calendar) {
        let eventKey = (event.which ? event.which : event.keyCode)
        this.$refs[calendar].close()
        if (event.target.value === '') {
          this.disabledDates = {}
          this.dateStart = ''
          if (this.dateEnd === '') {
            this.$emit('selected', '')
          } else {
            this.$emit('selected', {startDate: '', endDate: this.endDate})
          }
          if(this.submitOnBlur || eventKey == 13) { //Allow enter key
            this.$emit('onEnter')
          }
        } else {
          let date = moment(event.target.value, 'DD/MM/YYYY')
          if (date.isValid()) {
            this.disabledDates = {
              to: new Date(moment(date))
            }

            if (this.dateEnd) {
              if (moment(this.dateEnd).isBefore(moment(date))) {
                this.dateEnd = ''
              }
            }
            event.target.value = date.format('DD/MM/YYYY')
            this.dateStart = date.format('DD/MM/YYYY')
            this.$emit('selected', {startDate: date.format('DD/MM/YYYY'), endDate: this.dateEnd})
            if(this.submitOnBlur || eventKey == 13) { //Allow enter key
              this.$emit('onEnter')
            }
          } else {
            event.target.value = ''
            this.disabledDates = {}
            this.$emit('selected', {startDate: '', endDate: this.dateEnd})
            if(this.submitOnBlur || eventKey == 13) { //Allow enter key
              this.$emit('onEnter')
            }
          }
        }
      },
      handleTypeEndDate (event, calendar) {
        let eventKey = (event.which ? event.which : event.keyCode)
        this.$refs[calendar].close()
        if (event.target.value === '') {
          this.dateEnd = ''
          if (this.dateStart === '') {
            this.$emit('selected', '')
          } else {
            this.$emit('selected', {startDate: this.dateStart, endDate: ''})
          }
          if(this.submitOnBlur || eventKey == 13) { //Allow enter key
            this.$emit('onEnter')
          }
        } else {
          let date = moment(event.target.value, 'DD/MM/YYYY')
          if (date.isValid() && !(this.dateStart && date.isBefore(moment(this.dateStart, 'DD/MM/YYYY')))) {
            event.target.value = date.format('DD/MM/YYYY')
            this.dateEnd = date.format('DD/MM/YYYY')
            this.$emit('selected', {startDate: this.dateStart, endDate: date.format('DD/MM/YYYY')})
            if(this.submitOnBlur || eventKey == 13) { //Allow enter key
              this.$emit('onEnter')
            }
          } else {
            event.target.value = ''
            this.dateEnd = ''
            this.$emit('selected', {startDate: this.dateStart, endDate: ''})
            if(this.submitOnBlur || eventKey == 13) { //Allow enter key
              this.$emit('onEnter')
            }
          }
        }
      },
      //single date picker
      handleDateChange (val) {
        this.singleDate = moment(val).format('DD/MM/YYYY')
        this.$emit('selected', val)
      },
      handleTypeDate (event, calendar) {
        let eventKey = (event.which ? event.which : event.keyCode)
        this.$refs[calendar].close()
        if (event.target.value == '') {
          this.singleDate = ''
          this.$emit('selected', '')
          if(this.submitOnBlur || eventKey == 13) { //Allow enter key
            this.$emit('onEnter')
          }
        } else {
          let date = moment(event.target.value, 'DD/MM/YYYY')
          if (date.isValid()) {
            event.target.value = date.format('DD/MM/YYYY')
            this.singleDate = date.format('DD/MM/YYYY')
            this.$emit('selected', date)
            if(this.submitOnBlur || eventKey == 13) { //Allow enter key
              this.$emit('onEnter')
            }
          } else {
            event.target.value = ''
            this.singleDate = ''
            this.$emit('selected', '')
            if(this.submitOnBlur || eventKey == 13) { //Allow enter key
              this.$emit('onEnter')
            }
          }
        }
      },
      openCalendar (calendar) {
        this.$refs[calendar].showCalendar()
      },
      convertToDate (date) {
        return new Date(moment(date, 'DD/MM/YYYY'))
      },
      handleFocusInput () {
        this.$nextTick(() => {
          console.log('datepicker focus',)
          const inputRef = this.$refs[this.obj.attributes.id]
          inputRef.focus()
        })
      }
    },
    mounted () {
      if (this.obj.attributes.value) {
        if (this.obj.attributes.value.hasOwnProperty('start')) {
          this.dateStart = moment(this.obj.attributes.value.start, 'YYYY/MM/DD').format('DD/MM/YYYY')
          this.dateEnd = moment(this.obj.attributes.value.end, 'YYYY/MM/DD').format('DD/MM/YYYY')
        } else {
          this.singleDate = moment(this.obj.attributes.value, 'YYYY/MM/DD').format('DD/MM/YYYY')
        }
      }
      if (this.obj.attributes.focusOnLoad) {
        this.handleFocusInput()
      }
    },
    beforeMount() {
      if (this.obj.attributes.disabledDates) {
        this.disabledDates.to = new Date(moment(this.obj.attributes.disabledDates))
      }
      if (this.obj.attributes.disabledDatesStart) {
        this.disabledDates.from = new Date(moment(this.obj.attributes.disabledDatesStart))
      }
    }
  }
</script>

<style scoped lang="scss">
  .date-range-picker {

  }

  .marginbottom {
    margin-bottom: 10px;
  }

  .date-picker-wrapper {
    display: flex;
    flex-direction: row;


    p {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .range-wrapper {
    width: 50%;
  }

  .date-input-wrapper {
    position: relative;
  }

  .date-check {
    position: absolute;
    display: none;
    width: 20px;
    height: 20px;
    right: 12px;
    top: 7px;
    color: green;
  }

  .icon-left {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 12px;
    top: 7px;
    color: white;
  }

  .showcheck {
    display: inline-block;
  }
</style>
