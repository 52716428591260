<template>
  <div>
    <div>
      <label for="url">File url</label>
    </div>
    <p>ex: http://api.lighthouse.local/img/download.jpg</p>
    <div>
      <input type="text" id="url" placeholder="url" v-model="url"/>
    </div>
    <div>
      <button @click="downloadWithAxios">Download file with Axios</button>
    </div>
    <img src="http://api.lighthouse.local/Assets/Images/download.jpg"/>
  </div>
</template>

<script>
  export default {
    name: 'LhDiv',
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        url: 'http://api.lighthouse.local/Assets/Images/download.jpg'
      }
    },
    methods: {
      forceFileDownload (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      },

      downloadWithAxios () {
        if (this.url !== '') {
          this.$store
              .dispatch('export', this.url)
              .then(result => {
                this.forceFileDownload(result)
              })
              .catch(error => {
                console.log('error: ', error)
              })
        }
      }
    }
  }
</script>

