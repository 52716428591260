import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: process.env.VUE_APP_VERSION,
    backendVersion: '',
    mainJSON: {},
    authJSON: {},
    user: {},
    token: localStorage.getItem('access_token') || null,
    // user: JSON.parse(localStorage.getItem("user")) || null,
    pageLoaded: null,
    loadingOrError: 'Loading...',
    loadingJSON: false,
    downloading: '',
    foundElement: null,
    userSettings: {
      view: 'lh-list'
    },
    downscaleUI: true,
    popups: [],
    popupSettings: {
      isOpen: false,
      content: null,
      selectedGuid: null,
      key: null
    },
    activeTab: {},
    formItems: [],
    formFilledData: [],
    browseSettings: [],
    paginationData: [],
    viewType: 'POPUP',
    sideFormContent: {},
    detailPage: {},
    filterData: [],
    activeFilterData: [],
    sortData: {},
    htmlPage: null,
    sidebarCollapse: false,
    localeStore: '',
    isMobile: false,
    windowSize: {
      windowWidth: 0,
      windowHeight: 0
    },
    redirectCheck: false,
    redirectCheckApi: null,
    sidebarJSON: {},
    lastRoutes: [],
    sidebarWidth: 0,
    translationEditMode: false,
    latestTranslations: {},
    translations: {
      en: {
        GENERAL_LOADING: 'Loading data',
        GENERAL_EMAIL: "Email",
        GENERAL_USERNAME: "Username",
        GENERAL_PASSWORD: "Password",
        GENERAL_LOGIN: "Login",
        GENERAL_LOGIN_TITLE: "Login",
        GENERAL_REGISTER: 'Register',
        GENERAL_REGISTER_TITLE: 'Register',
        GENERAL_RESET_PASSWORD: 'Reset password',
        GENERAL_RESET_PASSWORD_TITLE: 'Reset password',
        GENERAL_NEW_PASSWORD_TITLE: 'New password',
        GENERAL_DATE_FROM: 'From',
        GENERAL_DATE_TO: 'to',
        GENERAL_CLEAR_ALL: 'clear all',
        GENERAL_VALIDATION_ERROR_TITLE: 'Please provide the required information correctly',
        GENERAL_PAGENOTFOUND_TEXT: 'Page not found.',
        GENERAL_ERROR_PAGE_TITLE: 'Something went wrong',
        GENERAL_ERROR_PAGE_TEXT: 'An error has occurred.',
        GENERAL_ERROR_PAGE_BUTTON: 'Back to the homepage.',
        GENERAL_CLOSEPOPUP_CONTENT: 'Changes were made, are you sure you want to close?',
        GENERAL_CLOSEPOPUP_TITLE: 'Confirm close',
        GENERAL_CLOSEPOPUP_CANCEL: 'Cancel',
        GENERAL_CLOSEPOPUP_CONFIRM: 'Close',
        VALIDATION_TEST: 'Testing {field} some text',
        UPLOAD_CHOOSE_FILE: 'Choose file',
        UPLOAD_FILE_SIZE: 'File size',
        UPLOAD_BROWSE_TEXT: 'Browse file',
        UPLOAD_UPLOAD: 'Upload',
      },
      nl: {
        GENERAL_LOADING: 'Gegevens laden',
        GENERAL_EMAIL: "Email",
        GENERAL_USERNAME: "Gebruikersnaam",
        GENERAL_PASSWORD: "Wachtwoord",
        GENERAL_LOGIN: "Log in",
        GENERAL_LOGIN_TITLE: "Aanmelden",
        GENERAL_REGISTER: 'Registreer',
        GENERAL_REGISTER_TITLE: 'Registreer',
        GENERAL_RESET_PASSWORD: 'Wachtwoord vergeten',
        GENERAL_RESET_PASSWORD_TITLE: 'Wachtwoord vergeten',
        GENERAL_NEW_PASSWORD_TITLE: 'Nieuw wachtwoord',
        GENERAL_DATE_FROM: 'Van',
        GENERAL_DATE_TO: 'tot',
        GENERAL_CLEAR_ALL: 'alles wissen',
        GENERAL_VALIDATION_ERROR_TITLE: 'Niet alle velden zijn correct ingevuld',
        GENERAL_PAGENOTFOUND_TEXT: 'Pagina niet gevonden.',
        GENERAL_ERROR_PAGE_TITLE: 'Something went wrong',
        GENERAL_ERROR_PAGE_TEXT: 'Er is een fout opgetreden.',
        GENERAL_ERROR_PAGE_BUTTON: 'Terug naar de homepage.',
        GENERAL_CLOSEPOPUP_CONTENT: 'Er zijn wijzigingen gebeurd, ben je zeker dat je wil sluiten?',
        GENERAL_CLOSEPOPUP_TITLE: 'Sluiten bevestigen',
        GENERAL_CLOSEPOPUP_CANCEL: 'Annuleren',
        GENERAL_CLOSEPOPUP_CONFIRM: 'Sluiten',
        VALIDATION_TEST: 'Custom bericht met deze {field} veld',
        UPLOAD_CHOOSE_FILE: 'Kies een bestand',
        UPLOAD_FILE_SIZE: 'Bestand grootte',
        UPLOAD_BROWSE_TEXT: 'Bestand kiezen',
        UPLOAD_UPLOAD: 'Uploaden',
      }
    },
  },
  getters,
  mutations,
  actions
})
