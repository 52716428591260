<template>
  <div class="basePage" :class="checkClasses" :style="checkStyles" draggable="false">
    <div class="downscale-ui-toggle" @click="handleToggleDownscaleUi" v-if="!isMobile">
      <font-awesome-icon :icon="downscaleUI ? ['fas', 'search-plus'] : ['fas', 'search-minus']"></font-awesome-icon>
    </div>
    <component
        v-if="notEmpty(mainJSON)"
        :is="mainJSON.type"
        :key="mainJSON.key"
        :obj="mainJSON"
    ></component>

    <div v-if="loading && notEmpty(mainJSON)" :class="['spinner-small', 'loading-wrapper', {'sidebar-collapsed':sidebarCollapse}, {'popup': popups.length >= 1}]">
      <font-awesome-icon :icon="['fad', 'spinner']" spin></font-awesome-icon>
      <span>{{ $t('GENERAL_LOADING') }}</span>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'

  export default {
    name: 'BasePage',
    data () {
      return {
        componentKey: 1,
        pageLink: ''
      }
    },
    computed: {
      ...mapState({
        mainJSON: 'mainJSON',
        loading: 'loadingJSON',
        page: 'pageLoaded',
        translations: 'translations',
        sidebarCollapse: 'sidebarCollapse',
        popups: 'popups',
        downscaleUI: 'downscaleUI',
        isMobile: 'isMobile'
      }),
    },
    methods: {
      ...mapActions({
        fetchJson: 'fetchJSON'
      }),
      ...mapMutations({
        toggleDownscaleUi: 'toggleDownscaleUi'
      }),
      closepopup () {
        this.$store.commit('removePopupOnTop')
      },
      handleToggleDownscaleUi () {
        this.toggleDownscaleUi()
      }
    },
    created () {
    },
    watch: {
      //fetch when url is changed
      $route (to, from) {
        console.log(`Url changed from ${from.path} to ${to.path}`)

        if (this.isMobile && !this.sidebarCollapse) {
          this.$store.commit('toggleSidebar', null)
        }
      },
      translations () {
        //update of translations
        console.log(this.$i18n)
        for (let locale in this.translations) {
          this.$i18n.setLocaleMessage(locale, this.translations[locale])
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .spinner-small {
    position: fixed;
    bottom: 0;
    right: 0;
    max-height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: $medium-dark-grey;
    top: unset;
    left: unset;
    background: $ultra-light-grey;
    padding: 1rem;
    //box-shadow: $popup-shadow;
    width: calc(100% - #{$sidebar-max-width});

    &.sidebar-collapsed {
      width: calc(100% - #{$sidebar-min-width});
    }

    &.popup {
      width: 100%;
    }

    span {
      font-size: 1rem;
      margin-left: 0.5rem;
    }
  }

  .inside-mobile{
    .spinner-small {
      bottom: 10px;
      right: 32px;
    }
  }

  .basePage {
    display: flex;
    flex-flow: column wrap;
    flex-grow: 1;
    max-width: 100%;
  }

  .loading-wrapper {
    z-index: 2000;
  }

  .downscale-ui-toggle {
    position: absolute;
    bottom: 1rem;
    right: 2rem;
    font-size: 1.2rem;
    color: $primary-color;
    cursor: pointer;
    z-index: 100;
  }
</style>

