<template>
  <div class="mainContainer">

    <div v-for="item in message" :id="item.key" :key="item.key">
      <component :is="item.type" :obj="item" :key="item.key"/>
    </div>
    <router-view></router-view>
    <!-- <component v-for="child in message.children" :is="child.type" :key="child.key" :obj="child"/> -->
    <!-- <keep-alive> -->
    <!-- </keep-alive> -->
  </div>
</template>

<script>
  export default {
    name: 'MainContainer',
    components: {},
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        message: [
          {
            type: 'Dashboard', // soort component
            key: 'root_header_1', // id van component [parent element]_[name]_[num]
            attributes: {
              class: 'header',
              content: '', // dit is de tekst
              href: ''
            },
            children: [
              {
                type: 'Logo', // soort component
                key: 'root_logo_1', // id van component [parent element]_[name]_[num]
                attributes: {
                  class: 'logo',
                  content: 'navMenu1', // dit is de tekst
                  href: '',
                  src: 'logo.png'
                },
                children: []
              },
              {
                type: 'Title', // soort component
                key: 'root_title_1', // id van component [parent element]_[name]_[num]
                attributes: {
                  class: 'root_title',
                  content: 'Lighthouse' // dit is de tekst
                },
                children: []
              },
              {
                type: 'MainMenu', // soort component
                key: 'root_mainmenu_1', // id van component [parent element]_[name]_[num]
                attributes: {
                  class: 'nav',
                  content: 'navMenu1' // dit is de tekst
                },
                children: [
                  {
                    type: 'navigation-item', // soort component
                    key: 'root_mainmenuItem_1', // id van component [parent element]_[name]_[num]
                    attributes: {
                      name: 'home',
                      class: 'blabla',
                      content: 'Home',
                      href: '/'
                    }
                  },
                  {
                    type: 'NavigationItem', // soort component
                    key: 'root_mainmenuItem_3', // id van component [parent element]_[name]_[num]
                    attributes: {
                      name: 'about',
                      class: 'blabla',
                      content: 'About',
                      href: '/about'
                    }
                  },
                  {
                    type: 'NavigationItem', // soort component
                    key: 'root_mainmenuItem_4', // id van component [parent element]_[name]_[num]
                    attributes: {
                      name: 'data',
                      class: 'blabla',
                      content: 'Data',
                      href: '/data'
                    }
                  },
                  {
                    type: 'NavigationItem', // soort component
                    key: 'root_mainmenuItem_2', // id van component [parent element]_[name]_[num]
                    attributes: {
                      name: 'login',
                      class: 'blabla',
                      content: 'Login',
                      href: '/login'
                    }
                  },
                  {
                    type: 'NavigationItem', // soort component
                    key: 'root_mainmenuItem_5', // id van component [parent element]_[name]_[num]
                    attributes: {
                      name: 'sign-up',
                      class: 'blabla',
                      content: 'Sign up',
                      href: '/register'
                    }
                  },
                  {
                    type: 'NavigationItem', // soort component
                    key: 'root_mainmenuItem_6', // id van component [parent element]_[name]_[num]
                    attributes: {
                      name: 'call-api',
                      class: 'blabla',
                      content: 'Call api',
                      href: '/test/user/1'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">


  ul {
    list-style-type: none;
    padding: 0;
  }

  a {
    color: #42b983;
  }


</style>
