<template>
    <div>
        <span class="password-wrapper">
            <lh-input :obj="inputs.mfaCode" :parentKey="getParentKey"></lh-input>
      </span>
        <div class="mfa-info mt-2 mb-2">
            <p>
                <font-awesome-icon :icon="['fas', 'info-circle']" class="mr-1"></font-awesome-icon>
                {{ message }}
            </p>
        </div>
        <div class="mfa-actions">
            <lh-button v-if="obj.attributes.providers.email" :obj="emailButton" @click.native="getToken($event, 'email')" class="mr-2"></lh-button>
            <lh-button v-if="obj.attributes.providers.sms" :obj="smsButton" @click.native="getToken($event, 'sms')" class="mr-2"></lh-button>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    import Vue from "vue";

    export default {
        name: 'LhMultiFactor',
        components: {},
        props: ['obj', 'data'],
        data(){
            return {
                message: this.$t(this.obj.attributes.message),
                emailButton: {
                    'key': 'multi-factor-email',
                    'type': 'LhButton',
                    'actions': [],
                    'attributes': {
                        'content': 'email',
                        'name': 'multi-factor-email',
                        'class': 'btn-get-token secondary',
                        'type': 'button'
                    },
                },
                smsButton: {
                    'key': 'multi-factor-sms',
                    'type': 'LhButton',
                    'actions': [],
                    'attributes': {
                        'content': 'sms',
                        'name': 'multi-factor-sms',
                        'class': 'btn-get-token secondary',
                        'type': 'button'
                    },
                },
                inputs: {
                    mfaCode: {
                        'key': 'multi-factor-token',
                        'type': 'LhInput',
                        'actions': [],
                        'attributes': {
                            'type': 'text',
                            'name': 'mfaCode',
                            'id': 'mfaCode',
                            'disabled': false,
                            'placeholder': this.$t('GENERAL_MULTIFACTOR_TOKEN')
                        },
                        'styles': [],
                        'children': []
                    },
                }
            }
        },
        computed: {
            //This ensures the lh-input gets added to the parent form when submitted
            getParentKey: function () {
                let parent = this.$parent;
                while (parent && parent.$options._componentTag !== 'LhForm') {
                    parent = parent.$parent;
                }
                return parent ? parent.$vnode.key : ''
            }
        },
        methods: {
            getToken: function (event, method) {
                this.$store.commit('setLoading')
                let getRoutes = this.obj?.attributes?.providerRoutes
                let providerRoute = null
                if(getRoutes && Object.keys(getRoutes).length > 0 && getRoutes.hasOwnProperty(method)){
                    providerRoute = getRoutes[method]
                }
                let baseRoute = Vue.prototype.$clientConfig.VUE_APP_API_URL + '/multifactor/renew/' + method
                let api = providerRoute ? providerRoute : baseRoute
                let buttonName = method + 'Button'
                let MFA = this

                let payload = {}
                let checkForLogin = document.getElementsByClassName("login-wrapper")
                if(checkForLogin.length > 0){
                    payload.Username = document.getElementById("login-username").value
                    payload.Password = document.getElementById("login-password").value
                }

                //Disable button + spinner (prevent spamming)
                this.$set(this[buttonName].attributes, 'icon', ['fad', 'spinner'])
                this.$set(this[buttonName].attributes, 'iconSpin', true)
                this.$set(this[buttonName].attributes, 'disabled', true)
                setTimeout(function(){
                    //Re-enable button
                    MFA.$set(MFA[buttonName].attributes, 'icon', null)
                    MFA.$set(MFA[buttonName].attributes, 'iconSpin', null)
                    MFA.$set(MFA[buttonName].attributes, 'disabled', false)
                }, (1000 * 30)) //30 seconds
                axios({
                    method: 'POST',
                    url: api,
                    data: payload
                }).then(response => {
                    if(response?.data?.success){
                        this.message = this.$t(response.data.message)
                        let messageParagraph = document.querySelector(".mfa-info p")
                        if(messageParagraph){
                            messageParagraph.classList.add("fadeInEffect")
                            setTimeout(function(){
                                messageParagraph.classList.remove("fadeInEffect")
                            }, 1000)
                        }
                    }
                }).catch(error => {
                    console.log(error)
                }).finally(() => {
                    this.$store.commit('stopLoading')
                })
                event.stopPropagation()
            },
            updateToken: function (val) {
                let payload = {
                    key: this.parentFormKey,
                    inputs: {
                        ['mfaCode']: val
                    }
                }
                console.log("CHANGEEE", payload, val, this, this.$parent)
                //this.$store.commit('setFormFilledDataByKey', payload)
            }
        }
    }
</script>
<style scoped lang="scss">
    .lh-input .form-control {
        width: 100%;
    }
    .mfa-info {
        p {
            background: $light-grey;
            padding: .25rem .75rem;
            border-radius: 10px;

            &.fadeInEffect {
                -webkit-animation: fadeInEffect 1s ease-in-out 0s;
                -moz-animation: fadeInEffect 1s ease-in-out 0s;
                -o-animation: fadeInEffect 1s ease-in-out 0s;
                animation: fadeInEffect 1s ease-in-out 0s;
            }
        }
    }
    .mfa-actions {
        display: flex;
        justify-content: end;
    }
    @-webkit-keyframes fadeInEffect {
        from {
            background: #fff;
        }
        to {
            background: $light-grey;
        }
    }
    @-moz-keyframes fadeInEffect {
        from {
            background: #fff;
        }
        to {
            background: $light-grey;
        }
    }
    @keyframes fadeInEffect {
        from {
            background: #fff;
        }
        to {
            background: $light-grey;
        }
    }
</style>