<template>
  <div  v-if="obj && obj.attributes.showMobileOnly && !isMobile" class="lh-collapse-empty">
    <component v-for="child in obj.children" :is="child.type" :obj="child" :key="child.key"></component>
  </div>
  <div v-else :class="[{transparent: isTransparent}, 'lh-collapse-wrapper']" v-on-clickaway="closeCollapse">
    <button v-b-toggle.lh-collapse
            :class="[{transparent: isTransparent}, 'button',
            {'lh-collapse-toggle': !obj}, {'lh-collapse-toggle-obj': obj}]"
            @click="handleToggle">
      <h4 v-if="obj && obj.attributes.title">{{$t(obj.attributes.title)}}</h4>
      <transition :name="rotateMode" mode="out-in">
        <font-awesome-icon
            v-if="collapsed"
            :icon="icons[0]"
            :key="'icon-up'"
        ></font-awesome-icon>
        <font-awesome-icon
            v-else
            :icon="icons[1]"
            :key="'icon-down'"
        ></font-awesome-icon>
      </transition>
    </button>
    <transition name="fade">
      <div id="lh-collapse" v-show="!collapsed"
           :class="[{collapsed: collapsed}, {'lh-collapse': !obj}, {'lh-collapse-obj': obj}]">
        <div class="scrollable">
          <div v-if="obj" class="lh-collapse-content">
            <keep-alive>
              <component v-for="child in obj.children" :is="child.type" :obj="child" :key="child.key"></component>
            </keep-alive>
          </div>
          <slot v-else></slot>
        </div>
        <div class="collapse-footer" v-show="obj && !obj.attributes.hideFooter">
          <slot name="footer"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mixin as clickaway } from 'vue-clickaway'

  export default {
    name: 'LhCollapse',
    components: {},
    mixins: [clickaway],
    data () {
      return {
        collapsed: true
      }
    },
    props: {
      obj: {
        type: Object
      },
      icons: {
        type: Array,
        default: function () {
          return [['fal', 'angle-down'], ['fal', 'angle-up']]
        }
      },
      transparent: {
        type: Boolean
      }
    },

    computed: {
      rotateMode () {
        return this.collapsed ? 'rotateLeft' : 'rotateRight'
      },
      isTransparent () {
        if (this.obj) {
          return this.obj.attributes.transparent
        } else {
          return this.transparent
        }
      }
    },
    methods: {
      handleToggle () {
        this.collapsed = !this.collapsed
      },
      closeCollapse () {
        this.collapsed = true
      }
    },
    mounted () {
      if (this.obj && !this?.obj?.attributes.startCollapsed) this.collapsed = false
    }
  }
</script>

<style scoped lang="scss">
h4 {
  margin-bottom: 0;
}
  .lh-collapse {
    position: absolute;
    z-index: 50;
    background: $white;
    box-shadow: $popup-shadow;
    width: auto;
    padding-bottom: 50px;

    .scrollable {
      max-height: 700px;
      overflow: auto;
    }

    &-wrapper {
      position: relative;
    }
  }

  .collapse-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $popup-footer-bg;
    height: 50px;
  }

  .lh-collapse-toggle {
    min-width: auto;
    padding: 0;
    &.button {
      svg {
        margin-right: 0;
      }
    }

  }

  .lh-collapse-toggle-obj {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 20px;
    margin-bottom: 5px;
    width: 100%;
    background-color: $light-grey;

    &:hover, &:focus {
      background-color: $light-grey;
    }

    svg {
      color: $primary-color !important;
      font-size: 25px;
    }

    .scrollable {
      max-height: unset;
    }


  }

  .lh-collapse-obj{
    .collapse-footer {
      position: relative;
    }
  }

  .lh-collapse-content{
    border: 1px solid $light-grey;
    border-top: 0;
    padding: 0 0.5rem;
  }
</style>
