import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "../store/store";

Vue.use(VueI18n);

export default new VueI18n({
  locale: "nl",
  fallbackLocale: "nl",
  messages: store.state.translations,
  silentTranslationWarn: true
});

