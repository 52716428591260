<template>
  <tbody v-if="!isMobile" :class="[{row_selected: rowChecked}, 'gridrow', checkClasses]" :style="checkStyles">

  <tr
      @click="$emit('rowClick', $event, obj, index, rowChecked)"
      v-on="allEvents"
      :ref="obj.key"
  >
    <td class="checkbox-wrapper">
      <transition name="fade">
        <div @click.stop>
          <b-form-checkbox
              v-if="(checkboxVisible && !disableMultiSelect) || selectMode"
              class="chkbox"
              :id="`checkbox-group-${browseKey}${index}`"
              :checked="rowChecked"
              @change="handleChkClick"
          />
          <div v-else class="checkbox-placeholder">
          </div>
        </div>
      </transition>
    </td>
    <td class="gridCell toggleIcon">
      <div
          v-if="expandItems.length"
          class="icon-wrapper"
      >
        <font-awesome-icon :icon="['fa', 'caret-down']" v-if="rowSelectedChk"></font-awesome-icon>
        <font-awesome-icon :icon="['fa', 'caret-right']" v-else></font-awesome-icon>
      </div>
    </td>
    <component
        v-for="cell in rearrangedRow"
        :is="cell.type"
        :obj="cell"
        :browseKey="browseKey"
        :key="cell.key"
        @input-click="$emit('input-click')"
    ></component>
  </tr>

  <!--  expandrow-->
  <transition name="fade" @after-enter="scrollToRow" :duration="{ enter: 400, leave: 200}">
    <tr v-show="rowSelectedChk" v-if="expandItems.length" class="expand-row">
      <td class="empty-cell"></td>
  <!--    <td class="gridCell toggleIcon"></td>-->
      <td :colspan="this.headers.length + 1" class="row-expand-content">
          <component
              v-for="child in expandItems"
              :is="child.type"
              :obj="child"
              :key="child.key"
          ></component>
      </td>
    </tr>
  </transition>
  </tbody>

  <!--  MOBILE ROWS-->
  <tbody v-else>
  <tr :class="[{'isMobile': isMobile}, {'first': index == 1}]">
    <td>
      <div v-if="headersMobile.length > 0"
           class="grid-card-item"
      >
        <div class="row-container-mobile">
          <transition name="expand" tag="div" mode="out-in">
            <div class="default checkbox-wrapper-mobile" v-bind:class="{ bigger: showCheckbox }" key="small">
              <b-form-checkbox-group class="checkbox-wrapper-group-mobile">
                <b-form-checkbox class="chkbox" :checked="rowChecked" @change="handleChkClick"/>
              </b-form-checkbox-group>
            </div>
          </transition>

          <div class="grid-card-item-content" :class="[{row_selected: rowChecked}, 'gridrow']"
               @click="$emit('rowClick', {
                 event: $event,
                 obj: obj,
                 index: index,
                 checked: rowChecked})"
               @contextmenu="disableContextMenu($event)">

            <div class="card-title">
              <template v-for="cell in cellsMobile" v-if="cell.attributes.titleMobile">
                <div v-if="cell.attributes.content">{{ cell.attributes.content }}</div>
              </template>
            </div>


            <div class="card-content">
              <p v-for="order in orderedCellsMobile" :style="{order: order}">
                <span v-for="cell in cellsMobile" v-if="cell.attributes.orderMobile === order && cell.attributes.labelMobile === false" >{{cell.attributes.content}} </span>
              </p>
              <p v-for="cell in cellsMobile" v-if="!cell.attributes.titleMobile && cell.attributes.labelMobile !== false">
                  {{$t(headerContent(cell.attributes.target))}}: <template v-if="cell.children.length > 0 && cell.children[0].attributes.tooltip">{{ $t(cell.children[0].attributes.tooltip) }}</template><template v-else>{{cell.attributes.content}}</template>
              </p>
            </div>

            <b-row class="row buttons-wrapper-mobile no-gutters">
              <b-col class="col-auto" v-for="button in buttonItemsMobile.children">
                <component
                    :is="button.type"
                    :obj="button"
                    :key="button.key"
                    :componentClass="'white-icon-buttons'"
                ></component>
              </b-col>
            </b-row>
<!--            <b-row class="row no-gutters">-->
<!--              <b-col>-->
<!--                <div-->
<!--                    v-if="expandItems.length"-->
<!--                    class="icon-wrapper"-->
<!--                >-->
<!--                  <font-awesome-icon :icon="['fa', 'caret-down']" v-if="rowSelectedChk"></font-awesome-icon>-->
<!--                  <font-awesome-icon :icon="['fa', 'caret-right']" v-else></font-awesome-icon>-->
<!--                </div>-->
<!--              </b-col>-->
<!--            </b-row>-->
            <!--            <b-row v-for="cell in cellsMobile" v-if="!cell.attributes.titleMobile" style="width: 100%;">-->
<!--              <b-col cols="12" class="grid-card-col no-gutters" v-if="cell.labelMobile !== false"><span class="header-mobile">{{$t(headerContent(cell.attributes.target))}}:</span>-->
<!--              </b-col>-->
<!--              <b-col cols="12" class="grid-card-col no-gutters p-l-0"><span class="cell-content-mobile">{{cell.attributes.content}}</span>-->
<!--              </b-col>-->
<!--            </b-row>-->
          </div>
        </div>
      </div>
    </td>
  </tr>

  <!--  expandrow-->
<!--  <transition name="fade">-->
<!--    <tr v-show="rowSelectedChk" v-if="expandItems.length" class="expand-row">-->
<!--      <td :colspan="this.headers.length" class="row-expand-content">-->
<!--          <component-->
<!--              v-for="child in expandItems"-->
<!--              :is="child.type"-->
<!--              :obj="child"-->
<!--              :key="child.key"-->
<!--          ></component>-->
<!--      </td>-->
<!--    </tr>-->
<!--  </transition>-->
  </tbody>
</template>

<script>

  import mixinFunctions from '@/mixin/mixinFunctions'
  import { mapState } from 'vuex'
  import confirm from '@/mixin/confirm'

  export default {
    name: 'GridRow',
    mixins: [ mixinFunctions, confirm ],
    components: {},
    props: {
      obj: {
        type: Object
      },
      index: {
        type: Number
      },
      browseKey: {
        type: String
      },
      rowSelectedChk: {
        type: Boolean
      },
      showCheckbox: {
        type: Boolean
      },
      rowChecked: {
        type: Boolean
      },
      checkboxVisible: {
        type: Boolean
      },
      scrollOnExpand: {
        type: Boolean
      },
      disableMultiSelect: {
        type: Boolean
      },
      selectMode: {
        type: Boolean
      }
    },
    data () {
      return {
        eventElements: [],
        localState: false,
        clicked: false
      }
    },
    computed: {
      ...mapState({
        isMobile: 'isMobile'
      }),
      headers () {
        return this.$store.getters.getBrowseSettingByKey(this.browseKey).headers
      },
      rearrangedRow () {
        let oldArr = this.obj.children
        let newArr = []
        for (const header of this.headers) {
          for (const child of oldArr) {
            if ((child.type == 'LhGridCell' || child.type == 'lh-grid-cell') && header.key === child.attributes.target) {
              newArr.push(child)
            }
          }
        }
        return newArr
      },
      expandItems () {
        return this.obj.children.filter(child => child.type != 'LhGridCell' && child.type != 'lh-grid-cell')
      },
      headersMobile () {
        return this.headers.filter(header => header.showMobile)
      },
      cellsMobile () {
        let cells = []
        this.headersMobile.forEach(header => {
          let cell = this.obj.children.find(cell => cell.attributes.target == header.key)
          if (cell) cells.push(cell)
        })
        return cells
      },
      orderedCellsMobile () {
        let orders = []
        this.cellsMobile.find(cell => {
          if (cell.attributes.orderMobile) {
            let orderPresent = orders.find(order => order === cell.attributes.orderMobile)
            if (!orderPresent) orders.push(cell.attributes.orderMobile)
          }
        })
        return orders
      },
      showContentMobile () {
        return header => {
          return this.obj.children.find(cell => cell.attributes.target == header)
              ? this.obj.children.find(cell => cell.attributes.target == header).attributes.content : null
        }
      },
      buttonItemsMobile () {
        let buttonHeader = this.headers.find(head => head.type == 'button')
        return buttonHeader && buttonHeader.key ? this.obj.children.find(cell => cell.attributes.target == buttonHeader.key) : ''
      }
    },
    methods: {
      disableContextMenu (event) {
        if (this.isMobile) {
          event.preventDefault()
          event.stopPropagation() // not necessary in my case, could leave in case stopImmediateProp isn't available?
          event.stopImmediatePropagation()
          return false
        }
      },
      handleChkClick () {
        this.$emit('rowChecked', {value: this.rowChecked, index: this.index})
      },
      headerContent(key) {
        let header = this.headersMobile.find(item => item.key == key)
        return header ? header.name : ''
      },
      scrollToRow () {
        if (this.scrollOnExpand) {
          this.$refs[this.obj.key].scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }
    }
  }
</script>

<style lang="scss">
  .gridrow {
    width: 100%;
    position: relative;

    tr {
      height: 39px;
    }

    .expand-row {
      .row-expand-content {
        padding: 0.5rem 1rem 0.5rem calc(1rem + 13px);
        color: $font-color;
        outline: 2px $browse-selected-color solid;
        outline-offset: -2px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .gridComplex {
      .gridrow {
        transform: translateX(-32px);
        transition: transform $speed $easing;
      }
      &.checkbox-visible {
        .gridrow {
          transform: translateX(0);
        }
      }
    }
  }

  /*Mobile*/

  tr.isMobile {
    border-top: 15px solid $white;

    &.first {
      border-top: 0;
    }

    > td {
      padding: 0;
    }
  }

  tr.isMobile + tr.expand-row {
    outline: 2px solid $browse-selected-color;
    outline-offset: -2px;
    .row-expand-content {
      padding: 5px 10px;
    }
  }

  .header-mobile {
    font-weight: 700;
    color: $font-color;
  }

  .row_selected {
    .header-mobile, .cell-content-mobile {
      color: $white;
    }
  }

  .cell-content-mobile {
    font-weight: 700;
    color: $headings-color;
  }

  .row-container-mobile {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    justify-content: space-between;
  }

  .checkbox-wrapper-mobile {
    display: flex;
    justify-content: center;
    background-color: $medium-grey-alt;

    .checkbox-wrapper-group-mobile {
      display: flex;
      align-items: center;
      overflow-x: hidden;

      .custom-control-label::before, .custom-control-label::after {
        width: 1.5rem;
        height: 1.5rem;
        left: -15px;
        border: 2px solid $white;
        background-color: $medium-grey-alt;
      }
    }

    input {
      font-size: 3em;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::after{
    /*background-image: url("../assets/img/check.svg");*/
  }

  .buttons-wrapper-mobile {
    color: $white;
    text-align: center;
    width: 100%;
    justify-content: flex-end;

    div[class^="col"] {
      &:not(:first-child) {
        margin-left: 1rem;
      }
    }

    .icon-button.white-icon-buttons{
      font-size: 22px;
      color: $white;
      background: $headings-color;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .grid-card-item {
    min-height: 95px;

    .grid-card-col {
      word-break: break-all;
      padding: 0 !important;

      @media (min-width: 400px) {
        flex: 1 0 50%;
        max-width: 50%;
      }
    }

    .grid-card-item-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      justify-content: center;

      .row {
        padding: 3px 0;
      }
    }

    .card-title {
      background-color: $headings-color;
      color: $white;
      width: 100%;
      padding: 0.5em;
      font-weight: bold;
    }

    .card-content {
      display: flex;
      flex-flow: column wrap;
      padding: 5px 10px;
      width: 100%;

      p {
        order: 999;
        margin-bottom: 0.2em;
      }
    }
  }

  .default {
    transition: 0.25s ease-out;
    transition-property: width;
    width: 0;
    pointer-events: none;
  }

  .bigger {
    transition: 0.25s ease-out;
    transition-duration: 0.25s;
    transition-property: width;
    width: calc(12 * var(--vw-unit));
    pointer-events: unset;
  }

  .expand-enter-active, .expand-leave-active {
    transition-property: width;
    transition-duration: 0.5s;
  }

  .checkbox-wrapper {
    .chkbox {
      label:before {
        background-color: unset;
      }
    }
  }

  .checkbox-placeholder {
    display: block;
    width: 24px;
    height: 0px;
  }


</style>
