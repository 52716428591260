<template>
  <div class="grid">
    <div>
      <table class="table noselect">
        <thead>
        <tr>
          <!-- table header -->
          <th v-for="item in obj.attributes.gridHeader" :key="item.key">{{$t(item.name)}}</th>
        </tr>
        </thead>
        <tbody>
        <!-- table body -->
        <tr
            v-for="row in obj.children"
            :key="row.key"
            class="tableRow"
            :data-id="row.attributes.id"
            @click="select($event)"
            @dblclick="dblclicked($event)"
        >
          <td v-for="cell in row.attributes.content" :key="cell.key">
            <component :is="row.type" :obj="cell" :key="cell.gridItem"></component>
          </td>
          <input type="checkbox" :class="row.key" class="chkbox" hidden/>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Grid',
    components: {},
    props: {
      obj: {
        type: Object
      },
      parentKey: {
        tyype: String
      }
    },
    data () {
      return {
        selected: [],
        selectAll: false
      }
    },
    methods: {
      select: function (event) {
        //hidden checkbox en row
        let row = event.currentTarget
        let chkbox = event.currentTarget.lastChild

        // alle checkbox deselecteren en alleen eventtarget kleuren
        let parent = event.currentTarget.parentElement
        let chkboxs = parent.querySelectorAll('.chkbox')
        for (var i = 0; i < chkboxs.length; i++) {
          chkboxs[i].checked = false
          chkboxs[i].parentElement.style.backgroundColor = ''
        }
        chkbox.checked = true
        row.style.backgroundColor = 'lightgreen'
        let selectedId = row.getAttribute('data-id')
        this.$store.commit('setSelectedId', selectedId)
      },
      dblclicked (event) {
        let row = event.currentTarget.querySelectorAll('.gridrowItem')

        for (var i = 0; i < row.length; i++) {
          console.log(row[i].innerHTML)
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
  }

  a {
    color: #42b983;
  }

  .title {
    background-color: burlywood;
  }

  td {
    width: 200px;
  }

  .tableRow:hover {
    background-color: aquamarine;
  }
</style>
