<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>Icon List</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h3>Light icons (fal)</h3>
        <div class="row icon-row" v-if="icons.definitions">
          <div v-for="(icon, key) in sortedIcons(icons.definitions.fal)" class="icon col-12 col-md-4">
            <font-awesome-icon :icon="['fal', key]"></font-awesome-icon>
            <span>{{ key }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h3>Regular icons (far)</h3>
        <div class="row icon-row" v-if="icons.definitions">
          <div v-for="(icon, key) in sortedIcons(icons.definitions.far)" class="icon col-12 col-md-4">
            <font-awesome-icon :icon="['far', key]"></font-awesome-icon>
            <span>{{ key }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h3>Solid icons (fas)</h3>
        <div class="row icon-row" v-if="icons.definitions">
          <div v-for="(icon, key) in sortedIcons(icons.definitions.fas)" class="icon col-12 col-md-4">
            <font-awesome-icon :icon="['fas', key]"></font-awesome-icon>
            <span>{{ key }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { library } from '@fortawesome/fontawesome-svg-core'

  export default {
    name: 'IconList',
    props: {},
    data () {
      return {
        icons: {}
      }
    },
    computed: {
    },
    methods: {
      sortedIcons (obj) {
        let ordered = {}
        Object.keys(obj).sort().forEach(function (key) {
          ordered[key] = obj[key]
        })
        return ordered
      }
    },
    watch: {},
    mounted () {
      this.icons = { ...library }
    }
  }
</script>

<style scoped lang="scss">
  .container {
    margin: 2rem;
  }

  h3 {
    margin-bottom: 2rem;
  }

  .icon {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    svg {
      width: 2rem;
      font-size: 24px;
      margin-right: 1rem;
    }
  }

  .icon-row {
    margin-bottom: 2rem;
  }
</style>