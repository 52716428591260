<template>
  <div :class="['lh-tabs-wrapper', {vertical: obj.attributes.verticalTabs && !isMobile}]">
    <b-navbar toggleable v-if="isMobile" class="mobile-nav">
      <b-navbar-toggle target="navbar-toggle-collapse">
        <template v-slot:default="{ expanded }">
          <transition name="rotate-right">
            <font-awesome-icon v-if="expanded" :icon="['fal', 'times']"></font-awesome-icon>
            <font-awesome-icon v-else :icon="['fas', 'bars']"></font-awesome-icon>
          </transition>
        </template>
      </b-navbar-toggle>

      <b-collapse id="navbar-toggle-collapse" is-nav>
        <b-navbar-nav v-for="(child, key) in obj.children"
                      :class="['ml-auto', {active: tabIndex === key}]"
        >
          <b-nav-item @click="handleTabChange(key)">{{ $t(child.attributes.label) }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div :class="['lh-tabs-nav', {vertical: obj.attributes.verticalTabs && !isMobile}]" v-else>
      <b-nav :tabs="!obj.attributes.verticalTabs"
             justified
             :vertical="obj.attributes.verticalTabs"
             class="lh-tabs-nav"
      >
        <b-nav-item v-for="(child, key) in obj.children"
                    @click="handleTabChange(key)"
                    :active="tabIndex === key"
        >{{ $t(child.attributes.label) }}
        </b-nav-item>
      </b-nav>
    </div>

    <b-tabs
        :class="['lh-tabs', {vertical: obj.attributes.verticalTabs && !isMobile}]"
        :styles="checkEmpty(obj.styles)"
        @input="emitTab"
        :nav-wrapper-class="'d-none'"
        v-model="tabIndex"
    >
      <b-tab
          v-for="child in obj.children"
          :title="$t(child.attributes.label)"
          :key="'Tab' + child.key"
          :active="child.attributes.active"
      >
        <component :is="child.type" :obj="child"></component>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { eventBus } from '../services/event-bus'
import { mapState } from 'vuex'
import confirm from '@/mixin/confirm'

export default {
  name: 'LhTabs',
  mixins: [confirm],
  components: {},
  props: {
    obj: Object
  },
  data () {
    return {
      tabIndex: 0,
      tabKey: ''
    }
  },
  computed: {
    ...mapState({
      popups: 'popups',
      isMobile: 'isMobile'
    })
  },
  methods: {
    handleTabChange (index) {
      let popup = this.popups.find(popup => popup.key === this.obj.attributes.target)
      if (popup && (popup.changed && popup.formTab)) {
        if (popup.formTab === this.obj.children[this.tabIndex].key) {
          let confirmMsg = {
            content: 'GENERAL_CHANGETAB_CONTENT',
            title: 'GENERAL_CHANGETAB_TITLE',
            cancel: 'GENERAL_CHANGETAB_CANCEL',
            confirm: 'GENERAL_CHANGETAB_CONFIRM'
          }
          this.confirm(confirmMsg).then(value => {
            console.log('confirm value: ', value)
            if (value) {
              eventBus.$emit('submitForm' + popup.formTabKey, { hideToast: false, tabIndex: index })
            }
          })
        } else {
          this.emitTab(index)
        }
      } else {
        this.emitTab(index)
      }
    },
    emitTab (index) {
      this.tabIndex = index
      this.tabKey = this.obj.children[index].key
      eventBus.$emit('changedTab', {
        key: this.obj.children[index].key,
        target: this.obj.attributes.target,
        child: this.obj.children[index].children[0].key
      })
      this.$store.commit('setActiveTab', {
        key: this.obj.children[index].key,
        target: this.obj.attributes.target,
        child: this.obj.children[index].children[0].key
      })
    },
    handleSwitchTab(data) {
      if (data.targetTab === this.obj.children[this.tabIndex].key) this.handleTabChange(data.index)
    }
  },
  mounted () {
    eventBus.$on('switch-tab', data => this.handleSwitchTab(data))
    this.$nextTick(() => {
      //wait for footer and header to load
      this.emitTab(this.tabIndex)
      for (let child in this.obj.children) {
        if (this.obj.children[child].attributes && this.obj.children[child].attributes.active) {
          this.emitTab(child)
        }
      }
    })
  },
  beforeDestroy () {
    eventBus.$off('switch-tab', data => this.handleSwitchTab(data))
  }
}
</script>

<style lang="scss">
.lh-tabs-wrapper {
  &.vertical {
    display: flex;

    .inside-mobile & {
      flex-flow: column nowrap;
    }
  }

  height: 100%;

  .lh-tabs {
    &.vertical {
      flex-grow: 1;
      margin-left: 2rem;
      max-width: calc(100% - #{$sidebar-max-width});
    }
  }

  .lh-tabs-nav {
    a {
      text-decoration: none;
    }

    .nav-tabs {
      margin-bottom: 1.5rem;
      border-bottom-color: $tabs-accent-color;
      border-bottom-width: 2px;

      &:focus, a:focus {
        outline: none;
      }
    }

    .nav-link {
      font-weight: 700;
      color: $tabs-color;

      &.active {
        color: $white;
        background: $tabs-accent-color;
        border-color: $tabs-accent-color;
      }

      &:hover, &:focus {
        border-bottom: none;
        border-color: $tabs-accent-color;
      }
    }

    .nav-pills {
      margin-bottom: 0.5rem;

      .nav-item {
        min-width: 100%;
        border: 1px solid $light-grey;

        &:not(:first-child) {
          border-top: none;
        }
      }

      .nav-link {
        &.active {
          background: $tabs-accent-color;
          color: $font-color-light;
          border: 1px solid $tabs-accent-color;
        }
      }
    }

    &.vertical {
      margin-left: -15px;

      .nav-link {
        background-color: unset;
        color: $headings-color;
        border: none;
        position: relative;

        &.active:before {
          content: ' ';
          position: absolute;
          display: inline-block;
          left: 0;
          top: 0.8rem;
          border-style: solid;
          border-width: 0.45rem 0 0.45rem 0.6rem;
          border-color: transparent transparent transparent $headings-color;
          border-radius: 0.18rem;

          &.popup-content {
            left: -7px;
          }
        }
      }

      .lh-tabs-nav {
        width: $sidebar-max-width;
        height: 100%;
        border-right: 2px solid $light-grey;
        padding-right: 5px;
        border-bottom: none;
      }
    }

    &:focus, a:focus {
      outline: none;
    }
  }

  .navbar {
    padding: 0 0 5px 0;
    margin-bottom: 5px;

    a {
      text-decoration: none;
    }

    .navbar-toggler {
      margin-left: auto;
      outline: none;
      border-color: $light-grey;
      font-size: 25px;
      margin-bottom: 5px;
      min-width: 50px;
    }

    .active {
      .nav-item {
        a {
          color: $white;
        }

        background: $tabs-accent-color;
      }
    }

    .navbar-nav:not(:last-child) {
      .nav-item {
        border-bottom: none;
      }
    }

    .nav-item {
      font-weight: 700;
      border: 1px solid $light-grey;
      padding: 0 1rem;


      &:focus, a:focus {
        outline: none;
      }
    }
  }
}

</style>
