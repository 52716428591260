<template>
  <div :class="['popup-content', checkClasses]" :style="checkStyles">
    <component v-for="child in obj.children" :is="child.type" :key="'pop' + child.key" :obj="child"></component>
  </div>
</template>

<script>
  export default {
    name: 'LhPopupContent',
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {}
    },
    computed: {},
    methods: {},
    watch: {}
  }
</script>

<style lang="scss">
  .popup-content {
    padding: 1.5rem 2rem 1rem 2rem;
    overflow-y: auto;
    max-height: calc(100% - #{$popup-header-height} - #{$popup-footer-height});
    max-width: 100%;
    height: calc(100% - #{$popup-header-height} - #{$popup-footer-height});

    &.inside-mobile{
      padding: 1rem 1rem;
    }

    .pagesize-select {
      position: initial;
    }
  }
</style>
