<template>
  <div class="cards">
    <ul>
      <li v-for="item in obj.attributes.content" :key="item.key" :obj="item">
        <p
            v-for="(value, name) in item"
            :key="value"
        ><b>{{ name }}</b>: {{ value }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'LhCards',
    components: {},
    props: {
      obj: {
        type: Object
      }
    },
    computed: {
      notPassOrGuid () {
        return
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .cards ul {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    padding: 0 1em;
    list-style: none;
  }

  .cards ul li {
    align-self: flex-start;
    background: white;
    border: 2px solid black;
    text-align: left;
    padding: 1em;
    width: 300px;
    word-break: break-all;
    -webkit-box-shadow: 9px 9px 5px -1px rgba(199, 199, 199, 0.6);
    -moz-box-shadow: 9px 9px 5px -1px rgba(199, 199, 199, 0.6);
    box-shadow: 9px 9px 5px -1px rgba(199, 199, 199, 0.6);
  }
</style>
