<template>
  <h1>Logging out...</h1>
</template>


<script>
  export default {
    mounted () {
      let action = {
        method: 'POST',
        type: 'redirect',
        url: '/logout'
      }
      // clear all
      //this.$store.commit('clearAll')
      this.$store.commit('setMainJSON', {})
      this.$store.dispatch('closeAllPopup')
      this.$store.dispatch(action.type, action).then(response => {
        this.$store.dispatch('destroyToken').then(() => {
          if (response.redirect) {
            this.$router.push({path: response.redirect})
          }
        })
      })
    }
  }
</script>
