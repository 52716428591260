//axios error interceptor
// all http errors are handled by the interceptor. To make an axios call without interceptor, import /http/customerror.js
import axios from 'axios'
import { eventBus } from '@/services/event-bus'
import store from '@/store/store'
import router from '@/router'

axios.interceptors.response.use(function (response) {
  if (response && response.data) {
    if (response.data.options) {
      let payload = {
        translations: response.data.translations,
        locale: response.data.options.active_language
      }

      if (response.data.translationParams && response.data.options && response.data.options.active_language) {
        let params = response.data.translationParams
        for (let language in payload.translations) {
          for (let param in params) {
            payload.translations[language][param] = params[param]
          }
        }
      }
      eventBus.$emit('translate', payload)
    }

    let redirectCheckApi = store.state.redirectCheckApi
    // redirectCheckApi -> check if manually changing route
    console.log(redirectCheckApi)
    if (redirectCheckApi === false && router.currentRoute.path !== '/login' && response.data.redirect == '/login') {
      console.log('session expired')
      return store.dispatch('destroyToken').then(() => {
        router.push(response.data.redirect)
        return null
      })
    }
    store.commit('setRedirectCheckApi', false)
  }

  // let page = response.data.page ? response.data.page : response.data
  return response
}, function (error) {
  eventBus.$emit('error-message', error.response.data)
  return Promise.reject(error)
})