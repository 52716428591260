<template>
<div :style="checkStyles"
     :class="['html-block', checkClasses]"
>
  <span v-html="obj.attributes.content"></span>
</div>
</template>

<script>
export default {
  name: 'LhHtmlBlock',
  props: {
    obj: {
      type: Object
    }
  },
  data () {
    return {}
  },
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style lang="scss">
.html-block {
  width: 100%;

  tbody:nth-of-type(odd):not(.row_selected) {
    background: none;
  }
  tbody {
    border-top: none;
    min-height: 0;
  }
  .row {
    margin-right: 0;
    margin-left: 0;
  }
}
</style>