<template>
  <div class="lh-text-area" :id='obj.key'>
    <label :class="obj.attributes.class">{{$t(obj.attributes.content)}}</label>
  </div>
</template>

<script>
  export default {
    name: 'LhTextArea',
    components: {},
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>
