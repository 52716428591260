<template>
  <div :class="[{collapsed: collapsed}, 'collapse-content']" id="sidebarCollapse" :style="checkStyles">
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'SidebarScrollContent',
    props: {
      obj: {
        type: Object
      }
    },
    computed: {
      ...mapState({
        collapsed: 'sidebarCollapse'
      })
    }
  }
</script>

<style lang="scss">
  $collapse-transition: width ease-in-out 300ms, height ease-in-out 300ms, opacity ease-in-out 300s;

  .collapse-content {
    width: 100%;
    transition: $collapse-transition;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    overflow: auto;
    height: 100%;

    > * {
      //transition: $collapse-transition;
    }

    @include media-breakpoint-up(md) {
      align-items: flex-start;
      margin-bottom: auto;
    }

    a {
      /*overflow: hidden;*/
      > span {
        //transition: $collapse-transition;
        width: 100%;
      }
    }

    li ul {
      //transition: $collapse-transition;
    }

    &.collapsed {
      //@include media-breakpoint-up(md) {

      //  align-items: center;
      //  > * {
     //     width: 0;
     //     height: 0;
     //   }


     //   ul, .button-group {

      //    width: $sidebar-min-width;
      //    height: auto;

     //   }

      //  a {
     //     justify-content: center;

     //     > span {
     //       opacity: 0;
      //      width: 0;
      //      height: 0;
     //    }
      //  }

     //   li ul {
    //      height: 0;
     //   }
     // }
    }
  }
</style>
