<template>
  <div class="multi-step-form">
    <div class="multi-step-form__header">
      <h2>{{ $t(obj.attributes.title) }}</h2>
      <div class="steps">
        <div :class="['step', { current: step === parseFloat(obj.attributes.currentStep) } ]" v-for="step in obj.attributes.steps">
          {{ step }}
        </div>
      </div>
    </div>
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
  </div>
</template>

<script>
export default {
  name: 'MultiStepForm',
  props: {
    obj: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style scoped lang="scss">
  .multi-step-form {
    display: flex;
    flex-flow: column nowrap;
    &__header {
      display: flex;
      justify-content: space-between;
    }
  }
  .title {

  }
  .steps {
    display: flex;
  }
  .step {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $medium-light-grey;
    font-size: 1.3rem;
    line-height: 1;
    font-weight: 700;
    color: $white;
    position: relative;

    &.current {
      background: $primary-color;
    }

    &:not(:last-child) {
      margin-right: 2rem;
      &::after {
        content: '';
        background: $medium-light-grey;
        width: 2rem;
        height: 2px;
        position: absolute;
        right: -2rem;
        top: calc(50% - 1px)
      }
    }
  }

</style>