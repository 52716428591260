<template>
  <div
      class="icon-button"
      v-on="allEvents"
      @click="[checkForModal, allEvents]"
      :value="checkEmpty(obj.attributes.value)"
      :style="checkEmpty(obj.styles)"
      :class="[checkClasses, checkDelete, checkDisabled]"
      v-tooltip.right-start="checkTooltip"
  >
    <font-awesome-layers>
      <font-awesome-icon :icon="setIcon"/>
      <span class="fa-layers-counter" v-if="checkEmpty(obj.attributes.counter)">{{ obj.attributes.counter }}</span>
    </font-awesome-layers>
  </div>
</template>

<script>
  import mixinFunctions from '@/mixin/mixinFunctions'
  import confirm from '@/mixin/confirm'

  export default {
    name: 'IconButton',
    mixins: [
        mixinFunctions, confirm
    ],
    props: {
      obj: {
        type: Object
      },
      modalId: {
        type: String
      }
    },
    computed: {
      setIcon () {
        return this.obj.attributes.icon ? this.obj.attributes.icon : 'circle'
      },
      checkDelete () {
        return this.obj.attributes.type === 'delete' ? 'btn-delete' : ''
      },
      checkForModal () {
        if (this.modalId) {
          this.$root.$emit('bv::hide::modal', this.modalId)
        }
      },
      checkDisabled () {
        return this.obj.attributes.disabled === true ? 'btn-disabled' : ''
      }
    }
  }
</script>

<style scoped lang="scss">
  .icon-button {
    font-size: 32px;
    color: $secondary-color;
    cursor: pointer;

    .input-icon-slot & {
      font-size: 16px;
      &:not(:first-child) {
        margin-left: 5px;
      }
    }

    &.btn-disabled {
      pointer-events: none;
      * {
        color: $medium-light-grey;
      }
    }
  }

  .fa-layers-counter {
    background: $secondary-color;
    transform: scale(0.3);
  }
</style>
