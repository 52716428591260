<template>
  <div class="header" :style="checkStyles" :class="checkClasses">
    <h3 :id="obj.key">{{$t(obj.attributes.content)}}</h3>
    <component v-for="item in obj.children" :is="item.type" :key="item.key" :obj="item"></component>
  </div>
</template>

<script>
  export default {
    name: 'Header',
    components: {},
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
