<template>
    <div>
        <component
            v-if="notEmpty(testJSON)"
            :is="testJSON.type"
            :key="testJSON.key"
            :obj="testJSON"
        ></component>
    </div>
</template>

<script>

    export default {
        data () {
            return {
                testJSON: {
                    'key': 'Root',
                    'type': 'LhRoot',
                    'actions': [],
                    'attributes':
                        {
                            'version':
                                '0.0.1',
                            'pageTitle': 'About dev',
                            'backgroundUrl': 'https://api.dev.automationtool.technieken.be/assets/img/lighthouse.svg'
                        }
                    ,
                    'styles':
                        [], 'children':
                        [{
                            'key': 'Root_Sidebar',
                            'type': 'LhSidebar',
                            'actions': [],
                            'attributes': [],
                            'styles': [],
                            'children': [{
                                'key': 'root-menu-logo-client',
                                'type': 'LhImage',
                                'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                                'attributes': {
                                    'src': 'https://api.dev.automationtool.technieken.be/assets/img/logo.svg',
                                    'collapse-src': 'https://api.dev.automationtool.technieken.be/assets/img/logo_small.svg',
                                    'class': 'client-logo'
                                },
                                'styles': { 'cursor': 'pointer' },
                                'children': []
                            },
                                {
                                    'key': 'root-menu-scroll-content',
                                    'type': 'LhSidebarScrollContent',
                                    'actions': [],
                                    'attributes': [],
                                    'styles': [],
                                    'children': [{
                                        'key': 'root-menu-nav-list',
                                        'type': 'LhNavList',
                                        'actions': [],
                                        'attributes': [],
                                        'styles': [],
                                        'children': [{
                                            'key': 'root-menu-nav-list-item-1',
                                            'type': 'LhNavListItem',
                                            'actions': [],
                                            'attributes': { 'content': 'SIDEBAR_INDEX', 'action': '/', 'icon': 'home' },
                                            'styles': [],
                                            'children': []
                                        }, {
                                            'key': 'root-menu-nav-list-item-2',
                                            'type': 'LhNavListItem',
                                            'actions': [],
                                            'attributes': { 'content': 'SIDEBAR_CLIENTS', 'action': '/clients', 'icon': 'file-alt' },
                                            'styles': [],
                                            'children': []
                                        }, {
                                            'key': 'root-menu-nav-list-item-3',
                                            'type': 'LhNavListItem',
                                            'actions': [],
                                            'attributes': { 'content': 'SIDEBAR_PROJECTS', 'action': '/projects', 'icon': 'chart-pie' },
                                            'styles': [],
                                            'children': []
                                        }, {
                                            'key': 'root-menu-nav-list-item-4',
                                            'type': 'LhNavListItem',
                                            'actions': [],
                                            'attributes': { 'content': 'SIDEBAR_PAGES', 'action': '/pages', 'icon': 'users' },
                                            'styles': [],
                                            'children': []
                                        }, {
                                            'key': 'root-menu-nav-list-item-5',
                                            'type': 'LhNavListItem',
                                            'actions': [],
                                            'attributes': { 'content': 'SIDEBAR_CONFIG', 'action': '/users', 'icon': 'cog' },
                                            'styles': [],
                                            'children': [{
                                                'key': 'root-menu-nav-sub-list-5',
                                                'type': 'LhNavList',
                                                'actions': [],
                                                'attributes': [],
                                                'styles': [],
                                                'children': [{
                                                    'key': 'root-menu-nav-sub-list-5-item-1',
                                                    'type': 'LhNavListItem',
                                                    'actions': [],
                                                    'attributes': { 'content': 'SIDEBAR_USERS', 'action': '/users' },
                                                    'styles': [],
                                                    'children': []
                                                }, {
                                                    'key': 'root-menu-nav-sub-list-5-item-2',
                                                    'type': 'LhNavListItem',
                                                    'actions': [],
                                                    'attributes': { 'content': 'SIDEBAR_BLOCKS', 'action': '/pageblocks' },
                                                    'styles': [],
                                                    'children': []
                                                }]
                                            }]
                                        }]
                                    }]
                                },
                                {
                                    'key': 'root-menu-logo-group',
                                    'type': 'LhDiv',
                                    'actions': [],
                                    'attributes': [],
                                    'styles': [],
                                    'children': [{
                                        'key': 'root-menu-logo-lighthouse',
                                        'type': 'LhImage',
                                        'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                                        'attributes': {
                                            'src': 'https://api.dev.automationtool.technieken.be/assets/img/lighthouse_logo_grey.svg',
                                            'class': 'lighthouse-logo'
                                        },
                                        'styles': { 'cursor': 'pointer' },
                                        'children': []
                                    }]
                                }, {
                                    'key': 'root-menu-button-group',
                                    'type': 'LhButtonGroup',
                                    'actions': [],
                                    'attributes': [],
                                    'styles': [],
                                    'children': [{
                                        'key': 'root-menu-col-1',
                                        'type': 'LhCol',
                                        'actions': [],
                                        'attributes': [],
                                        'styles': [],
                                        'children': [{
                                            'key': 'root-menu-user-button',
                                            'type': 'LhIconButton',
                                            'actions': [{ 'url': '/settings', 'type': 'update', 'method': 'GET', 'event': 'click' }],
                                            'attributes': { 'icon': 'user-cog', 'class': 'sidebar-user-settings-button' },
                                            'styles': [],
                                            'children': []
                                        }]
                                    }, {
                                        'key': 'root-menu-col-2',
                                        'type': 'LhCol',
                                        'actions': [],
                                        'attributes': [],
                                        'styles': [],
                                        'children': [
                                            {
                                                'key': 'root-menu-logout-button',
                                                'type': 'LhIconButton',
                                                'actions': [{ 'url': '/logout', 'type': 'redirect', 'method': 'POST', 'event': 'click' }],
                                                'attributes': { 'icon': 'sign-out-alt', 'class': 'sidebar-user-logout-button' },
                                                'styles': [],
                                                'children': []
                                            }
                                        ]
                                    },
                                        {
                                            'key': 'root-menu-col-3',
                                            'type': 'LhCol',
                                            'actions': [],
                                            'attributes': [],
                                            'styles': [],
                                            'children': [
                                                {
                                                    'key': 'toggle-translations-button',
                                                    'type': 'LhIconButton',
                                                    'actions': [
                                                        {
                                                            event: 'click',
                                                            method: 'translations-toggle-view',
                                                            type: 'emit',
                                                            url: ''
                                                        }
                                                    ],
                                                    'attributes': {
                                                        'name': 'toggle-translations-button',
                                                        icon: 'globe',
                                                        transparent: true,
                                                        disabled: this.loadingJSON
                                                    },
                                                    'styles': [],
                                                    'children': []
                                                }
                                            ]
                                        },
                                        {
                                            'key': 'root-menu-col-4',
                                            'type': 'LhCol',
                                            'actions': [],
                                            'attributes': [],
                                            'styles': [],
                                            'children': [
                                                {
                                                    'key': 'translations-popup-button',
                                                    'type': 'LhIconButton',
                                                    'actions': [
                                                        {
                                                            event: 'click',
                                                            method: '',
                                                            type: '',
                                                            url: ''
                                                        }
                                                    ],
                                                    'attributes': {
                                                        'name': 'translations-popup-button',
                                                        icon: 'file-alt',
                                                        transparent: true,
                                                        disabled: this.loadingJSON
                                                    },
                                                    'styles': [],
                                                    'children': []
                                                }
                                            ]
                                        }
                                    ]
                                }]
                        },
                            {
                                "key":"main-admin-dashboard",
                                "type":"LhMainPage",
                                "actions":[

                                ],
                                "attributes":[

                                ],
                                "styles":[

                                ],
                                "children":[
                                    {
                                        "key": "pharmacy-edit-form-sub-row-1",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [{
                                            "key": "pharmacy-dashboard-tab-title",
                                            "type": "LhH1",
                                            "actions": [],
                                            "attributes": {
                                                "content": "Components"
                                            },
                                            "styles": [],
                                            "children": []
                                        }]
                                    },
                                    {
                                        "key": "pharmacy-edit-form-sub-row-1111",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [{
                                            "key": "pharmacy-dashboard-tab-title1111",
                                            "type": "LhH2",
                                            "actions": [],
                                            "attributes": {
                                                "content": "Inputs"
                                            },
                                            "styles": [],
                                            "children": []
                                        }]
                                    },
                                    /* ## START PAGE CONTENT ## */
                                    /* LhInput -- TEXT */
                                    {
                                        "key": "pharmacy-edit-form-sub-row-1112",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-detail-sub-col-1112-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patient-id-label1112",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "TEXT"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-detail-sub-col-1113-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "pharmacy-code11113",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "text",
                                                            "label": "Label",
                                                            "name": "code",
                                                            "id": "pharmacy-code-cru",
                                                            "value": "",
                                                            "disabled": false,
                                                            "validation": [
                                                                {
                                                                    "type": "required",
                                                                    "translation_key": "GENERAL_VALIDATION_REQUIRED",
                                                                    "translation_key_custom": null
                                                                }
                                                            ],
                                                            "prefix": "Netto",
                                                            "postfix": "Kg",
                                                            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget orci tristique, convallis felis eu, suscipit urna. Pellentesque finibus vehicula porttitor. Nulla facilisi. Nam et nibh eros. Pellentesque sodales diam ac eros pharetra, id porttitor elit rutrum.",
                                                            "placeholder": "123 (placeholder)"
                                                        },
                                                        "styles": [],
                                                        "children": []
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- TEXT-SELECT */
                                    {
                                        "key": "pharmacy-edit-form-sub-row-1112",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-detail-sub-col-1112-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patient-id-label1112",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "TEXT-SELECT"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-detail-sub-col-1113-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "pharmacy-code111132",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "text-select",
                                                            "label": "Label",
                                                            "disabled": false,
                                                            "prefix": "Netto",
                                                            "postfix": "Kg",
                                                            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget orci tristique, convallis felis eu, suscipit urna. Pellentesque finibus vehicula porttitor. Nulla facilisi. Nam et nibh eros. Pellentesque sodales diam ac eros pharetra, id porttitor elit rutrum."
                                                        },
                                                        "styles": [],
                                                        "children": [
                                                            {
                                                                "key": "pharmacy-code111132",
                                                                "type": "LhInput",
                                                                "actions": [],
                                                                "attributes": {
                                                                    "type": "text",
                                                                    "name": "code",
                                                                    "id": "pharmacy-code-cru",
                                                                    "value": "",
                                                                    "disabled": false,
                                                                    "validation": [
                                                                        {
                                                                            "type": "required",
                                                                            "translation_key": "GENERAL_VALIDATION_REQUIRED",
                                                                            "translation_key_custom": null
                                                                        }
                                                                    ],
                                                                    "placeholder": "123 (placeholder)"
                                                                }
                                                            },
                                                            {
                                                                "key": "languageSearch111152",
                                                                "type": "LhInput",
                                                                "actions": [],
                                                                "attributes": {
                                                                    "type": "select",
                                                                    "name": "languageSearch",
                                                                    "id": "pharmacy-languageId-cru",
                                                                    "content": [
                                                                        {
                                                                            "value": "3b324c49e24ce9e6cb12ef1d6a09d1793b85429150d341e97fb05df30506797afd41d0cfaa7b6810666c3c7d73c9e6e6fa4a8b7e5c2dd4bf390365f4522851de",
                                                                            "text": "Kg"
                                                                        },
                                                                        {
                                                                            "value": "47f912585f58ec5fdf59c17c738496a2e48fa40fba38c69ab42f68b0d1ea1d758e35bdab7cd09520fcc025f7f5999347782f03e7940329b85935edc36c866835",
                                                                            "text": "Mg"
                                                                        }
                                                                    ],
                                                                    "value": "3b324c49e24ce9e6cb12ef1d6a09d1793b85429150d341e97fb05df30506797afd41d0cfaa7b6810666c3c7d73c9e6e6fa4a8b7e5c2dd4bf390365f4522851de",
                                                                    "disabled": false,
                                                                    "validation": [
                                                                        {
                                                                            "type": "required",
                                                                            "translation_key": "GENERAL_VALIDATION_REQUIRED",
                                                                            "translation_key_custom": null
                                                                        }
                                                                    ]
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- TEXTAREA */
                                    {
                                        "key": "pharmacy-edit-form-sub-row-11114",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-detail-sub-col-1114-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patient-id-label11114",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "TEXTAREA"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-detail-sub-col-1-left11114",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "pharmacy-memo111114",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "textarea",
                                                            "label": "Label",
                                                            "name": "memo",
                                                            "id": "pharmacy-memo-cru",
                                                            "value": null,
                                                            "disabled": false,
                                                            "rows": 6
                                                        },
                                                        "styles": [],
                                                        "children": []
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- SELECT */
                                    {
                                        "key": "pharmacy-edit-form-sub-row-11115",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-detail-sub-col-111115-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patient-id11115-label",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "SELECT"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-detail-sub-col-111151-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "languageSearch11115",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "select",
                                                            "label": "Label",
                                                            "name": "languageSearch",
                                                            "id": "pharmacy-languageId-cru",
                                                            "content": [
                                                                {
                                                                    "value": "3b324c49e24ce9e6cb12ef1d6a09d1793b85429150d341e97fb05df30506797afd41d0cfaa7b6810666c3c7d73c9e6e6fa4a8b7e5c2dd4bf390365f4522851de",
                                                                    "text": "Nederlands"
                                                                },
                                                                {
                                                                    "value": "47f912585f58ec5fdf59c17c738496a2e48fa40fba38c69ab42f68b0d1ea1d758e35bdab7cd09520fcc025f7f5999347782f03e7940329b85935edc36c866835",
                                                                    "text": "English"
                                                                },
                                                                {
                                                                    "value": "2f733de22a1f9d69443aa5b7e8cd4c8021d439b0008b3f979d1d246013c9dadca9cf41d36ad00341dc3be8118ed05a09e8f869dc8f4ddf3b5239b18e529fbec1",
                                                                    "text": "Fran\u00e7ais"
                                                                },
                                                                {
                                                                    "value": "78c6a7621a0f448da95e4301b498accc78c6a7621a0f448da95e4301b498accc78c6a7621a0f448da95e4301b498accc78c6a7621a0f448da95e4301b498accc",
                                                                    "text": "Italian"
                                                                },
                                                                {
                                                                    "value": "yciqv4567876543gvougcvovo45678",
                                                                    "text": "Deutsch"
                                                                }
                                                            ],
                                                            "value": "3b324c49e24ce9e6cb12ef1d6a09d1793b85429150d341e97fb05df30506797afd41d0cfaa7b6810666c3c7d73c9e6e6fa4a8b7e5c2dd4bf390365f4522851de",
                                                            "disabled": false,
                                                            "validation": [
                                                                {
                                                                    "type": "required",
                                                                    "translation_key": "GENERAL_VALIDATION_REQUIRED",
                                                                    "translation_key_custom": null
                                                                }
                                                            ],
                                                            "labelCols": 12,
                                                            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget orci tristique, convallis felis eu, suscipit urna. Pellentesque finibus vehicula porttitor. Nulla facilisi. Nam et nibh eros. Pellentesque sodales diam ac eros pharetra, id porttitor elit rutrum."
                                                        },
                                                        "styles": [],
                                                        "children": []
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- CHECKBOX */
                                    {
                                        "key": "pharmacy-edit-form-sub-row-111116",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-detail-sub-col-111116-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patient-id11116-label",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "CHECKBOX"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-detail-sub-col-111116.2-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "pharmacy-11116insideSales",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "checkbox",
                                                            "label": "TEST 123",
                                                            "name": "insideSales",
                                                            "id": "pharmacy-insideSales-cru",
                                                            "value": "1",
                                                            "disabled": false,
                                                            "stacked": false,
                                                            "switchCheckbox": false,
                                                            "labelPlaceholder": true,
                                                            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget orci tristique, convallis felis eu, suscipit urna. Pellentesque finibus vehicula porttitor. Nulla facilisi. Nam et nibh eros. Pellentesque sodales diam ac eros pharetra, id porttitor elit rutrum."
                                                        },
                                                        "styles": [],
                                                        "children": [
                                                            {
                                                                "key": null,
                                                                "type": null,
                                                                "actions": [],
                                                                "attributes": {
                                                                    "value": "1",
                                                                    "content": "PHARMACY_INSIDESALES"
                                                                },
                                                                "styles": [],
                                                                "children": []
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "key": "dynamic-form-field-checkbox-201",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "checkbox",
                                                            "label": "test123",
                                                            "id": "6a87d29e4c858e99dff248ddfbf0e8704d963620709a11349196ae140f4a8ecbfcc31b7caf3d749c9f537e4f47be6f97b89fe3be765085a84abaff3f0b06e6fd",
                                                            "name": "dynamicForm[6fb3b775255f6e082ccd33b0d53139bd53c0c8cbc149ed8f727dfda766f7aacc759e85b5c3fd488f2851baa17fff0af4909e625f004cf673973a68e5bb40123a][6a87d29e4c858e99dff248ddfbf0e8704d963620709a11349196ae140f4a8ecbfcc31b7caf3d749c9f537e4f47be6f97b89fe3be765085a84abaff3f0b06e6fd][checkbox]",
                                                            "value": null,
                                                            "validation": [],
                                                            "placeholder": "",
                                                            "disabled": false,
                                                            "labelCols": 12,
                                                            "stacked": true,
                                                            "labelPlaceholder": true
                                                        },
                                                        "styles": [],
                                                        "children": [
                                                            {
                                                                "key": null,
                                                                "type": null,
                                                                "actions": [
                                                                    {
                                                                        "display": {
                                                                            ".dynamic-action-212": true
                                                                        },
                                                                        "required": {
                                                                            ".dynamic-action-212": false
                                                                        }
                                                                    }
                                                                ],
                                                                "attributes": {
                                                                    "id": "ced07193f6eba1522506f3f72b036decc64372ca067a7b870f725af5d6853a35de2e1607ff7ee1f1bfca3f7c244aceb0743b99179b6100faedd729b2df7e23fa",
                                                                    "value": "ced07193f6eba1522506f3f72b036decc64372ca067a7b870f725af5d6853a35de2e1607ff7ee1f1bfca3f7c244aceb0743b99179b6100faedd729b2df7e23fa",
                                                                    "content": "1",
                                                                    "disabled": false,
                                                                    "labelIcon": {
                                                                        "icon": ['fas', 'info-circle'],
                                                                        "tooltip": "PHARMACY_NEXT_VISIT_TOOLTIP"
                                                                    },
                                                                    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget orci tristique, convallis felis eu, suscipit urna. Pellentesque finibus vehicula porttitor. Nulla facilisi. Nam et nibh eros. Pellentesque sodales diam ac eros pharetra, id porttitor elit rutrum."
                                                                },
                                                                "styles": [],
                                                                "children": []
                                                            },
                                                            {
                                                                "key": null,
                                                                "type": null,
                                                                "actions": [
                                                                    {
                                                                        "display": {
                                                                            ".dynamic-action-213": true
                                                                        },
                                                                        "required": {
                                                                            ".dynamic-action-213": false
                                                                        }
                                                                    }
                                                                ],
                                                                "attributes": {
                                                                    "id": "10abb97d9ea226ed8a7dac31142002aca86f5a49b98a1a994903a9a3b49a6a670808685e56ded09902c2ccff9da38c52d85d9b69d51a71db71a7a57e1caa327f",
                                                                    "value": "10abb97d9ea226ed8a7dac31142002aca86f5a49b98a1a994903a9a3b49a6a670808685e56ded09902c2ccff9da38c52d85d9b69d51a71db71a7a57e1caa327f",
                                                                    "content": "2",
                                                                    "disabled": false
                                                                },
                                                                "styles": [],
                                                                "children": []
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- RADIO */
                                    {
                                        "key": "pharmacy-edit-form-sub-row-111117",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-detail-sub-col-111117-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patient-11117id-label",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "RADIO"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-detail-sub-col-111117.2-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "professional-radio",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "radio",
                                                            "label": "RADIO BUTTONS",
                                                            "name": "sex",
                                                            "id": "professional-radio-cru",
                                                            "value": "",
                                                            "disabled": false,
                                                            //"stacked": "true",
                                                            "validation": [],
                                                            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget orci tristique, convallis felis eu, suscipit urna. Pellentesque finibus vehicula porttitor. Nulla facilisi. Nam et nibh eros. Pellentesque sodales diam ac eros pharetra, id porttitor elit rutrum."
                                                        },
                                                        "styles": [],
                                                        "children": [
                                                            {
                                                                "key": null,
                                                                "type": null,
                                                                "actions": [],
                                                                "attributes": {
                                                                    "value": "M",
                                                                    "content": "Radio 1",
                                                                    "labelIcon": {
                                                                        "icon": ['fas', 'info-circle'],
                                                                        "tooltip": "PHARMACY_NEXT_VISIT_TOOLTIP"
                                                                    },
                                                                    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget orci tristique, convallis felis eu, suscipit urna. Pellentesque finibus vehicula porttitor. Nulla facilisi. Nam et nibh eros. Pellentesque sodales diam ac eros pharetra, id porttitor elit rutrum."
                                                                },
                                                                "styles": [],
                                                                "children": []
                                                            },
                                                            {
                                                                "key": null,
                                                                "type": null,
                                                                "actions": [],
                                                                "attributes": {
                                                                    "value": "V",
                                                                    "content": "Radio 2"
                                                                },
                                                                "styles": [],
                                                                "children": []
                                                            },
                                                            {
                                                                "key": null,
                                                                "type": null,
                                                                "actions": [],
                                                                "attributes": {
                                                                    "value": "O",
                                                                    "content": "Radio 3"
                                                                },
                                                                "styles": [],
                                                                "children": []
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- CHECKBOX: TOGGLE */
                                    {
                                        "key": "pharmacy-edit-form-sub-row-11118",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-detail-sub-col-1-left11118",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patient-id-labe11118l",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "CHECKBOX: TOGGLE"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharma11118cy-detail-sub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "pharmacy-display-futuro-toggle11118",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "checkbox",
                                                            "labelPlaceholder": false,
                                                            "label": "",
                                                            "name": "displayFuturo",
                                                            "id": "pharmacy-display-futuro-toggle",
                                                            "value": false,
                                                            "stacked": false,
                                                            "switchCheckbox": true,
                                                            "disabled": false
                                                        },
                                                        "styles": {
                                                            "marginTop": "0.5rem"
                                                        },
                                                        "children": [
                                                            {
                                                                "key": null,
                                                                "type": null,
                                                                "actions": [],
                                                                "attributes": {
                                                                    "value": "1",
                                                                    "content": "PHARMACY_DISPLAY_FUTURO"
                                                                },
                                                                "styles": [],
                                                                "children": []
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- DATEPICKER */
                                    {
                                        "key": "pharmacy-edit-form-sub-row-111119",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-detail-sub-col-111119-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patient-id11119-label",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "DATEPICKER"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-detail-sub-col-111119.2-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "pharmacy-11119nextVisitDate",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "date",
                                                            "label": "Label",
                                                            "name": "nextVisitDate",
                                                            "id": "pharmacy-nextVisitDate-cru",
                                                            "value": "2022-09-23",
                                                            "disabled": false,
                                                            "labelIcon": {
                                                                "icon": ['fas', 'info-circle'],
                                                                "tooltip": "PHARMACY_NEXT_VISIT_TOOLTIP"
                                                            },
                                                            "prefix": "Before",
                                                            "postfix": "After",
                                                            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget orci tristique, convallis felis eu, suscipit urna. Pellentesque finibus vehicula porttitor. Nulla facilisi. Nam et nibh eros. Pellentesque sodales diam ac eros pharetra, id porttitor elit rutrum.",
                                                        },
                                                        "styles": [],
                                                        "children": []
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- DATERANGE */
                                    {
                                        "key": "pharmacy-edit-form-sub-row-111120",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-detail-sub-col-111120-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patient-id-labe11120l",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "DATERANGE"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-detail-sub-col-1-left11120",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "sendbox-date-sent11120",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "dateRange",
                                                            "label": "Label",
                                                            "name": "dateSent",
                                                            "id": "dateSent-filter",
                                                            "value": null,
                                                            "yearFirst": true,
                                                            "timepicker": false,
                                                            "labelCols": 4,
                                                            "prefix": "Before",
                                                            "postfix": "After",
                                                            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget orci tristique, convallis felis eu, suscipit urna. Pellentesque finibus vehicula porttitor. Nulla facilisi. Nam et nibh eros. Pellentesque sodales diam ac eros pharetra, id porttitor elit rutrum.",
                                                        },
                                                        "styles": [],
                                                        "children": []
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- TIMEPICKER */
                                    {
                                        "key": "pharmacy-edit-form-sub-row-111121",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-detail-sub-col-1111211-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patient-id-111121label",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "TIMEPICKER"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-detail-111121sub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "appointment-111121start-time",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "time",
                                                            "label": "Label",
                                                            "name": "time",
                                                            "id": "appointment-start-time-cru",
                                                            "value": {
                                                                "HH": "11",
                                                                "mm": "00"
                                                            },
                                                            "disabled": false,
                                                            "prefix": "Before",
                                                            "postfix": "After",
                                                            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget orci tristique, convallis felis eu, suscipit urna. Pellentesque finibus vehicula porttitor. Nulla facilisi. Nam et nibh eros. Pellentesque sodales diam ac eros pharetra, id porttitor elit rutrum.",
                                                            "interval": 1
                                                        },
                                                        "styles": [],
                                                        "children": []
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- COLORPICKER */
                                    {
                                        "key": "pharmacy-edit-form-sub-row-111122",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-detail-sub-col-1111122-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patient-id-label111122",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "COLORPICKER"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-detail-sub-col-1111221-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "action-111122colour",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "colorpicker",
                                                            "label": "Label",
                                                            "name": "colour",
                                                            "id": "action-colour-cru",
                                                            "value": null,
                                                            "disabled": false
                                                        },
                                                        "styles": [],
                                                        "children": []
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- RANGE */
                                    {
                                        "key": "pharmacy-edit-form-sub-row-1111123",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-detail-sub-col111123-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patient-id-l111123abel",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "RANGE"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-detail-sub-col-1111123-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        'key': 'unavailabilitytype-range',
                                                        'type': 'LhInput',
                                                        'actions': [],
                                                        'attributes': {
                                                            'type': 'range',
                                                            'label': 'RANGE',
                                                            'name': 'range',
                                                            'id': 'unavailabilitytype-range',
                                                            'value': '55',
                                                            'disabled': false,
                                                            'validation': [{
                                                                'type': 'required',
                                                                'translation_key': 'INVALID_EMAIL',
                                                                'translation_key_custom': null
                                                            }]
                                                        },
                                                        'styles': [],
                                                        'children': []
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- NUMBER */
                                    {
                                        "key": "pharmacy-edit-form-s111124ub-row-1",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-det111124ail-sub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patien111124t-id-label",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "NUMBER"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-detail111124-sub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "produc111124t-unitPrice",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "number",
                                                            "label": "Label",
                                                            "name": "unitPrice",
                                                            "id": "product-unitPrice-cru",
                                                            "value": 123,
                                                            "disabled": false,
                                                            "validation": [
                                                                {
                                                                    "type": "min:2",
                                                                    "translation_key": "GENERAL_VALIDATION_MIN",
                                                                    "translation_key_custom": null
                                                                },
                                                                {
                                                                    "type": "min_value:0",
                                                                    "translation_key": "GENERAL_VALIDATION_MIN_VALUE",
                                                                    "translation_key_custom": null
                                                                }
                                                            ]
                                                        },
                                                        "styles": [],
                                                        "children": []
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- AUTOSUGGEST */
                                    {
                                        "key": "pharmacy-edit-form-s111125ub-row-1",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-deta111125il-sub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patie111125nt-id-label",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "AUTOSUGGEST"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-det111125ail-sub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "pharm111125acy-city",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "autosuggest",
                                                            "label": "Label",
                                                            "name": "citySearch",
                                                            "id": "pharmacy-city-cru",
                                                            "value": "faca14704e997b81892c6fa619e0f322ab44efe4e62718bc1fe2d267828b8bbee66ea45d3b8193ab567e5aa0ff451874cf5621551a8f137ecb82367c6f6974e6",
                                                            "placeholder": "8730 Beernem",
                                                            "disabled": false,
                                                            "url": "\/cities\/search\/citySearch",
                                                            "validation": [
                                                                {
                                                                    "type": "required",
                                                                    "translation_key": "GENERAL_VALIDATION_REQUIRED",
                                                                    "translation_key_custom": null
                                                                }
                                                            ],
                                                            "labelCols": 12
                                                        },
                                                        "styles": [],
                                                        "children": []
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- INPUTTAGS */
                                    {
                                        "key": "pharmacy-edit-form-su111125b-row-1",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-detail-s111125ub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patient-id111125-label",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "INPUTTAGS"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-deta111125il-sub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        'key': 'unavailabilitytype-mail',
                                                        'type': 'LhInput',
                                                        'actions': [],
                                                        'attributes': {
                                                            'type': 'inputtags',
                                                            'label': 'Label',
                                                            'name': 'mailtags',
                                                            'id': 'unavailabilitytype-colour-mail',
                                                            'value': ['test@mail.com', 'test2@mail.com'],
                                                            'disabled': false,
                                                            'validation': [{
                                                                'type': 'email',
                                                                'translation_key': 'INVALID_EMAIL',
                                                                'translation_key_custom': "testing"
                                                            }]
                                                        },
                                                        'styles': [],
                                                        'children': []
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- FILE UPLOAD */
                                    {
                                        "key": "pharmacy-edit-for111126m-sub-row-1",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-detail111126-sub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patien111126t-id-label",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "FILE"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-de111126tail-sub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "prod111126uct-import",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "file",
                                                            "name": "import",
                                                            "label": "Label",
                                                            "id": "product-import",
                                                            "labelCols": 12,
                                                            "validation": [
                                                                {
                                                                    "type": "required",
                                                                    "translation_key": "GENERAL_VALIDATION_REQUIRED",
                                                                    "translation_key_custom": null
                                                                }
                                                            ],
                                                            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget orci tristique, convallis felis eu, suscipit urna. Pellentesque finibus vehicula porttitor. Nulla facilisi. Nam et nibh eros. Pellentesque sodales diam ac eros pharetra, id porttitor elit rutrum."
                                                        },
                                                        "styles": [],
                                                        "children": []
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- BUSINESS HOURS */
                                    {
                                        "key": "pharmacy-edi111127t-form-sub-row-1",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-d111127etail-sub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patien111127t-id-label",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "BUSINESS HOURS"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-deta111127il-sub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "pharmacy-openin111127g-hours-input",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "businesshour",
                                                            "name": "businessHours",
                                                            "id": "pharmacy-business-hours-input",
                                                            "value": {
                                                                "AM": [
                                                                    "false",
                                                                    "false",
                                                                    "false",
                                                                    "false",
                                                                    "false",
                                                                    "false"
                                                                ],
                                                                "PM": [
                                                                    "false",
                                                                    "false",
                                                                    "false",
                                                                    "false",
                                                                    "false",
                                                                    "false"
                                                                ]
                                                            },
                                                            "days": [
                                                                "mon",
                                                                "tue",
                                                                "wed",
                                                                "thu",
                                                                "fri",
                                                                "sat"
                                                            ],
                                                            "direction": "horizontal",
                                                            "disabled": false
                                                        },
                                                        "styles": [],
                                                        "children": []
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- HIDDEN */
                                    {
                                        "key": "pharmacy-edit-fo111129rm-sub-row-1",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-det111129ail-sub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patient-111129id-label",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "HIDDEN INPUT"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-d111129etail-sub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "pharmac111129y-basic-guid",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "hidden",
                                                            "name": "guid",
                                                            "id": "guid",
                                                            "value": "ea01b853642989e5044cbb1220804bdf1d102fb741f999ee546657b36b3fad6c5e0b0399c1b5862472f6c3e62447bea66e4afd44cdc49ef6f55388cdfa428927"
                                                        },
                                                        "styles": [],
                                                        "children": []
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhInput -- SCORE */
                                    {
                                        "key": "pharmacy-edit-fo111129rm-sub-row-1",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-det111129ail-sub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patient-111129id-label",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "SCORE INPUT"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-d111129etail-sub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [{
                                                    "display": "none"
                                                }],
                                                "children": [
                                                    {
                                                        "key": "pharmac111129y-basic-guid",
                                                        "type": "LhInput",
                                                        "actions": [],
                                                        "attributes": {
                                                            "type": "score",
                                                            "name": "score_guid",
                                                            "id": "score_guid",
                                                            "value": "1"
                                                        },
                                                        "styles": [],
                                                        "children": []
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    /* LhA -- Anchor link */
                                    {
                                        "key": "pharmacy-edit-fo111129rm-sub-row-2",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                            {
                                                "key": "pharmacy-det111129ail-sub-col-1-left-2",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 2,
                                                    "class": "d-flex flex-wrap justify-content-end align-content-center"
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "patient-111129id-label",
                                                        "type": "LhP",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "EXT. HYPERLINK"
                                                        },
                                                        "styles": [{
                                                            "fontWeight": "900"
                                                        }],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "key": "pharmacy-d111129etail-sub-col-1-left",
                                                "type": "LhCol",
                                                "actions": [],
                                                "attributes": {
                                                    "cols": "auto",
                                                    "md": 4
                                                },
                                                "styles": [],
                                                "children": [
                                                    {
                                                        "key": "pharmacy-dashboard-map-link",
                                                        "type": "LhA",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "Google.com",
                                                            "href": "https:\/\/google.com\/",
                                                            "target": "_blank",
                                                            "class": "button primary"
                                                        },
                                                        "styles": {
                                                            "marginBottom": "1rem"
                                                        },
                                                        "children": []
                                                    },
                                                    {
                                                        "key": "pharmacy-dashboard-map-link2",
                                                        "type": "LhA",
                                                        "actions": [],
                                                        "attributes": {
                                                            "content": "Google.com",
                                                            "href": "https:\/\/google.com\/",
                                                            "target": "_blank",
                                                            "class": ""
                                                        },
                                                        "styles": {
                                                            "marginBottom": "1rem"
                                                        },
                                                        "children": []
                                                    }
                                                ]
                                            }
                                        ]
                                    },

                                    /* ## END PAGE CONTENT ## */
                                ]
                            }
                        ]
                },
            }
        }
    }
</script>

<style scoped lang="scss">
    html body .form-group-wrapper,
    html body .custom-control {
        margin: 0;
        padding: 0;
    }
    html body .form-group.form-group > label.empty-space-placeholder {
        display: none !important;
    }
    html body .input-wrapper > div {
        align-items: center;
        display: flex;
    }
    html body .row {
        margin-bottom: 1.5rem;
    }
    html body .mainPage {
        .row {
            margin-bottom: 5rem;
        }
    }
</style>
