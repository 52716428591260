<template>
  <div class="autocomplete">
    <input
        type="text"
        autocomplete="chrome-off"
        v-model="search"
        @input="handleSearch"
        @keyup.down="onArrowDown"
        @keyup.up="onArrowUp"
        @keyup.enter="onEnter"
        :disabled="checkDisabled"
        :class="['form-control', {hascheck: selectedItem.hasOwnProperty('value')}]"
        :ref="obj.attributes.id"
        @focus="emitFocus({
          parentForm: parentFormKey,
          id: obj.attributes.id,
          parent: obj.attributes.parent
        })"
    />
    <font-awesome-icon icon="search"/>
    <ul id="autocomplete-results" v-show="isOpen" class="autocomplete-results">
      <li class="loading-autosuggest" v-if="isLoading">{{$t('GENERAL_LOADING')}}...</li>
      <li
          v-else
          v-for="(result, i) in searchedItems"
          :key="i"
          @click="setResult(result, i)"
          class="autocomplete-result"
          :class="{ 'is-active': i === arrowCounter }"
      >
        <template v-if="result">{{ result.placeholder }}</template>
      </li>
      <li v-if="!isLoading && searchedItems.length == 0">{{$t('GENERAL_NO_RESULTS')}}</li>
    </ul>
  </div>
</template>

<script>
  import axios from 'axios'
  import debounce from 'lodash/debounce'
  import { eventBus } from '@/services/event-bus'
  import {mapMutations} from "vuex";

  export default {
    name: 'LhAutoSuggest',
    props: {
      obj: {
        type: Object,
        required: true
      },
      isAsync: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data () {
      return {
        isOpen: false,
        results: [],
        search: '',
        isLoading: false,
        arrowCounter: 0,
        searchedItems: [],
        items: this.obj.attributes.content,
        inputName: this.obj.attributes.name,
        disabled: this.obj.attributes.disabled === true || this.obj.attributes.disabled === 'true',
        selectedItem: {},
        itemsReplace: {},
        postSelectionTrigger: this.obj.attributes.postSelectionTrigger != '' ? this.obj.attributes.postSelectionTrigger : null,
      }
    },
    computed: {
      parentFormKey () {
        return this.$store.getters.getInsideOfFormKey(this.obj.key)
      }
    },
    methods: {
      ...mapMutations({
        setLoading: 'setLoading',
        stopLoading: 'stopLoading'
      }),
      handleSearch () {
        this.selectedItem = {}
        this.$emit('handleInput', '')
        this.onChange()
      },
      onChange: debounce(function () {
        console.log('handling autosuggest change')
        if (this.isAsync) {
          this.isLoading = true
          let payload = {}
          payload[this.inputName] = this.search

          let data = {
            url: this.obj.attributes.url,
            payload: payload
          }

          if (this.obj.attributes.submitOnChange) {
            //handle by submit
            this.$emit('handleInput', this.search)
          } else {
            this.$store.dispatch('getAutoSuggestItems', data).then(result => {
              if (result && result.data) {
                let datas = result.data
                this.searchedItems = datas
                // for (let data of datas) {
                //   for (let inputs in data) {
                //     if (inputs == this.obj.key) {
                //       this.searchedItems.push({
                //         text: data[inputs].placeholder,
                //         guid: data[inputs].value
                //       })
                //     } else {
                //       //if other then obj.key -> attach to obj.value in array to replace
                //       if (data[this.obj.key]) {
                //         this.itemsReplace[data[this.obj.key].value] = data[inputs]
                //         this.itemsReplace[data[this.obj.key].value]['target'] = inputs
                //       }
                //     }
                //   }
                // }
              }
              this.isLoading = false
            })
          }
        }

        if (this.search !== '') {
          this.isOpen = true
        } else {
          this.isOpen = false
          this.searchedItems = []
          this.selectedItem = {}
        }

        if (this.obj.attributes.submitOnChange) {
          this.isOpen = false
          this.isLoading = false
          this.searchedItems = []
          this.selectedItem = {}
        }
      }, 500, {leading: false, trailing: true}),
      setResult (result, i) {
        if (result) {
          console.log('Autosuggest setResult', result)
          this.selectedItem = result
          this.search = result.placeholder
          this.isOpen = false
          this.$emit('handleInput', {text: result.placeholder, guid: result.value})
          this.$emit('handleSearchedItem', this.searchedItems[i])
          this.replaceComponentFeatures(this.searchedItems[i])
          if(this.postSelectionTrigger){
            console.log('postSelectionTrigger')
            this.setLoading()
            let payload = {}
            payload.pharmacyGuid = result.value
            let api = this.$clientConfig.VUE_APP_API_URL + this.postSelectionTrigger
            axios({
              method: 'post',
              url: api,
              withCredentials: true,
              crossdomain: true,
              data: payload
            })
            .then(response => {
              let pharmacySearch = {
                "componentReplace": {}
              }
              pharmacySearch["componentReplace"][this.obj.key] = {
                "attributes": response.data[0]
              }
              this.replaceComponentFeatures(pharmacySearch)
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              this.stopLoading()
            })
          }
        }
      },
      onEnter () {
        if (this.searchedItems.length > 0 && this.arrowCounter > -1) {
          this.selectedItem = this.searchedItems[this.arrowCounter]
          if (this.selectedItem) {
            this.$emit('handleInput', {text: this.selectedItem.placeholder, guid: this.selectedItem.value})
            this.$emit('handleSearchedItem', this.searchedItems[this.arrowCounter])
            this.search = this.searchedItems[this.arrowCounter].placeholder
            this.replaceComponentFeatures(this.searchedItems[this.arrowCounter])
          }
          this.isOpen = false
          this.arrowCounter = -1
        } else if (!this.isOpen) this.$emit('onEnter')
      },
      onArrowDown (evt) {
        if (this.arrowCounter < this.searchedItems.length - 1) {
          this.arrowCounter = this.arrowCounter + 1
        }
      },
      onArrowUp () {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1
        }
      },
      handleClickOutside (evt) {
        if (!this.$el.contains(evt.target)) {
          this.isOpen = false
          this.arrowCounter = -1
        }
      },
      replaceComponentFeatures (item) {
        console.log('replace component features', item)
        for (let replace in item) {
          if (replace == 'componentReplace') {
            let componentsReplace = item[replace]
            for (let componentKey in componentsReplace){
              let component = componentsReplace[componentKey]
              for (let type in component) {
                let payload = {
                  target: componentKey,
                  content: component[type],
                  popup: this.$store.getters.isObjInsidePopup(componentKey),
                  type: type
                }
                console.log(payload)
                this.$store.commit('replaceTarget', payload)
              }
            }
          }
        }
      },
      emitFocus (payload) {
        eventBus.$emit('inputFocus' + payload.parentForm, { id: payload.id, parent: payload.parentBlock },)
      },
      handleFocusInput () {
          this.$nextTick(() => {
            console.log('autosuggest focus',)
            const inputRef = this.$refs[this.obj.attributes.id]
            inputRef.focus()
          })
      },
    },
    watch: {
      items: function (val, oldValue) {
        if (val.length !== oldValue.length) {
          this.results = val
          this.isLoading = false
        }
      },
      'obj.attributes.value': function (val)  {
        console.log("Autosuggest value changed", val)
        if (val) {
          console.log("Autosuggest handle value change", this.obj.attributes)
          if (val.text || val.guid) {
            this.$emit('handleInput', {text: val.text, guid: val.guid})
            this.search = val.text
            this.selectedItem = {'placeholder': val.text, 'value': val.guid}
          } else {
            this.search = this.obj.attributes.placeholder
            this.selectedItem = {'placeholder': this.search, 'value': val}
            this.$emit('handleInput', {text: this.search, guid: val})
          }
        }
      },
      'obj.attributes.placeholder': function (val)  {
        console.log("Autosuggest placeholder changed", val)
        if (val) {
          console.log("Autosuggest handle placeholder change", this.obj.attributes)
          if (val.text || val.guid) {
            this.$emit('handleInput', {text: val.text, guid: val.guid})
            this.search = val.text
            this.selectedItem = {'placeholder': val.text, 'value': val.guid}
          } else {
            this.search = val
            this.selectedItem = {'placeholder': this.search, 'value': this.obj.attributes.value}
            this.$emit('handleInput', {text: this.search, guid: this.obj.attributes.value})
          }
        }
      }
    },
    created () {
      if (this.obj.attributes.value) {
        let value = ''
        let text = ''
        if (this.obj.attributes.value && this.obj.attributes.value.guid) {
          value = this.obj.attributes.value.guid
          text = this.obj.attributes.value.text
        } else {
          value = this.obj.attributes.value
          text = this.obj.attributes.placeholder
        }
        this.$emit('handleInput', {text: text, guid: value})
        this.selectedItem = {placeholder: text, value: value}
        this.search = text
      }
    },
    mounted () {
      document.addEventListener('click', this.handleClickOutside)
      if (this.obj.attributes.focusOnLoad) {
        this.handleFocusInput()
      }
    },
    destroyed () {
      document.removeEventListener('click', this.handleClickOutside)
    }
  }
</script>

<style scoped lang="scss">

  .autocomplete {
    position: relative;

    svg {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  .autocomplete-results {
    position: absolute;
    padding: 5px 10px;
    margin: 0;
    border: 1px solid #eeeeee;
    min-height: 60px;
    max-height: 200px;
    overflow: auto;
    width: 100%;
    background: white;
    z-index: 100;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: $primary-color;
    color: white;
  }

  .hascheck {
    border-color: $primary-color;
  }
</style>
