<template>
  <div class="registerContact" :style="null">
    <div>
      <h1>{{obj.attributes.title}} - {{obj.attributes.projectName}}</h1>
      <form @submit.prevent="validateBeforeSubmit">
        <div>
          <!-- firstname -->
          <label class="label">First name</label>
          <input
              type="text"
              name="firstName"
              id="firstName"
              v-validate="'required|alpha_num'"
              :class="{'input': true, 'is-danger': errors.has('firstName') }"
              v-model="firstName"
              placeholder="First name*"
          />
          <template
              v-show="errors.has('firstName')"
              class="help is-danger"
          >{{ errors.first('firstName') }}
          </template>
        </div>
        <!-- lastname -->
        <div>
          <label class="label">Last name</label>
          <input
              type="text"
              name="lastName"
              id="lastName"
              v-validate="'required|alpha'"
              :class="{'input': true, 'is-danger': errors.has('lastName') }"
              v-model="lastName"
              placeholder="Last name*"
          />
          <template
              v-show="errors.has('lastName')"
              class="help is-danger"
          >{{ errors.first('lastName') }}
          </template>
        </div>
        <!-- title -->
        <div>
          <label class="label">Title</label>
          <input
              type="text"
              name="title"
              id="title"
              v-validate="'alpha'"
              :class="{'input': true, 'is-danger': errors.has('title') }"
              v-model="title"
              placeholder="Title"
          />
          <template v-show="errors.has('title')" class="help is-danger">{{ errors.first('title') }}</template>
        </div>
        <!-- birthday -->
        <!-- <div>
          <label class="label">Birthday</label>
          <input
            type="text"
            name="birthday"
            id="birthday"
            v-validate="'required|date_format:dd/mm/yyyy'"
            :class="{'input': true, 'is-danger': errors.has('birthday') }"
            v-model="birthday"
            placeholder="dd/mm/yyyy"
          >
          <template
            v-show="errors.has('birthday')"
            class="help is-danger"
          >{{ errors.first('birthday') }}</template>
        </div>-->
        <!-- country -->
        <div>
          <label class="label">Country</label>
          <input
              type="text"
              name="country"
              id="country"
              v-validate="'alpha'"
              :class="{'input': true, 'is-danger': errors.has('country') }"
              v-model="country"
              placeholder="Country"
          />
          <template
              v-show="errors.has('country')"
              class="help is-danger"
          >{{ errors.first('country') }}
          </template>
        </div>
        <!-- city -->
        <div>
          <label class="label">City</label>
          <input
              type="text"
              name="city"
              id="city"
              v-validate="'alpha'"
              :class="{'input': true, 'is-danger': errors.has('city') }"
              v-model="city"
              placeholder="City"
          />
          <template v-show="errors.has('city')" class="help is-danger">{{ errors.first('city') }}</template>
        </div>
        <!-- street -->
        <div>
          <label class="label">Street</label>
          <input
              type="text"
              name="street"
              id="street"
              v-validate="'alpha'"
              :class="{'input': true, 'is-danger': errors.has('street') }"
              v-model="street"
              placeholder="Street"
          />
          <template
              v-show="errors.has('street')"
              class="help is-danger"
          >{{ errors.first('street') }}
          </template>
        </div>
        <!-- houseNumber -->
        <div>
          <label class="label">House number</label>
          <input
              type="text"
              name="houseNumber"
              id="houseNumber"
              v-validate="'alpha_num'"
              :class="{'input': true, 'is-danger': errors.has('houseNumber') }"
              v-model="houseNumber"
              placeholder="House number"
          />
          <template
              v-show="errors.has('houseNumber')"
              class="help is-danger"
          >{{ errors.first('houseNumber') }}
          </template>
        </div>
        <!-- nationality -->
        <div>
          <label class="label">Nationality</label>
          <input
              type="text"
              name="nationality"
              id="nationality"
              v-validate="'alpha'"
              :class="{'input': true, 'is-danger': errors.has('nationality') }"
              v-model="nationality"
              placeholder="Nationality"
          />
          <template
              v-show="errors.has('nationality')"
              class="help is-danger"
          >{{ errors.first('nationality') }}
          </template>
        </div>
        <!-- phoneNr -->
        <div>
          <label class="label">Telephone number</label>
          <input
              type="text"
              name="phoneNr"
              id="phoneNr"
              v-validate="'alpha_num'"
              :class="{'input': true, 'is-danger': errors.has('phoneNr') }"
              v-model="phoneNr"
              placeholder="Telephone number"
          />
          <template
              v-show="errors.has('phoneNr')"
              class="help is-danger"
          >{{ errors.first('phoneNr') }}
          </template>
        </div>
        <!-- privateNr -->
        <div>
          <label class="label">Private number</label>
          <input
              type="text"
              name="privateNr"
              id="privateNr"
              v-validate="'alpha_num'"
              :class="{'input': true, 'is-danger': errors.has('privateNr') }"
              v-model="privateNr"
              placeholder="Private number"
          />
          <template
              v-show="errors.has('privateNr')"
              class="help is-danger"
          >{{ errors.first('privateNr') }}
          </template>
        </div>
        <!-- fax -->
        <div>
          <label class="label">Fax</label>
          <input
              type="text"
              name="fax"
              id="fax"
              v-validate="'alpha_num'"
              :class="{'input': true, 'is-danger': errors.has('fax') }"
              v-model="fax"
              placeholder="Fax"
          />
          <template v-show="errors.has('fax')" class="help is-danger">{{ errors.first('fax') }}</template>
        </div>
        <!-- email -->
        <div>
          <label class="label">E-mail</label>
          <input
              type="email"
              name="email"
              id="email"
              v-validate="'required|email'"
              :class="{'input': true, 'is-danger': errors.has('email') }"
              v-model="email"
              placeholder="e-mail"
              ref="email"
          />
          <template v-show="errors.has('email')" class="help is-danger">{{ errors.first('email') }}</template>
        </div>
        <!-- email -->
        <div>
          <label class="label">Confirm e-mail</label>
          <input
              type="email"
              name="email2"
              id="email2"
              v-validate="'required|confirmed:email'"
              :class="{'input': true, 'is-danger': errors.has('email2') }"
              v-model="email2"
              placeholder="confirm e-mail"
          />
          <template
              v-show="errors.has('email2')"
              class="help is-danger"
          >{{ errors.first('email2') }}
          </template>
        </div>
        <!-- memo -->
        <div>
          <label class="label">Memo</label>
          <input
              type="text"
              name="memo"
              id="memo"
              v-validate="'alpha_num'"
              :class="{'input': true, 'is-danger': errors.has('memo') }"
              v-model="memo"
              placeholder="Memo"
          />
          <template v-show="errors.has('memo')" class="help is-danger">{{ errors.first('memo') }}</template>
        </div>
        <!-- type -->
        <div>
          <label class="label">Type</label>
          <input
              type="text"
              name="type"
              id="type"
              v-validate="'alpha_num'"
              :class="{'input': true, 'is-danger': errors.has('type') }"
              v-model="type"
              placeholder="Type"
          />
          <template v-show="errors.has('type')" class="help is-danger">{{ errors.first('type') }}</template>
        </div>
        <!-- link -->
        <div>
          <label class="label">Link</label>
          <input
              type="text"
              name="link"
              id="link"
              v-validate="'url'"
              :class="{'input': true, 'is-danger': errors.has('link') }"
              v-model="link"
              placeholder="Link"
          />
          <template v-show="errors.has('link')" class="help is-danger">{{ errors.first('link') }}</template>
        </div>

        <div>
          <input
              type="checkbox"
              name="terms"
              v-validate="'required'"
              :class="{'input': true, 'is-danger': errors.has('terms') }"
              id="terms"
              v-model="terms"
          />
          <label for="terms">Accept Terms of Use</label>
          <template v-show="errors.has('terms')" class="help is-danger">{{ errors.first('terms') }}</template>
        </div>

        <div>
          <button type="submit" class="button c-register-form__register">Create Account</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        firstName: 'john',
        lastName: 'doe',
        title: 'mr',
        birthday: '12/12/2000',
        country: 'Belgium',
        city: '',
        street: '',
        houseNumber: '',
        nationality: '',
        phoneNr: '',
        privateNr: '',
        fax: '',
        email: 'john@doe.com',
        email2: 'john@doe.com',
        memo: '',
        type: '',
        link: '',
        terms: true
      }
    },
    methods: {
      register () {
        let dateTime = new Date()
        const formData = {
          formName: 'contact-form',
          firstName: this.firstName,
          surName: this.lastName,
          title: this.title,
          // birthday: this.birthday,
          freeCountry: this.country,
          city: this.city,
          street: this.street,
          houseNumber: this.houseNumber,
          nationality: this.nationality,
          telephoneNumber: this.phoneNr,
          privateTelephoneNumber: this.privateNr,
          fax: this.fax,
          email: this.email,
          email2: this.email2,
          memo: this.memo,
          type: this.type,
          link: this.link
        }
        this.$store.dispatch('registerContact', formData).then(response => {
          if (
              response.data ==
              'Thank you for registering. You will receive a mail to active your account.'
          ) {
            alert(
                'Thank you for registering. You will receive a mail to active your account.'
            )
            this.$router.push({ path: '/login' })
          }
        })
      },
      lostFocus (input) {
        input.$touch()
      },
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.register()
            return
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .input.invalid input {
    border: 1px solid red;
  }

  input {
    margin: 10px;
    width: 200px;
    height: 20px;
    line-height: 20px;
  }

  input[type="checkbox"] {
    margin: 0;
    width: 13px;
    height: 13px;
  }
</style>
