<template>
  <div class="lh-dropdown">

  </div>
</template>

<script>
  export default {
    name: 'LhDropDown',
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        selectedItem: {}
      }
    },
    methods: {
      handleChange (val) {
        console.log(val)
      }
    },
    computed: {}
  }
</script>

<style scoped lang="scss">
</style>
