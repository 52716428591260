<template>
    <div class="lh-spacer" :class="checkClasses" :style="[checkStyles, spacerSize]">
        <!-- Spacer -->
    </div>
</template>

<script>
    export default {
        name: 'LhSpacer',
        props: {
            obj: {
                type: Object
            }
        },
        computed: {
            spacerSize () {
                if(this.obj.attributes.size) {
                    if(this.obj.attributes.size.toString().includes("px")){
                        return {
                            height: this.obj.attributes.size
                        }
                    } else {
                        return {
                            height: this.obj.attributes.size + 'em'
                        }
                    }
                } else {
                    return {
                        height: '1em'
                    }
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .lh-spacer {
        display: block;
        width: 100%;
        height: 1em;
    }
</style>