<template>
  <li class="list-column-item" :key="obj.guid" :id="obj.guid">
    <div class="list-column-item-content">
      {{ $t(obj.name) }}
      <div class="edit-icon"  v-on="allEvents">
        <font-awesome-icon class="icon" :icon="['fas', 'cog']"></font-awesome-icon>
      </div>
      <font-awesome-icon class="icon-drag" :icon="['fas', 'bars']"></font-awesome-icon>
    </div>
    <draggable
        class="list-columns"
        tag="ul"
        v-model="obj.children"
        v-bind="dragOptions"
        @start="drag = true"
        @add="onAdd"
        @update="onUpdate"
    >
      <lh-sitemap-item
          v-if="obj.children.length"
          class="child" v-for="child in obj.children"
          :obj="child"
          @itemAdd="onAdd"
          @itemUpdate="onUpdate"
      ></lh-sitemap-item>
    </draggable>
  </li>
</template>

<script>
  import draggable from 'vuedraggable'
  import mixinFunctions from '@/mixin/mixinFunctions'
  import confirm from '@/mixin/confirm'

  export default {
    name: 'LhSitemapItem',
    mixins: [mixinFunctions, confirm],
    props: {
      obj: {
        type: Object,
        required: true
      }
    },
    components: {
      draggable
    },
    data () {
      return {}
    },
    computed: {
      dragOptions () {
        return {
          drag: false,
          animation: 200,
          group: 'description',
          disabled: false,
          ghostClass: 'ghost'
        }
      },
    },
    methods: {
      onAdd (event) {
        this.$emit('itemAdd', event)
      },
      onUpdate (event) {
        this.$emit('itemUpdate', event)
      }
    },
    watch: {}
  }
</script>

<style scoped lang="scss">
  .list-columns {
    padding: 10px 0;
    min-height: 0;
  }

  .list-column-item {
    position: relative;
    border: none;
    padding: 0;
    //background: $ultra-light-grey;

    &-content {
      background: $light-grey;
      padding: 10px 20px;
      margin-top: 5px;
      display: flex;
      align-items: center;
    }

    &.child {
      margin-left: 1rem;
    }
  }


  @include media-breakpoint-up(lg) {
    .list-column-item {
      background: unset;

      &-content {
        padding: 0 1rem 0 2rem;
        background: unset;
      }

      &.child {
        margin-left: 2rem;
      }
    }
  }

  .edit-icon {
    width: 30px;
    height: 100%;
    display: grid;
    place-items: center;
    margin-left: auto;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      margin-left: 0;
    }
  }

  .icon {
    font-size: 14px;
    color: $medium-grey-alt;

    &-drag {
      margin-left: 1.5rem;
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }
</style>