<template>
  <div class="lh-text" :id='obj.key'>
    <p :class="obj.attributes.class">{{$t(obj.attributes.content)}}</p>
  </div>
</template>

<script>
  export default {
    name: 'LhText',
    components: {},
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
