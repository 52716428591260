<template>
  <th
      :class="['gridHeader', checkClasses, {notSortable: header.disableSort}]"
      :key="header.key + componentKey"
      v-if="header.checked"
      v-show="!header.hidden"
  >
    <template v-if="header.disableSort">
      <span class="header nosort">{{$t(header.name)}}</span>
      <lh-edit-translation :obj="{key: header.name}"></lh-edit-translation>
      <div class="subheader" v-if="header.subContent">
        {{ header.subContent }}
      </div>
    </template>
    <template v-else>
      <template v-if="header.type === 'data'">
        <span class="header" @click="clickhandler(header.key)">{{$t(header.name)}}
          <lh-edit-translation :obj="{key: header.name}"></lh-edit-translation>
          <!--          <font-awesome-icon :icon="['fas', 'sort']" :key="componentKey"></font-awesome-icon>-->
          <span class="fa-layers fa-fw">
            <font-awesome-icon v-show="header.sort === 0 || header.sort === 1"
                               :icon="['fal', 'angle-up']"
                               transform="up-4"
                               :class="[{'is-active': header.sort === 1}]"
            ></font-awesome-icon>
            <font-awesome-icon v-show="header.sort === 0 || header.sort === 2"
                               :icon="['fal', 'angle-down']"
                               transform="down-3"
                               :class="[{'is-active': header.sort === 2}]"
            ></font-awesome-icon>
          </span>
        </span>
      </template>
    </template>
  </th>
</template>

<script>
  export default {
    name: 'GridHeader',
    components: {},
    data () {
      return {
        componentKey: 1,
      }
    },
    props: {
      header: {
        type: Object
      }
    },
    methods: {
      clickhandler (key) {
        this.$emit('headerClick', key)
        // this.componentKey++;
      }
    },

  }
</script>

<style lang="scss" scoped>
  th {
    color: $browse-header-color;
  }

  .gridHeader {
    padding: 0 1em 1em 1em;
    white-space: nowrap;
    cursor: pointer;

    &.notSortable {
      cursor: unset;
    }

    .subheader {
      font-size: ($font-size-base*0.9);
    }
  }

  @include media-breakpoint-up(md) {
    .gridComplex {
      .gridHeader {
        transform: translateX(-32px);
        transition: transform $speed $easing;
      }
      &.checkbox-visible {
        .gridHeader {
          transform: translateX(0);
        }
      }
    }
  }

  .fa-layers {
    svg {
      color: $medium-grey;

      &.is-active {
        color: inherit
      }
    }
  }
</style>
