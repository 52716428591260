<template>
  <div class="table-wrapper">
    <div class="lh-table">
      <!-- settings -->
      <b-table striped hover :items="obj.attributes.content"></b-table>
    </div>
  </div>
</template>

<script>
  import lowerCase from 'lodash/lowerCase'
  import { mapGetters } from 'vuex'

  export default {
    name: 'LhTable',
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        search: '',
        searchType: '',
        selectedRow: {},
        componentKey: 1,
        items: []
      }
    },
    computed: {
      ...mapGetters(['viewType']),
      browseSettings () {
        return this.$store.getters.getBrowseSettings
      },
      headers: {
        get () {
          return this.$store.getters.getBrowseSettingsHeaders
        },
        set (val) {
          this.$store.commit('setBrowseSettings', { headers: val })
        }
      },
      tableData: {
        get () {
          return this.obj.attributes.content.filter(
              data =>
                  !this.search ||
                  lowerCase(data[this.searchType]).includes(lowerCase(this.search))
          )
        },
        set (val) {
          this.$store.commit('setBrowseSettings', { content: val })
        }
      },
      filteredDetails () {
        let filtered = {}
        for (const property in this.selectedRow) {
          for (const header of this.browseSettings.headers) {
            if (header.checked && header.name === property) {
              filtered[property] = this.selectedRow[property]
            }
          }
        }
        return filtered
      }
    },
    methods: {
      indexMethod (index) {
        return index
      },
      findHeaders () {
        let headersArr = []
        if (this.obj.attributes.content[0]) {
          let index = 0
          for (const name in this.obj.attributes.content[0]) {
            index++
            headersArr.push({ name, checked: true, order: index })
          }
        }
        this.headers = headersArr
      },

      handleRowClick (selection, row, event) {
        let payload = { selectedRow: this.tableData.indexOf(selection) }
        this.$store.commit('setBrowseSettings', payload)
        if (this.browseSettings.viewType === 'FULLPAGE') {
          //this.$router.push("/detailPage");
        } else {
          this.selectedRow = selection
        }
      },
      handleRowDblclick (selection, row) {
        if (this.browseSettings.viewType === 'FULLPAGE') {
          this.$store.commit('setBrowseSettings', { content: selection })
          this.$router.push({
            path: '/detailPage'
            // params: { selection }
          })
        } else if (this.browseSettings.viewType === 'POPUP') {
          this.$store.commit('setBrowseSettings', { content: selection })
          let payload = {
            isOpen: true,
            content: {
              type: 'LhDetailPage',
              attributes: {
                content: selection
              }
            },
            key: selection.guid
          }
          this.$store.commit('addPopup', payload)
          // this.$store.commit("changePopupSettings", payload);
        } else {
          //sideform

          this.selectedRow = selection
        }
      },
      deleteRow (index, rows) {
        rows.splice(index, 1)
        //delete
        //this.$store.dispatch("saveTableData", this.tableData);
      },
      sortChange () {
        console.log('clear all')
      },
      getSettings () {
        this.$store.dispatch('getTableSettings')
      },
      addRow () {
        let newRow = {
          username: 'NewPerson',
          password:
              'c4fa2260d8c05225332cdda2b41918fa760b98f8243429cc41e8e1c97029c821c82bb73781496ee4dfb5fc18855d1bc84ec468cca3d632ef29c9ce4343115c8b',
          salt: 'monkey',
          id: 2,
          guid:
              '632d1f94b682416b48f030cd0ac83671b395fcc1582167b223a5ab4822d8e1a0c27919067fb431042d23ff8d9c9470ee5636a5b7b267149efba25af3fa611f8e',
          createdDate: '2019-06-24 10:39:03',
          modifiedDate: null,
          deletedDate: null
        }
        this.obj.attributes.content.push(newRow)
      }
    },
    created () {
      // this.getSettings();
      this.findHeaders()
      let payload = this.obj.attributes.content
      this.$store.commit('setBrowseSettings', { content: payload })
    },
    watch: {
      headers: function () {
        this.componentKey++
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .table-wrapper {
    display: flex;
  }

  .table-searchInput {
    width: 200px;
  }

  .lh-table {
    flex: 1 1 auto;
    transition: all 1s;
    overflow-x: hidden;
  }

  .cell {
    word-break: normal;
  }

  .el-table {
    thead th {
      color: $white;
      background-color: $black;
    }

    .el-table__body {
      tr.el-table__row--striped td {
        background: $ultra-light-grey;
      }

      tr.current-row td {
        background-color: $light-purple !important;
        color: $white;
      }

      tr.hover-row {
        // background-color: aqua !important;
      }
    }
  }

  td div {
    white-space: nowrap !important;
  }
</style>
