<template>
  <div class="form-step-order">
    <b-row align-v="center" class="mb-2">
      <b-col md="4">
        <lh-input :obj="inputs.baseDiscount" :parentKey="obj.key"></lh-input>
      </b-col>
      <b-col md="4">
        {{ $t('PRODUCT_MAXIMUMDISCOUNT') }}: {{ obj.attributes.maxDiscount }}
      </b-col>
    </b-row>
    <b-row class="filter-row">
      <b-col md="4">
        <lh-input :obj="inputs.productSearch" :parentKey="obj.key"></lh-input>
      </b-col>
      <b-col md="4">
        <lh-input :obj="inputs.filterSelect" :parentKey="obj.key"></lh-input>
      </b-col>
      <b-col md="4">
        <b-row>
          <b-col md="6">
            <lh-button :obj="buttons.recentProducts" class="w-100"></lh-button>
          </b-col>
          <b-col md="6">
            <lh-button :obj="buttons.topProducts" class="w-100"></lh-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="order-products row">

      <!-- Search results -->
      <b-col md="4" class="results">
        <template v-if="searchResults.length > 0">
          <lh-product-detail
              v-for="result in searchResults"
              :obj="result"
              :notAddable="!!isInBasket(result.guid)"
              @add-product="getProductDetails"
              @edit-product="getProductDetails"
              :class="[{selected: result.guid === selectedProduct}]"
          ></lh-product-detail>
        </template>
        <div v-else-if="this.productSearchLoading" class="placeholder">{{ $t('Loading') }}</div>
        <div v-else class="placeholder">{{ $t('No results') }}</div>
      </b-col>

      <!--  Product Details -->
      <b-col md="4" class="details">
        <keep-alive>
        <transition name="fade" mode="out-in" :duration="{ enter: 50, leave: 50}">
          <div v-if="product" :key="product.guid" class="d-flex flex-column h-100">
            <div class="font-weight-bold mb-2">{{ product.name }}</div>
            <b-row>
              <b-col md="7">
                <template v-if="obj.attributes.productCnkKey">{{ $t(obj.attributes.productCnkKey) }}:</template>
                <template v-else>{{ $t('PRODUCT_CNK') }}:</template>
              </b-col>
              <b-col class="text-right mb-2">
                <template v-if="product.pharmaCode">{{ product.pharmaCode }}</template>
                <template v-if="product.cnk">{{ product.cnk }}</template>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col md="7">
                {{ $t('PRODUCT_UNITPRICE') }}:
              </b-col>
              <b-col class="text-right">
                {{ currency }}{{ parseFloat(product.unitPrice).toFixed(2) }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <lh-input :obj="inputs.productAmount" :parentKey="obj.key"></lh-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div v-show="productAmountError" class="error">{{ $t('VISITORDER_NO_PRODUCTAMOUNT_ERROR') }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <lh-input
                    :obj="inputs.productDiscount"
                    :parentKey="obj.key"
                ></lh-input>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="product.maximumDiscount">
              <b-col>
                {{ $t('PRODUCT_MAXIMUMDISCOUNT') }}: {{ product.maximumDiscount }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>{{ $t('PRODUCT_TOTALDISCOUNT') }}: {{ baseDiscount }}% + {{ product.discount }}% = {{ calcDiscount }}</b-col>
            </b-row>
            <b-row>
              <b-col>
                <div v-show="discountError" class="error">{{ $t('VISITORDER_HIGH_DISCOUNT_WARNING') }}</div>
              </b-col>
            </b-row>
            <b-row align-h="between" class="mt-auto pt-1 border-top">
              <b-col>{{ $t('VISITORDER_TOTALBASE') }}:</b-col>
              <b-col class="text-right">{{ currency }}{{ calcProductPrice.basePrice }}</b-col>
            </b-row>
            <b-row align-h="between">
              <b-col>{{ $t('VISITORDER_DISCOUNT') }}:</b-col>
              <b-col class="text-right">{{ currency }}{{ calcProductPrice.discount }}</b-col>
            </b-row>
            <b-row align-h="between" class="font-weight-bold mb-3">
              <b-col>{{ $t('VISITORDER_TOTALPRICE') }}:</b-col>
              <b-col class="text-right">{{ currency }}{{ calcProductPrice.totalPrice }}</b-col>
            </b-row>
            <b-row>
              <b-col>
                <button class="button w-100 mb-2" @click="addToBasket" :disabled="parseFloat(product.discount) >= 100 || !product || loadingProduct || productAmountError">
                  <font-awesome-icon icon="shopping-basket"></font-awesome-icon>
                  <template v-if="editingProduct">{{ $t('VISITORDER_EDITPRODUCT') }}</template>
                  <template v-else>{{ $t('VISITORDER_ADDTOBASKET') }}</template>
                </button>
              </b-col>
            </b-row>
          </div>
          <div v-else class="placeholder mt-2">{{ $t('GENERAL_SELECT_PRODUCT') }}</div>
        </transition>
        </keep-alive>
      </b-col>

      <!-- Basket -->
      <b-col md="4" class="basket d-flex flex-column">
        <div class="product-wrapper">
            <transition-group name="list">
              <lh-product-detail
                  v-for="product in basket.products"
                  :obj="product"
                  :inBasket="true"
                  :baseDiscount="baseDiscount"
                  :currency="currency"
                  @edit-product="getProductDetails"
                  @remove-product="removeProduct"
                  :key="product.guid"
              ></lh-product-detail>
            </transition-group>
        </div>
        <b-row class="basket__price pt-1 border-top mt-auto">
          <b-col>
            <b-row align-h="between">
              <b-col>{{ $t('VISITORDER_ORDERLINES') }}:</b-col>
              <b-col class="text-right">
                <span v-if="basket.orderLinesCount">{{ basket.orderLinesCount }}</span>
                <span v-else>0</span>
              </b-col>
            </b-row>
            <b-row align-h="between">
              <b-col>{{ $t('VISITORDER_TOTALBASE') }}:</b-col>
              <b-col class="text-right" v-if="basket.prices">
                <template v-if="basket.prices.basePrice !== null">{{ currency }}{{ basket.prices.basePrice }}</template>
              </b-col>
            </b-row>
            <b-row align-h="between">
              <b-col>{{ $t('VISITORDER_DISCOUNT') }}:</b-col>
              <b-col class="text-right" v-if="basket.prices">
                <template v-if="basket.prices.discountPrice !== null">- {{ currency }}{{ basket.prices.discountPrice }}</template>
              </b-col>
            </b-row>
            <b-row align-h="between" class="font-weight-bold mb-3">
              <b-col>{{ $t('VISITORDER_TOTALPRICE') }}:</b-col>
              <b-col class="text-right" v-if="basket.prices">
                <template v-if="basket.prices.totalPrice !== null">{{ currency }}{{ basket.prices.totalPrice }}</template>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <button class="button w-100 mb-2 secondary" @click="showModal('discountModal')" :disabled="basketEmpty">
                  <font-awesome-icon icon="percent"></font-awesome-icon>
                  {{ $t('VISITORDER_SETDISCOUNT') }}
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </div>

    <!-- MODAL -->
    <b-modal id="discountModal" centered hide-footer>
      <template v-slot:modal-title>
        {{ $t('CHANGE_PRODUCT_DISCOUNT') }}
      </template>
      <div class="modal__content">
        <p>{{ $t('VISITORDER_SET_BASKET_DISCOUNT') }}</p>
        <p>{{ this.baseDiscount }}% + <input type="number" id="modalDiscount" v-model="modalDiscount"> =
          {{ calcModalDiscount }}</p>
        <lh-button :obj="buttons.setDiscount" class="w-100 mt-1" @click.native="setBasketDiscount('discountModal')"></lh-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { eventBus } from '@/services/event-bus'
import { mapActions, mapState } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'LhFormStepOrder',
  props: {
    obj: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      searchResults: [],
      productDetails: {},
      selectedProduct: '',
      baseDiscount: 0,
      productAmount: 1,
      productAmountError: false,
      productDiscount: 0,
      modalDiscount: 0,
      productSearch: '',
      productSearchLoading: false,
      productFilterSelect: '',
      editingProduct: false,
      currency: '€',
      filtering: false,
      lastProductValues: {
        units: 0,
        discount: 0
      },
      loadingProduct: false,
      baseDiscountInit: false,
      inputs: {
        baseDiscount: {
          type: 'LhInput',
          attributes: {
            'type': 'number',
            'label': 'VISITORDER_TOTALDISCOUNT',
            'name': 'baseDiscount',
            'id': 'base-discount',
            'value': 0,
            'disabled': false,
            'step': 0.01,
            'min': 0,
            'labelCols': 8,
            'noMargin': true,
            validation: [{
              "type": "max_value:100",
              'translation_key': 'GENERAL_VALIDATION_MAX_VALUE'
            }]
          }
        },
        productDiscount: {
          type: 'LhInput',
          attributes: {
            'type': 'number',
            'label': 'PRODUCT_DISCOUNT',
            'name': 'productDiscount',
            'id': 'product-discount',
            'value': 0,
            'disabled': false,
            'step': 0.01,
            'min': 0,
            'labelCols': 8,
            'noMargin': true,
            validation: [{
              "type": "max_value:100",
              'translation_key': 'GENERAL_VALIDATION_MAX_VALUE'
            }]
          }
        },
        productAmount: {
          type: 'LhInput',
          attributes: {
            'type': 'number',
            'label': 'PRODUCT_NUMBEROFUNITS',
            'name': 'productAmount',
            'id': 'product-amount',
            'value': '1',
            'disabled': false,
            'labelCols': 8,
            'min': 1,
            noMargin: true
          }
        },
        productSearch: {
          'key': 'form-step-product-search',
          'type': 'LhInput',
          'actions': [],
          'attributes': {
            'type': 'text',
            'name': 'productSearch',
            'id': 'form-step-product-search',
            'value': '',
            'placeholder': 'Search',
            'disabled': false,
          },
          'styles': [],
          'children': []
        },
        filterSelect: {
          'key': 'form-step-product-filter-select',
          'type': 'LhInput',
          'actions': [],
          'attributes': {
            'type': 'select',
            'name': 'productFilterSelect',
            'id': 'product-filterselect',
            'value': '',
            'content': [{
              'text': 'GENERAL_NO_OPTIONS',
              'value': ''
            }],
            'disabled': false,
          }, 'styles': [], 'children': []
        }
      },
      buttons: {
        recentProducts: {
          'key': 'recent-products-button',
          'type': 'LhButton',
          'actions': [
            {
              event: 'click',
              method: 'get-recent-products',
              type: 'emit',
              url: ''
            }
          ],
          'attributes': {
            'content': 'VISITORDER_RECENT',
            'name': 'recent-products-button',
            icon: 'history',
            disabled: this.loadingJSON
          },
          'styles': [],
          'children': []
        },
        topProducts: {
          'key': 'top-products-button',
          'type': 'LhButton',
          'actions': [
            {
              event: 'click',
              method: 'get-top-products',
              type: 'emit',
              url: ''
            }
          ],
          'attributes': {
            'content': 'VISITORDER_TOP',
            'name': 'top-products-button',
            icon: 'trophy',
            disabled: this.loadingJSON
          },
          'styles': [],
          'children': []
        },
        setDiscount: {
          'key': 'set-basket-discount-button',
          'type': 'LhButton',
          'actions': [],
          'attributes': {
            'content': 'Set discount',
            'name': 'set-discount-button',
            'type': 'emit',
            icon: 'percent',
            disabled: this.loadingJSON,
          },
          'styles': [],
          'children': []
        },
      },
      basket: {}
    }
  },
  computed: {
    ...mapState({
      loadingJSON: 'loadingJSON'
    }),
    product () {
      return this.productDetails[this.selectedProduct]
    },
    calcDiscount () {
      if (this.product) {
        return parseFloat(this.baseDiscount) + parseFloat(this.product.discount) + '%'
      }
    },
    calcModalDiscount () {
      let sum = parseFloat(this.baseDiscount) + parseFloat(this.modalDiscount)
      return sum > 0 ? sum + '%' : 0 + '%'
    },
    calcProductPrice () {
      if (this.product) {
        let basePrice = parseFloat(this.product.unitPrice) * parseFloat(this.product.units)
        let discount = basePrice * ((parseFloat(this.product.discount) + parseFloat(this.baseDiscount)) / 100)
        let totalPrice = basePrice - discount
        return {
          basePrice: basePrice.toFixed(2),
          discount: discount.toFixed(2),
          totalPrice: totalPrice.toFixed(2)
        }
      }
    },
    discountError () {
      if (this.product.maximumDiscount > 0) {
        if (this.product?.discount) return (Math.abs(this.product.discount) + Math.abs(this.baseDiscount)) > Math.abs(this.obj.attributes.maxDiscount) || (Math.abs(this.product.discount) + Math.abs(this.baseDiscount)) > Math.abs(this.product.maximumDiscount)

        if (this.baseDiscount) return Math.abs(this.baseDiscount) > Math.abs(this.product.maximumDiscount) || Math.abs(this.baseDiscount) > Math.abs(this.obj.attributes.maxDiscount)
      }

      if (this.product?.discount) return (Math.abs(this.product.discount) + Math.abs(this.baseDiscount)) > Math.abs(this.obj.attributes.maxDiscount)

      if (this.baseDiscount) return Math.abs(this.baseDiscount) > Math.abs(this.obj.attributes.maxDiscount)
    },
    basketEmpty () {
      return this.basket.products ? this.basket?.products.length === 0 : true
    }
  },
  methods: {
    ...mapActions({
      post: 'postLocal'
    }),
    searchProducts (payload) {
      // clear search results
      this.searchResults = []
      // make api call to get product list based on params
      let data = {
        url: `wizard/product/search/${this.obj.attributes.visitGuid}`,
        payload
      }
      // set loader
      this.productSearchLoading = true
      this.post(data).then((response) => {
        this.searchResults = response.data
      }).finally(() => {
        this.productSearchLoading = false
        this.filtering = false
      })
    },
    getProductDetails (product) {
      // show product in details column and add to list of product details
      // when editing product already in basket
      let inBasket = this.isInBasket(product.guid)
      if (inBasket) this.$set(this.productDetails, product.guid, Object.assign({}, inBasket))
      // when editing product from basket
      if (product.hasOwnProperty('totalValue')) this.$set(this.productDetails, product.guid, Object.assign({}, product))
      this.editingProduct = (inBasket || product.hasOwnProperty('totalValue'))
      if (!this.productDetails[product.guid]) {
        // add product to list if it doesn't exist yet
        this.$set(this.productDetails, product.guid, product)
        this.$set(this.productDetails[product.guid], 'discount', this.lastProductValues.discount)
        this.$set(this.productDetails[product.guid], 'units', this.lastProductValues.units)
      }
      this.selectedProduct = product.guid
    },
    getRecentProducts () {
      this.clearFilters()
      this.filtering = true
      this.searchProducts( { filter: 'recent' } )
    },
    getTopProducts () {
      this.clearFilters()
      this.filtering = true
      this.searchProducts( { filter: 'top' } )
    },
    removeProduct (product) {
      // make api call to remove current product from basket
      let data = {
        url: `orderlines/delete/1/1/${this.obj.attributes.responseType}`,
        payload: {
          visitOrderSearch: this.obj.attributes.visitGuid,
          guid: product.guid,
          sentDate: new Date().toISOString()
        }
      }
      this.post(data).then( (response) => {
        // update basket
        this.updateContent(response.data)
      })
    },
    handleInput (event) {
      let val = Math.abs(parseFloat(event.value))
      if (event.name === 'productSearch' || event.name === 'productFilterSelect') {
        this[event.name] = event.value
        return
      }

      if (val > 0) {
        this[event.name] = val
        this.productAmountError = this.product.units <= 0
        return
      }
      this[event.name] = event.value.length ? event.value : 0
      this.productAmountError = this.product.units <= 0
    },
    addToBasket () {
      // make api call to add current product to basket
      console.log('product units: ', parseFloat(this.product.units))
      if (parseFloat(this.product.units) <= 0) {
        this.productAmountError = true
        return
      } else {
        this.productAmountError = false
      }
      if (parseFloat(this.product.discount) <= 100) {
        this.loadingProduct = true
        let callUrl = `orderlines/add/1/${this.obj.attributes.responseType}`
        if (this.editingProduct) {
          // update product
          callUrl = `orderlines/update/1/${this.obj.attributes.responseType}`
        }
        let data = {
          url: callUrl,
          payload: {
            units: parseFloat(this.product.units),
            discount: parseFloat(this.product.discount),
            baseDiscount: parseFloat(this.baseDiscount),
            guid: this.product.guid,
            productSearch: this.product.guid,
            visitOrderSearch: this.obj.attributes.visitGuid,
            sentDate: new Date().toISOString()
          }
        }

        this.lastProductValues.units = parseFloat(this.product.units)
        this.lastProductValues.discount = parseFloat(this.product.discount)

        this.post(data).then((response) => {
          // update basket
          console.log('basket response', response)
          // clear selected product
          this.selectedProduct = ''
          this.updateContent(response.data)
          console.log('add to basket response', response)
        }).finally(() => this.loadingProduct = false)
      }
    },
    updateContent (data) {
      this.basket = data.basket
      if (data.page) this.$store.commit('replaceTarget', data)
    },
    setBasketDiscount (modalId) {
      let data = {
        url: 'wizard/order/discount/global',
        payload: {
          discount: this.modalDiscount,
          baseDiscount: this.baseDiscount,
          guid: this.obj.attributes.visitGuid,
          sentDate: new Date().toISOString()
        }
      }
      this.post(data).then((response) => {
        this.closeModal(modalId)
        console.log('set discount response', response.data)
        this.updateContent(response.data)
      })
    },
    showModal (id) {
      this.$root.$emit('bv::show::modal', id)
    },
    closeModal (id) {
      this.$root.$emit('bv::hide::modal', id)
    },
    clearFilters () {
      eventBus.$emit('clearInput' + this.inputs.filterSelect.key)
      eventBus.$emit('clearInput' + this.inputs.productSearch.key)
    },
    setBaseDiscount (val) {
      let data = {
        url: 'wizard/order/discount/base',
        payload: {
          discount: val,
          guid: this.obj.attributes.visitGuid,
          sentDate: new Date().toISOString()
        }
      }
      this.post(data).then((response) => {
        this.updateContent(response.data)
      })
    },
    isInBasket (guid) {
      if (this.basket.products) {
        return this.basket.products.find(item => item.productGuid === guid)
      }
    }
  },
  watch: {
    productAmount (val) {
      if (this.product) {
        if (parseFloat(this.product.units) !== val) this.$set(this.product, 'units', val)
      }
    },
    productDiscount (val) {
      if (this.product) {
        if (this.product.discount !== val) this.$set(this.product, 'discount', val)
      }
    },
    baseDiscount (val) {
      if (!this.baseDiscountInit) {
        this.baseDiscountInit = true
        return
      }
      this.setBaseDiscount(val)
    },
    productSearch (val) {
      if (val !== undefined && val !== 0 && val && !this.filtering) {
        let payload = {
          searchString: val
        }
        if (this.productFilterSelect) payload.filter = this.productFilterSelect
        this.searchProducts(payload)
      }
    },
    productFilterSelect (val) {
      if (val !== '' && val !== undefined && val !== 0) {
        let payload = {
          filter: val
        }
        if (this.productSearch) payload.searchString = this.productSearch
        this.searchProducts(payload)
      }
    },
    product: {
      deep: true,
      handler (val) {
        if (val) {
          this.productDiscount = parseFloat(val.discount)
          this.productAmount = parseFloat(val.units)
          this.$set(this.inputs.productAmount.attributes, 'value', val.units)
          this.$set(this.inputs.productDiscount.attributes, 'value', val.discount)
        } else {
          this.productDiscount = 0
          this.productAmount = 0
          this.$set(this.inputs.productAmount.attributes, 'value', 0)
          this.$set(this.inputs.productDiscount.attributes, 'value', 0)
        }
      }
    }
  },
  created () {
    this.searchProducts = debounce(this.searchProducts, 600, { leading: false, trailing: true })
    this.setBaseDiscount = debounce(this.setBaseDiscount, 600, { leading: false, trailing: true })
    if (this.obj.attributes.baseDiscount) {
      this.inputs.baseDiscount.attributes.value = this.obj.attributes.baseDiscount
      this.baseDiscount = parseFloat(this.obj.attributes.baseDiscount)
    }
    if (this.obj.attributes.productGroups) this.inputs.filterSelect.attributes.content = this.obj.attributes.productGroups[0]
    if (this.obj.attributes.basket) this.basket = this.obj.attributes.basket
    if (this.obj.attributes.currency) this.currency = this.obj.attributes.currency
  },
  mounted () {
    eventBus.$on('inputEmit' + this.obj.key, this.handleInput)
    eventBus.$on('get-recent-products', this.getRecentProducts)
    eventBus.$on('get-top-products', this.getTopProducts)
  },
  beforeDestroy () {
    eventBus.$off('inputEmit' + this.obj.key, this.handleInput)
    eventBus.$off('get-recent-products', this.getRecentProducts)
    eventBus.$off('get-top-products', this.getTopProducts)
  }
}
</script>

<style scoped lang="scss">
.filter-row {
  border: 1px solid $light-grey;
  padding: 0.75rem 0;
  background: $ultra-light-grey;
}

.order-products {
  display: flex;
  flex-flow: row wrap;
  height: calc(calc(100 * var(--vh-unit)) - 312px);
  max-height: calc(calc(100 * var(--vh-unit)) - 312px);
  overflow: hidden;
  border-bottom: 1px solid $medium-light-grey;

  > div[class*="col-"] {
    height: 100%;
    max-height: 100%;
    overflow: auto;
  }
}

.results, .details {
  border-right: 1px solid $light-grey;
}

.details {
  padding-top: 5px;
}

.basket {
  position: relative;

  &__price {
    position: sticky;
    bottom: 0;
    background: $white;
  }
}

.placeholder {
  color: $medium-grey;
  margin-top: 5px;
}
</style>