<template>
  <div class="MainMenu">
    <ul :class="obj.attributes.class" :style="checkStyles">
      <li v-for="item in obj.children" :obj="item" :key="item.key">
        <component :is="item.type" :obj="item"></component>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'MainMenu',
    components: {},
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {}
    },
    methods: {},
    mounted: function () {
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .MainMenu ul {
    display: flex;
    flex-flow: flex-direction;
    list-style-type: none;
    padding: 0;
    justify-content: space-between;
  }

  a {
    margin: em 0;
    padding: 0.5em 1em;
    color: black;
  }

  li {
    margin-right: 20px;
    color: black;
    align-self: flex-end;
  }

  img {
    margin: 1em;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin-right: 20px;
    color: black;
  }

  a {
    margin: 0.5em 0;
    padding: 0.5em 1em;
    color: wheat;
    background: green;
  }
</style>
