<!--
Attributes:
- attributes.icons: custom icons to show when opened or closed (default = angle-right/down)
  icons: {
    'open': ['fal', 'angle-down'],
    'closed': ['fal', 'angle-right']
  }
- attributes.open: collapse is open on load
- attributes.text: text to show next to icon
    text: {
       'closed': 'Show more',
       'open': 'Show less'
    },
-->

<template>
  <div class="simple-collapse">
    <div class="simple-collapse__toggle" @click="toggle">
      <font-awesome-icon class="simple-collapse__icon"
                         :icon="show ? this.icons.open : this.icons.closed"></font-awesome-icon>
      <span v-if="show">{{ $t(obj.attributes.text.open) }}</span>
      <span v-else>{{ $t(obj.attributes.text.closed) }}</span>
    </div>
    <transition name="fade">
      <div v-show="show" class="simple-collapse__content">
        <component v-for="item in obj.children" :is="item.type" :key="item.key" :obj="item"></component>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'LhSimpleCollapse',
  props: {
    obj: {
      type: Object
    }
  },
  data () {
    return {
      show: false,
      icons: {
        open: ['fal', 'angle-down'],
        closed: ['fal', 'angle-right']
      }
    }
  },
  computed: {},
  methods: {
    toggle () {
      this.show = !this.show
    }
  },
  watch: {},
  mounted () {
    if (this.obj.attributes.icons) {
      this.icons = this.obj.attributes.icons
    }
    if (this.obj.attributes.open) {
      this.show = true
    }
  }
}
</script>

<style scoped lang="scss">
.simple-collapse {
  &__toggle {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
    line-height: 1;
  }

  &__icon {
    font-size: 1.3rem;
    margin-right: 0.5em;
  }

  &__content {
    padding-left: 1.4rem;
  }
}
</style>