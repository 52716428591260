<template>
  <div class="lh-side-form">
    <component
        v-for="child in sideFormContent.children"
        :is="child.type"
        :key="child.key"
        :obj="child"
        :detailpage="true"
        @closePopup="handleGoBack"
    ></component>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'DetailForm',
    props: {
      detail: {
        type: Object
      }
    },
    data () {
      return {
        inputValue: [],
        componentKey: 1
      }
    },
    computed: {
      ...mapState({
        loading: 'loadingJSON',
        page: 'pageLoaded',
        sideFormContent: 'sideFormContent'
      })
    },
    methods: {
      handleChange (event) {
        // console.log(event);
        for (const property in this.detail) {
          if (event.srcElement.id === property) {
            this.detail[property] = event.srcElement.value
          }
        }
      },
      handleGoBack () {
        this.$store.commit('removeSideform')
      },
    }
  }
</script>

<style scoped lang="scss">
  .lh-side-form {
    flex: 0 0 auto;
    text-align: left;
    padding: 0 0 2em 1em;
    width: 450px;
    z-index: 10;
    top: 0;
    right: 0;
    overflow-x: hidden;
    padding-top: 20px;
    min-height: calc(50 * var(--vh-unit));
    border: 1px solid $light-grey;
  }
</style>
