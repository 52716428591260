<template>
  <div class="actionItem" :class="checkClasses">
    <!-- <div v-for="action in obj.actions" :key="action.event"> -->
    <div
        class="button"
        :style="checkStyles"
        v-on="allEvents"

        :title="checkTooltip"
    >{{$t(obj.attributes.content)}}
    </div>
  </div>
</template>

<script>
  import mixinFunctions from '@/mixin/mixinFunctions'
  import confirm from '@/mixin/confirm'

  export default {
    name: 'ActionItem',
    mixins: [ mixinFunctions, confirm ],
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {}
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .actionItem {
    color: white;
    padding: 0.5em;
    height: 40px;
    line-height: 40px;
    background-color: green;
  }
</style>
