<template>
  <div class="subtitle">
    <h2>{{$t(obj.attributes.content)}}</h2>
  </div>
</template>

<script>
  export default {
    name: 'SubTitle',
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .subtitle {
    background-color: darkcyan;
    width: 80%;
    margin: 0 auto;
  }
</style>
