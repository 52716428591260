<template>
  <thead class="header-component">
  <th class="checkbox-th">
      <div  v-if="(((!isMobile && checkboxVisible ) || (isMobile && checkboxMobile)) && !disableMultiSelect) || selectMode"
           class="select-all" :class="[{'checkbox-mobile': isMobile}]"
      >

        <b-form-checkbox
            class="chkbox"
            v-model="allSelected"
            :indeterminate="indeterminate"
            @change="toggleAll"
        ></b-form-checkbox>
      </div>
  </th>
  <th></th>
  <lh-grid-header
      v-for="header in headers"
      :header="header"
      :key="header.key"
      @headerClick="$emit('headerClick', header.key)"
      @headerWidth="emitWidth($event, header.key)"
      :style="checkStyles"
      :class="checkClasses"
  ></lh-grid-header>
  </thead>
</template>

<script>
import { mapState } from 'vuex'
  export default {
    name: 'GridTHead',
    props: {
      headers: {
        type: Array
      },
      indeterminate: {
        type: Boolean
      },
      allSelected: {
        type: Boolean
      },
      checkboxVisible: {
        type: Boolean
      },
      checkboxMobile: {
        type: Boolean
      },
      disableMultiSelect: {
        type: Boolean
      },
      selectMode: {
        type: Boolean
      }
    },
    computed: {
      ...mapState({
        isMobile: 'isMobile'
      })
    },
    methods: {
      emitWidth (width, key) {
        //console.log(width, key)
        this.$emit('headerWidth', { width, key })
      },
      toggleAll (value) {
        this.$emit('toggleAll', value)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-component {
    //white-space: nowrap;
    .checkbox-th {
      min-width: 2.5rem;

      .custom-control {
        padding-left: 2rem;
      }
    }
  }

  .popup-content {
    .select-all {
      padding-left: 9px;
    }
  }

  th {
    &:nth-of-type(2) {
      padding-left: 0;
    }
  }
</style>
