<template>
  <div class="basePage" :class="checkClasses" :style="checkStyles" v-tooltip="checkTooltip">
    <div v-if="loading || error" class="loading">{{LoadingOrError}}</div>
    <component v-if="!loading && !error && typeExist" :is="pageJSON.type" :key="pageJSON.key"
               :obj="pageJSON"></component>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        pageLink: '',
        page: null,
        loading: false,
        post: null,
        error: null,
        LoadingOrError: 'Loading...'
      }
    },
    beforeCreate: function () {
      const link = this.$route.path
      this.pageLink = link
      // this.$store.dispatch("getJSON", link).then(() => {
      //   //fill component with data
      //   // this.fillPage();
      // });
    },
    created () {
      // this.fetchData();
    },
    computed: {
      pageJSON: function () {
        return this.$store.getters.getMainJSON
      },
      infolabel: function () {},
      typeExist () {
        if (this.pageJSON.type) {
          return true
        } else {
          return false
        }
      }
    },
    methods: {
      fillPage () {
        this.page = this.$store.getters.getPageLoaded
      },
      fetchData () {
        this.error = this.post = null
        this.loading = true

        // replace `getPost` with your data fetching util / API wrapper
        this.$store
            .dispatch('fetchJSON', this.$route.path)
            //.dispatch("retrieveHomeJSON")
            .then(result => {
              this.loading = false
            })
            .catch(error => {
              this.LoadingOrError = error
              console.log('error: ', error)
            })
      }
    },
    beforeMount: function () {

    }
  }
</script>

<style lang="scss" scoped>
</style>

