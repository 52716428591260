<template>
  <div class="pagesize-select" :class="checkClasses" :style="checkStyles">
    <template v-if="obj.children.length !== 0">
        <span>{{ $t(obj.attributes.textBefore) }}</span>
        <component v-for="item in obj.children" :is="item.type" :obj="item" :key="item.key"></component>
        <span>{{ $t(obj.attributes.textAfter) }}</span>
    </template>
    <template v-else>
      <template v-if="obj.attributes.content">
        {{ $t(obj.attributes.content) }}
      </template>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'LhBrowsePageSizeSelect',

    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {}
    },
    computed: {},
    methods: {},
    watch: {}
  }
</script>

<style lang="scss">
  .pagesize-select {
    display: flex;
    align-items: center;

    span {
      color: $font-color;
    }

    .form-group {
      label {
        display: none !important;
      }
    }

    .lh-select {
      /*width: auto;*/
      margin-bottom: 0;
      min-width: 4rem;
      .custom-select {
        color: $pagination-color;
      }
      label {
        display: none;
      }
    }

    .custom-select {
      border: none;
      padding-top: 0;
      padding-bottom: 0;
      height: 1.5em;
      &:focus {
        box-shadow: none;
      }
    }
  }
</style>
