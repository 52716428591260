<template>
  <div class="title" :style="checkStyles">
    <h2 id="root_title_2">{{$t(obj.attributes.content)}}</h2>
    <component v-for="item in obj.children" :is="item.type" :key="item.key" :obj="item"></component>
  </div>
</template>

<script>
  export default {
    name: 'Title',
    components: {},
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
