<template>
  <div :class="[checkClasses, 'lh-h2']" :style="checkStyles">
    <h2>{{$t(obj.attributes.content)}}
      <lh-edit-translation :obj="{key: obj.attributes.content, parentKey: obj.key}"></lh-edit-translation>
    </h2>
    <component v-for="item in obj.children" :is="item.type" :key="item.key" :obj="item"></component>
  </div>
</template>

<script>

  export default {
    name: 'LhH2',
    components: {},
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>
