<script>
  import { Line } from 'vue-chartjs'


  export default {
    name: 'LineChart',
    extends: Line,
    props: {
      chartdata: {
        type: Object,
        default: null
      },
      options: {
        type: Object,
        default: null
      }
    },
    methods: {
      hexToRgb (hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : {
          r: 0,
          g: 0,
          b: 0
        }
      }

    },
    mounted () {
      for (let i = 0; i < this.chartdata.datasets.length; i++) {
        if( this.chartdata.datasets[i].backgroundColor){
          let gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
          let rbgBg = this.hexToRgb(this.chartdata.datasets[i].backgroundColor)

          gradient.addColorStop(0, `rgba(${rbgBg.r}, ${rbgBg.b}, ${rbgBg.g}, 0.5)`)
          gradient.addColorStop(0.5, `rgba(${rbgBg.r}, ${rbgBg.b}, ${rbgBg.g}, 0.25)`)
          gradient.addColorStop(1, `rgba(${rbgBg.r}, ${rbgBg.b}, ${rbgBg.g}, 0.0)`)

          this.chartdata.datasets[i].backgroundColor = gradient
        }
      }
      this.renderChart(this.chartdata, this.options)
    },
    watch: {
      options() {
        this.renderChart(this.chartdata, this.options)
      }
    },
    ready () {
      window.addEventListener('resize', this.renderChart(this.chartdata, this.options))
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.renderChart)
    }
  }
</script>
