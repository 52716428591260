<template>
  <div v-show="obj.attributes.totalPages > 1" :style="checkStyles">
    <b-pagination
        :total-rows="parseFloat(obj.attributes.totalPages)*parseFloat(obj.attributes.pageSize)"
        v-model="currentPage"
        :per-page="obj.attributes.pageSize"
        @change="handleCurrentChange"
        align="center"
    >
      <template v-slot:first-text><font-awesome-icon :icon="['fal', 'angle-double-left']"></font-awesome-icon></template>
      <template v-slot:prev-text><font-awesome-icon :icon="['fal', 'angle-left']" class="icon-larger"></font-awesome-icon></template>
      <template v-slot:next-text><font-awesome-icon :icon="['fal', 'angle-right']" class="icon-larger"></font-awesome-icon></template>
      <template v-slot:last-text><font-awesome-icon :icon="['fal', 'angle-double-right']"></font-awesome-icon></template>
    </b-pagination>
  </div>
</template>


<script>
  export default {
    name: 'LhPagination',
    components: {},
    props: {
      obj: {
        type: Object
      }
    },
    computed: {
      paginations () {
        return this.$store.getters.getPaginationData
      },
      currentPage: {
        get () {
          return parseFloat(this.obj.attributes.currentPage)
        },
        set (val) {
          //method removed because of double executing
        }
      }
    },
    methods: {
      handleCurrentChange (val) {
        let deselect = {
          key: this.obj.attributes.target,
          payload: {
            selectedRows: [],
            selectionLength: 0,
            browseSize: this.obj.attributes.pageSize
          }
        }
        this.$store.commit('setBrowseSettingByKey', deselect)

        let payload = {
          url: this.obj.attributes.url,
          page: val,
          browseKey: this.obj.attributes.target
        }

        this.$store.commit('setPaginationData', {
          key: this.obj.attributes.target,
          currentPage: val,
          browseSize: this.obj.attributes.pageSize
        })
        this.$store.dispatch('gotoPage', payload)
      }
    },
    created () {
      this.$store.commit('setPaginationData', {
        key: this.obj.attributes.target,
        currentPage: this.obj.attributes.currentPage,
        browseSize: this.obj.attributes.pageSize
      })
    }
  }
</script>

<style lang="scss">
  .pagination {
    .page-link {
      border: none;
      color: $dark-grey;
    }
    .page-item {
      &.active {
        font-weight: bold;

        .page-link {
          background: transparent;
          color: $pagination-color;

        }
      }
      &.disabled {
        .page-link {
          color: lighten($medium-grey, 10%);
        }
      }
    }
    .icon-larger {
      font-size: 1.4em;
    }
  }
</style>
