<template>
  <div class="browse-stats" :class="checkClasses" :style="checkStyles">
    <div v-for="stat in obj.attributes.stats">
      {{ $t(stat.text) }} {{ stat.value }}
    </div>
    <component v-for="item in obj.children" :is="item.type" :key="item.key" :obj="item"></component>
  </div>
</template>

<script>
export default {
  name: 'LhBrowseStats',
  components: {},
  props: {
    obj: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.browse-stats {
  display: flex;
  flex-flow: row wrap;
  border-top: 1px solid $light-grey;
  padding-top: 0.5rem;

  > div {
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
}
</style>