<template>
  <div class="container error-page">
    <div class="row">
      <div class="col">
        <h1>Oops!</h1>
        <h2>{{$t('GENERAL_ERROR_PAGE_TITLE')}}</h2>
        <p v-if="error.toLowerCase() === 'pagenotfound'">{{$t('GENERAL_PAGENOTFOUND_TEXT')}}</p>
        <p v-else>{{$t('GENERAL_ERROR_PAGE_TEXT')}}</p>
        <b-button @click="$router.push('/')" class="button secondary">{{$t('GENERAL_ERROR_PAGE_BUTTON')}}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'GeneralError',
    props: {
      error: {
        type: String,
        default: 'pagenotfound'
      }
    },
    data () {
      return {}
    },
    computed: {},
    methods: {},
    watch: {}
  }
</script>

<style scoped lang="scss">
.error-page {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
  h1 {
    color: $secondary-color;
  }
  p {
    color: $font-color;
  }
</style>