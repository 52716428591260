<template>
  <div class="lh-p" :class="checkClasses">
    <p :style="checkStyles">{{$t(obj.attributes.content)}}</p>
    <component v-for="item in obj.children" :is="item.type" :key="item.key" :obj="item"></component>
  </div>
</template>

<script>
  export default {
    name: 'LhP',
    components: {},
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>
